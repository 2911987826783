import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import withRotateDevice from 'src/components/hoc/withRotateDevice';
import VisitPageWithSupportedDeviceHOC from 'src/components/hoc/VisitPageWithSupportedDeviceHOC';

import Home from 'src/pages/onboarding/Home';
import SetupOverview from 'src/pages/onboarding/SetupOverview';
// import ChildWait from 'src/pages/onboarding/ChildWait';
import Account from 'src/pages/onboarding/Account';
import TeacherSurvey from 'src/pages/onboarding/TeacherSurvey';
import VerifyEmail from 'src/pages/onboarding/VerifyEmail';
import Consent from 'src/pages/onboarding/Consent';
import Welcome from 'src/pages/onboarding/Welcome';
import GameDownload from 'src/pages/onboarding/GameDownload';
import FireInstructions from 'src/pages/onboarding/FireInstructions';
import ConnectionCodeDialog from 'src/pages/onboarding/ConnectionCodeDialog';

import OnboardingProgressBar from 'src/components/general/OnboardingProgressBar';

import { hideHeaderForPath } from '..';
import * as routes from './routes';

import './style.scss';

const pagesWithoutProgressBar = [
  routes.ONBOARDING_HOME_ROUTE,
  routes.ONBOARDING_OVERVIEW_ROUTE,
  routes.ONBOARDING_WELCOME_ROUTE,
];

const hideProgressBarForPath = (path) =>
  pagesWithoutProgressBar.includes(path) ||
  pagesWithoutProgressBar.includes(path.substring(0, path.length - 1));

const OnboardingRoutes = () => {
  const location = useLocation();
  return (
    <div
      className={classNames('onboarding-content', {
        'onboarding-content--no-header': hideHeaderForPath(location.pathname),
      })}
    >
      <Switch>
        <Route exact path={routes.ONBOARDING_FIRE_ROUTE}>
          <FireInstructions />
        </Route>
        <Route exact path={routes.ONBOARDING_OVERVIEW_ROUTE}>
          <SetupOverview />
        </Route>
        {/* <Route exact path={routes.ONBOARDING_CHILD_WAIT_ROUTE}>
          <ChildWait />
        </Route> */}
        <Route exact path={routes.ONBOARDING_ACCOUNT_ROUTE}>
          <Account />
        </Route>
        <Route exact path={routes.ONBOARDING_SURVEY_ROUTE}>
          <TeacherSurvey />
        </Route>
        <Route exact path={routes.ONBOARDING_VERIFY_EMAIL_ROUTE}>
          <VerifyEmail />
        </Route>
        <Route exact path={routes.ONBOARDING_CONSENT_ROUTE}>
          <Consent />
        </Route>
        <Route exact path={routes.ONBOARDING_DOWNLOAD_ROUTE}>
          <GameDownload />
        </Route>
        <Route exact path={routes.ONBOARDING_WELCOME_ROUTE}>
          <Welcome />
        </Route>
        <Route exact path={routes.ONBOARDING_CONNECTION_CODE_ROUTE}>
          <ConnectionCodeDialog />
        </Route>
        <Route exact path={routes.ONBOARDING_HOME_ROUTE}>
          <Home />
        </Route>
      </Switch>
      {!hideProgressBarForPath(location.pathname) && (
        <OnboardingProgressBar pathname={location.pathname} />
      )}
    </div>
  );
};

export default VisitPageWithSupportedDeviceHOC(
  withRotateDevice(OnboardingRoutes),
  'onboarding',
);
