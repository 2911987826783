import React from 'react';
import classNames from 'classnames';
import { translate } from 'src/i18n';

import DownloadIcon from 'src/assets/images/onboarding/download-icon.svg';

import './style.scss';

const GameDownloadItem = ({
  game,
  isRecommended,
  active,
  hideDownloadIcon,
  size = 'medium',
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames('game-download-item', className, {
        'game-download-item--active': active,
        'game-download-item--big': size === 'big',
      })}
      onClick={onClick}
    >
      <div className="game-download-item__icon">
        <img
          src={game.icon.src}
          alt={game.icon.alt}
          className="game-download-item__icon__img"
        />
        {!hideDownloadIcon && (
          <img
            src={DownloadIcon}
            alt=""
            className="game-download-item__icon__dl"
          />
        )}
        {isRecommended && (
          <span className="game-download-item__icon__recommended">
            {translate('general.recommended')}
          </span>
        )}
      </div>
      <div className="game-download-item__name">
        {game.name.replace('Osmo ', '')}
      </div>
      {game.oneLiner && (
        <div className="game-download-item__one-liner">{game.oneLiner}</div>
      )}
    </div>
  );
};

export default GameDownloadItem;
