import React, { useCallback } from 'react';

import { isIpad, isSilk } from 'src/utils/device';

import VisitPageWithSupportedDeviceHOC from 'src/components/hoc/VisitPageWithSupportedDeviceHOC';

import OsmoButton from 'src/components/buttons/OsmoButton';

import MathWizardIconSrc from 'src/assets/images/games/math-wizard/math-wizard-osmo-logo@2x.png';
import MathWizardAppIconSrc from 'src/assets/images/games/math-wizard/math-wizard-icon@2x.png';

import './style.scss';

const GetMathWizard = () => {
  const _onClick = useCallback(() => {
    if (isIpad()) {
      window.open('https://itunes.apple.com/app/id1470095030');
    } else if (isSilk()) {
      // TODO: Update link to app link (Fire)
      window.open('https://www.playosmo.com');
    }
  }, []);

  return (
    <>
      <div className="get-math-wizard-page">
        <img
          className="get-math-wizard-page__icon"
          src={MathWizardIconSrc}
          alt="Math Wizard Icon "
        />
        <img
          className="get-math-wizard-page__app-icon"
          src={MathWizardAppIconSrc}
          alt="Math Wizard App Icon "
        />
        <div className="get-math-wizard-page__content">
          <div className="get-math-wizard-page__instructions">
            {
              'To get started on your journey to become a math wiz, download the Math Wizard app!'
            }
          </div>

          <div className="get-math-wizard-page__button">
            <OsmoButton
              text="Download Math Wizard"
              theme="blue"
              isLarge={true}
              onClick={() => _onClick()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitPageWithSupportedDeviceHOC(GetMathWizard);
