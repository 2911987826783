import MathBuzzIcon from 'src/assets/images/icons/games/mathbuzz.png';
import LettertopiaIcon from 'src/assets/images/icons/games/lettertopia.png';
import CountingCountyIcon from 'src/assets/images/icons/games/counting-county.png';
import ShapesIcon from 'src/assets/images/icons/games/shapes.png';
import ToyboxIcon from 'src/assets/images/icons/games/toybox-512-r.png';
import WordsExplorersIcon from 'src/assets/images/icons/games/words-explorers.png';
import WordsChompIcon from 'src/assets/images/icons/games/words-chomp.png';
import CookingChaosIcon from 'src/assets/images/icons/games/cooking-chaos.png';

export const apps = {
  MATHBUZZ: 'memory_math',
  LETTERTOPIA: 'scotland_yard',
  COUNTING_COUNTY: 'lgk_countingcounty',
  SHAPES: 'lgk_shapes',
  TOYBOX: 'toybox',
  WORDS_EXPLORER: 'aztext',
  WORDS_CHOMP: 'wordschomp',
  NUMBERS_COOKING_CHAOS: 'numbers_moles',
};

export const appsData = {
  [apps.MATHBUZZ]: {
    icon: {
      src: MathBuzzIcon,
      alt: 'MathBuzz',
    },
    name: 'MathBuzz',
    storeUrl: 'https://itunes.apple.com/app/id1504177111',
  },
  [apps.LETTERTOPIA]: {
    icon: {
      src: LettertopiaIcon,
      alt: 'Lettertopia',
    },
    name: 'Lettertopia',
    storeUrl: 'https://itunes.apple.com/app/id1504176887',
  },
  [apps.COUNTING_COUNTY]: {
    icon: {
      src: CountingCountyIcon,
      alt: 'Counting Town',
    },
    name: 'Counting Town',
    storeUrl: 'https://itunes.apple.com/app/id1506823569',
  },
  [apps.SHAPES]: {
    icon: {
      src: ShapesIcon,
      alt: 'Shape Builder',
    },
    name: 'Shape Builder',
    storeUrl: 'https://itunes.apple.com/app/id1492331208',
  },
  [apps.TOYBOX]: {
    icon: {
      src: ToyboxIcon,
      alt: 'Numbers Toybox',
    },
    name: 'Numbers Toybox',
    storeUrl: 'https://apps.apple.com/us/app/id1326405743',
  },
  [apps.WORDS_EXPLORER]: {
    icon: {
      src: WordsExplorersIcon,
      alt: 'Words Explorers',
    },
    name: 'Words Explorers',
    storeUrl: 'https://itunes.apple.com/app/id1433980039 ',
  },
  [apps.WORDS_CHOMP]: {
    icon: {
      src: WordsChompIcon,
      alt: 'Words Chomp',
    },
    name: 'Osmo Words Chomp',
    storeUrl: 'https://itunes.apple.com/app/id1487831337',
  },
  [apps.NUMBERS_COOKING_CHAOS]: {
    icon: {
      src: CookingChaosIcon,
      alt: 'Cooking Chaos',
    },
    name: 'Osmo Numbers Cooking Chaos',
    storeUrl: 'https://itunes.apple.com/app/id1487831692',
  },
};
