import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Title from 'src/components/texts/Title';
import GameSet from 'src/components/games/GameSet';

import './style.scss';

const FireInstructionsHeader = (props) => {
  const { headerData, gamesIncluded } = props;

  return (
    <div className="fire-instructions-header">
      {/* Image */}
      {headerData?.image && (
        <img
          className="fire-instructions-header__image"
          src={headerData.image.src}
          alt={headerData.image.alt}
        />
      )}

      {/* Right side content */}
      <div
        className={classNames('fire-instructions-header__content', {
          'fire-instructions-header__content--centered':
            headerData?.hideGamesInHeader,
        })}
      >
        {/* Head */}
        <div className="fire-instructions-header__head">
          {'Amazon Fire Tablet Setup Instructions for'}
        </div>
        {/* Name */}
        {headerData?.name && <Title text={headerData.name} />}

        {/* Unfortunately because the layout differs between desktop and mobile
            we need to clone the image element */}
        {headerData?.image && (
          <img
            className="fire-instructions-header__image fire-instructions-header__image--small-devices"
            src={headerData.image.src}
            alt={headerData.image.alt}
          />
        )}

        {/* Games included */}
        {!headerData?.hideGamesInHeader && !!gamesIncluded?.length && (
          <div className="fire-instructions-header__games">
            <div className="fire-instructions-header__games-label">
              {'Games included:'}
            </div>
            <GameSet games={gamesIncluded} />
          </div>
        )}
      </div>
    </div>
  );
};

FireInstructionsHeader.propTypes = {
  headerData: PropTypes.object.isRequired,
  gamesIncluded: PropTypes.arrayOf(PropTypes.string),
};

export default FireInstructionsHeader;
