import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';

import { translate } from 'src/i18n';
import * as actions from 'src/actions';

import useSetStateRef from 'src/hooks/useSetStateRef';

import withLoading from 'src/components/hoc/withLoading';
import withModal from 'src/components/hoc/withModal';
import CodeInput from 'src/components/inputs/CodeInput';
import ErrorMessage from 'src/components/messages/ErrorMessage';
import CodeRetry from './components/CodeRetry';
import EmailHeader from './components/EmailHeader';

import './style.scss';

const VerificationCodeForm = (props) => {
  const {
    dispatch,
    email,
    setLoadingOn,
    isLoading,
    onComplete,
    errors: errorsFromParent = {},
  } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [verificationCode, setVerificationCode] = useSetStateRef('');

  const [isRetrying, setIsRetrying] = useState(false);
  const [hasRetried, setHasRetried] = useState(false);

  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    setErrors({
      ...errors,
      ...errorsFromParent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsFromParent]);

  // ===========================================================================
  // ============================ ERRORS =======================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onChange(e) {
    setVerificationCode(e);
  }

  function _onComplete() {
    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Remove all errors to update to new ones
    _resetErrors();

    if (!verificationCode || !verificationCode.current) {
      return;
    }

    // Dispatch loading on
    setLoadingOn();

    onComplete({ verificationCode });
  }

  function _onRetry() {
    setIsRetrying(true);

    // Dispatch
    dispatch({
      type: actions.redeem.SEND_EMAIL_TO_SERVER_SAGA,
      payload: {
        params: {
          email,
        },
        callback: () => {
          setIsRetrying(false);

          setHasRetried(true);
        },
        errorCallback: () => {
          setIsRetrying(false);

          setErrors({
            retry: translate('verifyEmail.resendCodeError'),
          });
        },
      },
    });
  }

  return (
    <>
      <EmailHeader email={email} />
      <div className="verification-code-form">
        <p>{translate('verifyEmail.title')}</p>

        <div className="verification-code-form__code-input">
          <CodeInput onComplete={_onComplete} onChange={_onChange} />
        </div>

        {errors?.code && (
          <div className="verification-code-form__message">
            <ErrorMessage message={errors?.code?.message} />
          </div>
        )}

        <CodeRetry
          onRetry={_onRetry}
          isLoading={isRetrying}
          retried={hasRetried}
          error={errors?.retry}
        />
      </div>
    </>
  );
};

export default compose(withLoading, withModal)(VerificationCodeForm);
