import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import GameItem from 'src/components/games/GameItem';

import { gamesData } from 'src/data/games';

import './style.scss';

/**
 *  Game set that includes multiple game items
 */

const GameSet = React.memo((props) => {
  const { games, isLarge, isInline, column } = props;

  if (!games?.length) {
    return null;
  }

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  // Game names are sometimes too long, so we need to add more
  // more space for readability
  const hasLargeGameName = games.some(
    (game) => gamesData[game].name.length > 16,
  );

  const gameSetClassNames = classNames('game-set', {
    'game-set--large': isLarge,
    'game-set--inline': isInline,
    'game-set--large-name': hasLargeGameName && !column,
    'game-set--column': column,
  });

  return (
    <div className={gameSetClassNames}>
      {games.map((game) => (
        <GameItem
          key={game}
          game={game}
          className="game-set__game"
          isLarge={isLarge}
          isInverted={isInline && !column}
        />
      ))}
    </div>
  );
});

GameSet.propTypes = {
  games: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLarge: PropTypes.bool,
  isInverted: PropTypes.bool,
};

export default GameSet;
