export const ONBOARDING_PATH = '/onboarding';

export const ONBOARDING_HOME_ROUTE = `${ONBOARDING_PATH}/:product`;
export const ONBOARDING_OVERVIEW_ROUTE = `${ONBOARDING_PATH}/overview`;
// export const ONBOARDING_CHILD_WAIT_ROUTE = `${ONBOARDING_PATH}/child-wait`;
export const ONBOARDING_ACCOUNT_ROUTE = `${ONBOARDING_PATH}/account`;
export const ONBOARDING_SURVEY_ROUTE = `${ONBOARDING_PATH}/survey`;
export const ONBOARDING_VERIFY_EMAIL_ROUTE = `${ONBOARDING_PATH}/verify-email`;
export const ONBOARDING_CONSENT_ROUTE = `${ONBOARDING_PATH}/consent`;
export const ONBOARDING_WELCOME_ROUTE = `${ONBOARDING_PATH}/welcome`;
export const ONBOARDING_DOWNLOAD_ROUTE = `${ONBOARDING_PATH}/download`;
export const ONBOARDING_CONNECTION_CODE_ROUTE = `${ONBOARDING_PATH}/connection-code`;
export const ONBOARDING_FIRE_ROUTE = `${ONBOARDING_PATH}/fire/:kit`;
export const getOnboardingFireRoute = (product) =>
  `${ONBOARDING_PATH}/fire/${product}`;
