export const IS_LOCAL = window.location.hostname === 'localhost';
export const IS_DEVELOP =
  window.location.hostname === 'develop.dev.my.playosmo.com';
export const IS_RELC =
  window.location.hostname === 'relc-dot-tangibleplay-ugc.appspot.com';
export const IS_BETA_PARENT =
  window.location.hostname === 'beta-parent-dot-tangibleplay-ugc.appspot.com';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production' && !IS_LOCAL;

export const ENV_PRODUCTION = 'production';
export const ENV_RELC = 'relc';
export const ENV_BETA_PARENT = 'beta-parent';
export const ENV_DEVELOP = 'develop';
export const ENV_LOCAL = 'local';

export const getEnvName = () => {
  if (IS_PRODUCTION) {
    return ENV_PRODUCTION;
  }

  if (IS_DEVELOP) {
    return ENV_DEVELOP;
  }

  if (IS_RELC) {
    return ENV_RELC;
  }

  if (IS_BETA_PARENT) {
    return ENV_BETA_PARENT;
  }

  return ENV_LOCAL;
};
