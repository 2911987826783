import MasterpieceIcon from 'src/assets/images/icons/games/masterpiece-256.jpg';
import NewtonIcon from 'src/assets/images/icons/games/newton-256.jpg';
import NumbersIcon from 'src/assets/images/icons/games/numbers-256.jpg';
import TangramIcon from 'src/assets/images/icons/games/tangram-256.jpg';
import WordsIcon from 'src/assets/images/icons/games/words-256.jpg';
import MathBuzzIcon from 'src/assets/images/icons/games/mathbuzz-256.png';
import LettertopiaIcon from 'src/assets/images/icons/games/lettertopia-256.png';
import CountingCountyIcon from 'src/assets/images/icons/games/countingtown-256.png';
import ShapesIcon from 'src/assets/images/icons/games/shapebuilder-256.png';
import ABCsIcon from 'src/assets/images/icons/games/abcs-256.png';
import CostumePartyIcon from 'src/assets/images/icons/games/costumeparty-256.png';
import SquiggleMagicIcon from 'src/assets/images/icons/games/squiggle-magic.png';
import StoriesIcon from 'src/assets/images/icons/games/stories-256.png';
import DetectiveIcon from 'src/assets/images/icons/games/detective-256.png';
import CodingIcon from 'src/assets/images/icons/games/coding-256.png';
import CodingDuoIcon from 'src/assets/images/icons/games/coding-duo-256.png';
import CodingJamIcon from 'src/assets/images/icons/games/coding-jam-256.png';
import SSMickeyIcon from 'src/assets/images/icons/games/superstudio-mickey-256.png';
import SSPrincessIcon from 'src/assets/images/icons/games/superstudio-princess-256.png';
import SSFrozenIcon from 'src/assets/images/icons/games/superstudio-frozen2-256.png';
import MonsterIcon from 'src/assets/images/icons/games/monster-256.png';
import ToyboxIcon from 'src/assets/images/icons/games/toybox-512-r.png';
import PizzaCoIcon from 'src/assets/images/icons/games/pizza-co-512-r.png';
import MathWizardIcon from 'src/assets/images/icons/games/mathwizard-workshop-256.png';
import WordsExplorersIcon from 'src/assets/images/icons/games/words-explorers.png';
import WordsChompIcon from 'src/assets/images/icons/games/words-chomp.png';
import CookingChaosIcon from 'src/assets/images/icons/games/cooking-chaos.png';
import KaleidoscopeIcon from 'src/assets/images/icons/games/kaleidoscope-256.png';
import NumbersEduIcon from 'src/assets/images/icons/games/edu-numbers-256.png';
import TangramEduIcon from 'src/assets/images/icons/games/edu-tangram-256.png';
import WordsEduIcon from 'src/assets/images/icons/games/edu-words-256.png';

export const games = {
  MASTERPIECE: 'masterpiece',
  NEWTON: 'newton',
  NUMBERS: 'numbers',
  TANGRAM: 'tangram',
  WORDS: 'words',
  MATHBUZZ: 'mathbuzz',
  LETTERTOPIA: 'lettertopia',
  COUNTING_COUNTY: 'counting-county',
  SHAPES: 'shapes',
  ABCS: 'abcs',
  COSTUME_PARTY: 'costume-party',
  SQUIGGLE_MAGIC: 'squiggle-magic',
  STORIES: 'stories',
  DETECTIVE: 'detective-agency',
  CODING: 'coding',
  CODING_DUO: 'coding-duo',
  CODING_JAM: 'coding-jam',
  SUPERSTUDIO_MICKEY: 'superstudio-mickey',
  SUPERSTUDIO_PRINCESS: 'superstudio-princesses',
  SUPERSTUDIO_FROZEN: 'superstudio-frozen-2',
  MONSTER: 'monster',
  TOYBOX: 'toybox',
  PIZZACO: 'pizzaco',
  MATH_WIZARD: 'math-wizard',
  WORDS_EXPLORERS: 'words-explorers',
  WORDS_CHOMP: 'wordschomp',
  NUMBERS_COOKING_CHAOS: 'numbers_moles',
  KALEIDOSCOPE: 'kaleidoscope',

  /* EDU */

  NUMBERS_EDU: 'numbers-edu',
  TANGRAM_EDU: 'tangram-edu',
  WORDS_EDU: 'words-edu',
};

export const gamesData = {
  [games.MASTERPIECE]: {
    icon: {
      src: MasterpieceIcon,
      alt: 'Osmo Masterpiece',
    },
    name: 'Osmo Masterpiece',
    oneLiner: 'Supercharge your drawing skills.',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07FLCCGW8',
      ipad: 'https://itunes.apple.com/app/id947529440',
    },
  },
  [games.NEWTON]: {
    icon: {
      src: NewtonIcon,
      alt: 'Osmo Newton',
    },
    name: 'Osmo Newton',
    oneLiner: 'Creative physics puzzles.',
    skills: ['Problem Solving'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPBPCWV',
      ipad: 'https://itunes.apple.com/app/id876524974',
    },
  },
  [games.NUMBERS]: {
    icon: {
      src: NumbersIcon,
      alt: 'Osmo Numbers',
    },
    name: 'Osmo Numbers',
    oneLiner: 'Dive into an ocean of math fun.',
    skills: ['Math'],
    appLinks: {
      amazonFire:
        'amzn://apps/android?p=com.tangibleplay.osmo.numbersworldtour',
      ipad: 'https://itunes.apple.com/app/id1531762562',
    },
  },
  [games.TANGRAM]: {
    icon: {
      src: TangramIcon,
      alt: 'Osmo Tangram',
    },
    name: 'Osmo Tangram',
    oneLiner: 'Stay sharp with shape-shifting puzzles.',
    skills: ['Problem Solving'],
    appLinks: {
      amazonFire: 'amzn://apps/android?p=com.tangibleplay.osmo.tangram2',
      ipad: 'https://itunes.apple.com/app/id1539533255',
    },
  },
  [games.WORDS]: {
    icon: {
      src: WordsIcon,
      alt: 'Osmo Words',
    },
    name: 'Osmo Words',
    oneLiner: 'Play action-packed word puzzles.',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: 'amzn://apps/android?p=com.tangibleplay.osmo.journey',
      ipad: 'https://itunes.apple.com/app/id1541764043',
    },
  },
  [games.MATHBUZZ]: {
    icon: {
      src: MathBuzzIcon,
      alt: 'Osmo MathBuzz',
    },
    name: 'Osmo MathBuzz',
    appName: 'Math Buzz',
    oneLiner: 'Boost math skills as a team.',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B08CZ19K4Q',
      ipad: 'https://itunes.apple.com/app/id1504177111',
    },
  },
  [games.LETTERTOPIA]: {
    icon: {
      src: LettertopiaIcon,
      alt: 'Osmo Lettertopia',
    },
    name: 'Osmo Lettertopia',
    appName: 'Lettertopia',
    oneLiner: 'Team up and learn to spell.',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B08CZKGDSK',
      ipad: 'https://itunes.apple.com/app/id1504176887',
    },
  },
  [games.COUNTING_COUNTY]: {
    icon: {
      src: CountingCountyIcon,
      alt: 'Osmo Counting Town',
    },
    name: 'Osmo Counting Town',
    appName: 'Counting Town',
    oneLiner: 'Hands-on math for preschoolers.',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B089K1NN2G',
      ipad: 'https://itunes.apple.com/app/id1506823569',
    },
  },
  [games.SHAPES]: {
    icon: {
      src: ShapesIcon,
      alt: 'Osmo Shapes',
    },
    name: 'Osmo Shapes',
    appName: 'Shape Builder',
    oneLiner: 'Fill a house full of shapes!',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B089JW18VJ',
      ipad: 'https://itunes.apple.com/app/id1492331208',
    },
  },
  [games.ABCS]: {
    icon: {
      src: ABCsIcon,
      alt: 'Osmo ABCs',
    },
    name: 'Osmo ABCs',
    oneLiner: 'Playful squishy pre-reading!',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07ZTVWNDL',
      ipad: 'https://itunes.apple.com/app/id1439615379',
    },
  },
  [games.COSTUME_PARTY]: {
    icon: {
      src: CostumePartyIcon,
      alt: 'Osmo Costume Party',
    },
    name: 'Osmo Costume Party',
    oneLiner: 'Play with clothes & color!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07ZTTYNH5',
      ipad: 'https://itunes.apple.com/app/id1450452360',
    },
  },
  [games.SQUIGGLE_MAGIC]: {
    icon: {
      src: SquiggleMagicIcon,
      alt: 'Osmo Squiggle Magic',
    },
    name: 'Osmo Squiggle Magic',
    oneLiner: 'Your creations come alive!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07ZPDYJ3Q',
      ipad: 'https://itunes.apple.com/app/id1460037873',
    },
  },
  [games.STORIES]: {
    icon: {
      src: StoriesIcon,
      alt: 'Osmo Stories',
    },
    name: 'Osmo Stories',
    oneLiner: 'Silly solutions to obstacles!',
    skills: ['Problem Solving'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07ZTVK94N',
      ipad: 'https://itunes.apple.com/app/id1439615012',
    },
  },
  [games.DETECTIVE]: {
    icon: {
      src: DetectiveIcon,
      alt: 'Osmo Detective Agency',
    },
    name: 'Osmo Detective Agency',
    oneLiner: 'Solve mysteries around the world!',
    skills: ['Geography'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07JM51ZGV',
      ipad: 'https://itunes.apple.com/app/id1371607066',
    },
  },
  [games.CODING]: {
    icon: {
      src: CodingIcon,
      alt: 'Osmo Coding Awbie',
    },
    name: 'Osmo Coding Awbie',
    oneLiner: 'Start here to learn basic coding fundamentals.',
    skills: ['Coding'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPFWDCP',
      ipad: 'https://itunes.apple.com/app/id1067117430',
    },
  },
  [games.CODING_DUO]: {
    icon: {
      src: CodingDuoIcon,
      alt: 'Osmo Coding Duo',
    },
    name: 'Osmo Coding Duo',
    oneLiner: 'Show off your skills in this advanced puzzle game.',
    skills: ['Coding'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPCMSL3',
      ipad: 'https://itunes.apple.com/app/id1294444077',
    },
  },
  [games.CODING_JAM]: {
    icon: {
      src: CodingJamIcon,
      alt: 'Osmo Coding Jam',
    },
    name: 'Osmo Coding Jam',
    oneLiner: 'Get creative with code and make your own music.',
    skills: ['Coding'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPHBZGK',
      ipad: 'https://itunes.apple.com/app/id1188022582',
    },
  },
  [games.SUPERSTUDIO_MICKEY]: {
    icon: {
      src: SSMickeyIcon,
      alt: 'Osmo Super Studio Mickey Mouse & Friends',
    },
    name: 'Osmo Super Studio Mickey Mouse & Friends',
    oneLiner: 'Draw Mickey and his friends!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07HFXD6H6',
      ipad: 'https://itunes.apple.com/app/id1327139572',
    },
  },
  [games.SUPERSTUDIO_PRINCESS]: {
    icon: {
      src: SSPrincessIcon,
      alt: 'Osmo Super Studio Princesses',
    },
    name: 'Osmo Super Studio Princesses',
    oneLiner: 'Draw your favorite princesses!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07JJNQTRS',
      ipad: 'https://itunes.apple.com/app/id1434905914',
    },
  },
  [games.SUPERSTUDIO_FROZEN]: {
    icon: {
      src: SSFrozenIcon,
      alt: 'Osmo Super Studio Frozen 2',
    },
    name: 'Osmo Super Studio Frozen 2',
    oneLiner: 'Draw Elsa and her friends!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07YL8Q78S',
      ipad: 'https://itunes.apple.com/app/id1450537450',
    },
  },
  [games.MONSTER]: {
    icon: {
      src: MonsterIcon,
      alt: 'Osmo Monster',
    },
    name: 'Osmo Monster',
    oneLiner: 'Bring drawings to life with Mo.',
    skills: ['Creativity '],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPD86X4',
      ipad: 'https://itunes.apple.com/app/id1065590798',
    },
  },
  [games.TOYBOX]: {
    icon: {
      src: ToyboxIcon,
      alt: 'Osmo Numbers Toybox',
    },
    name: 'Osmo Numbers Toybox',
    oneLiner: 'A variety of math minigames!',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07PVZK8F5',
      ipad: 'https://itunes.apple.com/app/id1326405743',
    },
  },
  [games.PIZZACO]: {
    icon: {
      src: PizzaCoIcon,
      alt: 'Osmo Pizza Co.',
    },
    name: 'Osmo Pizza Co.',
    oneLiner: 'Get an extra-large combo of fun and learning.',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPF7R81',
      ipad: 'https://itunes.apple.com/app/id1155230147',
    },
  },
  [games.MATH_WIZARD]: {
    icon: {
      src: MathWizardIcon,
      alt: 'Osmo Math Wizard',
    },
    name: 'Osmo Math Wizard',
    oneLiner: '',
    skills: ['Math'],
    appLinks: {
      amazonFire: '',
      ipad: '',
    },
  },
  [games.WORDS_EXPLORERS]: {
    icon: {
      src: WordsExplorersIcon,
      alt: 'Osmo Words Explorers',
    },
    name: 'Osmo Words Explorers',
    oneLiner: 'Go on a words adventure!',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07PX85SBZ',
      ipad: 'https://itunes.apple.com/app/id1433980039',
    },
  },
  [games.WORDS_CHOMP]: {
    icon: {
      src: WordsChompIcon,
      alt: 'Osmo Words Chomp',
    },
    name: 'Osmo Words Chomp',
    oneLiner: 'Devour words with Chomp!',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: '',
      ipad: '',
    },
  },
  [games.NUMBERS_COOKING_CHAOS]: {
    icon: {
      src: CookingChaosIcon,
      alt: 'Osmo Numbers Cooking Chaos',
    },
    name: 'Osmo Numbers Cooking Chaos',
    oneLiner: '',
    skills: [''],
    appLinks: {
      amazonFire: '',
      ipad: '',
    },
  },
  [games.KALEIDOSCOPE]: {
    icon: {
      src: KaleidoscopeIcon,
      alt: 'Osmo Kaleidoscope',
    },
    name: 'Osmo Kaleidoscope',
    oneLiner: 'Explore, design, and discover!',
    skills: ['Creativity'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07W6XH5HC',
      ipad: 'https://itunes.apple.com/app/id1468962586',
    },
  },

  /* Edu */

  [games.NUMBERS_EDU]: {
    icon: {
      src: NumbersEduIcon,
      alt: 'Osmo Numbers Education',
    },
    name: 'Osmo Numbers Education',
    oneLiner: 'Dive into an ocean of math fun.',
    skills: ['Math'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07FLCCFMY',
      ipad: 'https://itunes.apple.com/app/id1017627073',
    },
  },
  [games.TANGRAM_EDU]: {
    icon: {
      src: TangramEduIcon,
      alt: 'Osmo Tangram Education',
    },
    name: 'Osmo Tangram Education',
    oneLiner: 'Stay sharp with shape-shifting puzzles.',
    skills: ['Problem Solving'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BPCJ295',
      ipad: 'https://itunes.apple.com/app/id876510541',
    },
  },
  [games.WORDS_EDU]: {
    icon: {
      src: WordsEduIcon,
      alt: 'Osmo Words Education',
    },
    name: 'Osmo Words Education',
    oneLiner: 'Play action-packed word puzzles.',
    skills: ['Literacy'],
    appLinks: {
      amazonFire: 'amzn://apps/android?asin=B07BP5K854',
      ipad: 'https://itunes.apple.com/app/id876514856',
    },
  },
};
