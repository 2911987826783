import i18n from 'i18n-js';

// Translation getters
const translationGetters = {
  en: () => require('./locales/en.json'),
};

export const translate = i18n.t;

const getLanguage = () => {
  // const browserLanguage = navigator.language;
  return 'en';
};

// Set i18n config
const setI18nConfig = () => {
  const language = getLanguage();

  // Set i18n-js config
  if (translationGetters[language]) {
    i18n.translations = { [language]: translationGetters[language]() };
  }

  i18n.locale = language;
};

setI18nConfig();
