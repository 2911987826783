import React from 'react';

import Title from 'src/components/texts/Title';

import RedeemCodeForm from '../RedeemCodeForm';

import './style.scss';

const RedeemCodeMain = (props) => {
  const { redeemCodeFromUrl, dispatch } = props;

  return (
    <div className="redeem-code-main">
      <Title text="Enter your redemption code" />
      <div className="redeem-code-main__text-holder">
        <p className="redeem-code-main__text--emphasized">
          {'If you purchased a physical product with a redemption code:'}
        </p>
        <p className="redeem-code-main__text">
          {'You can find the code on the paper slip inside the box.'}
        </p>
      </div>
      <div className="redeem-code-main__text-holder">
        <p className="redeem-code-main__text--emphasized">
          {'If you purchased a digital game from playosmo.com:'}
        </p>
        <p className="redeem-code-main__text">
          {
            'You can find the code in the email we sent with the subject line ‘Game Redemption Code‘.'
          }
        </p>
      </div>
      <RedeemCodeForm
        redeemCodeFromUrl={redeemCodeFromUrl}
        dispatch={dispatch}
      />
    </div>
  );
};

export default RedeemCodeMain;
