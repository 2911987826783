import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import * as selectors from 'src/selectors';
import * as actions from 'src/actions';

/**
 *  This HoC can be used to easily
 *  access loading methods
 */

const withLoading = (WrappedComponent) => (props) => {
  const { dispatch } = props;

  function setLoadingOn() {
    dispatch({
      type: actions.loading.SET_LOADING_ON,
    });
  }

  function setLoadingOff() {
    dispatch({
      type: actions.loading.SET_LOADING_OFF,
    });
  }

  return (
    <WrappedComponent
      setLoadingOff={setLoadingOff}
      setLoadingOn={setLoadingOn}
      {...props}
    />
  );
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.loading.getIsLoadingSelector(state),
  };
}

const enhancedWithLoading = compose(connect(mapStateToProps), withLoading);

export default enhancedWithLoading;
