import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as selectors from 'src/selectors';

import ProgressRocketHalfThrustIconSrc from 'src/assets/images/onboarding/progress-rocket-half-thrust.svg';
import ProgressRocketIconSrc from 'src/assets/images/onboarding/progress-rocket.svg';
import ProgressCheckIconSrc from 'src/assets/images/onboarding/progress-check.svg';

import getSteps from './utils/getSteps';

import './style.scss';

const OnboardingProgressBar = ({
  pathname,
  // From Redux
  trajectoryType,
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [progressRatio, setProgressRatio] = useState(0);
  const [animating, setAnimating] = useState(0);

  const steps = getSteps(trajectoryType);

  // ===========================================================================
  // ============================== HOOKS ======================================
  // ===========================================================================

  useEffect(() => {
    const index = steps.findIndex((step) => step.pages.includes(pathname));
    setActiveStepIndex(index);
  }, [pathname, steps]);

  useEffect(() => {
    const activeStep = steps[activeStepIndex];
    const progressIndex =
      activeStep?.pages?.findIndex((page) => page === pathname) || 0;
    const progress =
      (progressIndex * 100) / (activeStep?.pages?.length || 0) / 100;

    if (progress !== undefined) {
      setProgressRatio(progress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepIndex, pathname]);

  useEffect(() => {
    setAnimating(true);

    setTimeout(() => {
      setAnimating(false);
    }, 400);
  }, [progressRatio]);

  // ===========================================================================
  // ======================= PROGRESS STATE VARS ===============================
  // ===========================================================================

  if (activeStepIndex === -1) {
    return null;
  }

  return (
    <div className="onboarding-progress-bar">
      {steps.map((step, i) => {
        const isActive = activeStepIndex === i;
        const isDone = activeStepIndex > i;
        const progress = isDone ? 1 : isActive ? progressRatio || 0.1 : 0;

        return (
          <div
            key={step.id}
            className={classNames('onboarding-progress-bar__item', {
              'onboarding-progress-bar__item--active': isActive,
              'onboarding-progress-bar__item--done': isDone,
            })}
          >
            <div className="onboarding-progress-bar__item__bar">
              <div
                className="onboarding-progress-bar__item__bar__inner"
                style={{ width: `${progress * 100}%` }}
              />
            </div>
            {isActive && (
              <>
                <img
                  className={classNames(
                    'onboarding-progress-bar__item__rocket',
                    {
                      animated: animating,
                    },
                  )}
                  src={ProgressRocketIconSrc}
                  alt=""
                  style={{ left: `${progress * 100}%` }}
                />
                <img
                  className={classNames(
                    'onboarding-progress-bar__item__rocket onboarding-progress-bar__item__rocket--hidden',
                  )}
                  src={ProgressRocketHalfThrustIconSrc}
                  alt=""
                  style={{ left: `${progress * 100}%` }}
                />
              </>
            )}
            <div className="onboarding-progress-bar__item__label">
              <span className="onboarding-progress-bar__item__number">
                <span className="onboarding-progress-bar__item__number__label">
                  {isDone ? <img src={ProgressCheckIconSrc} alt="" /> : i + 1}
                </span>
              </span>
              {step.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  trajectoryType: selectors.onboarding.getOnboardingTrajectorySelector(state),
});

export default connect(mapStateToProps)(memo(OnboardingProgressBar));
