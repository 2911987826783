import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { translate } from 'src/i18n';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';
import OsmoButton from 'src/components/buttons/OsmoButton';
import Loader from 'src/components/loader/Loader';
import { isSilk } from 'src/utils/device';

import EmailConnectionIllustration from 'src/assets/images/onboarding/email-connection-code.svg';

import './style.scss';

const ConnectionCodeDialog = () => {
  const history = useHistory();
  const [isCtaLoading, setIsCtaLoading] = useState(true);
  const game = history?.location?.state?.game;

  useEffect(() => {
    setTimeout(() => {
      setIsCtaLoading(false);
    }, 4000);
  }, []);

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  const _onClick = () => {
    if (!game) {
      return;
    }

    if (isSilk()) {
      window.open(game.appLinks.amazonFire);
    } else {
      window.open(game.appLinks.ipad);
    }
  };

  return (
    <div className="connection-code-dialog">
      <Container>
        <CenterContent>
          <MainHeader
            title={translate('onboarding.connectionCodeDialog.title')}
          />
        </CenterContent>
      </Container>

      <div className="connection-code-dialog-illustration">
        <Container>
          <CenterContent>
            <img
              src={EmailConnectionIllustration}
              alt=""
              className="connection-code-dialog-img"
            />
          </CenterContent>
        </Container>
      </div>

      <Container>
        <CenterContent>
          <p className="connection-code-dialog-subtitle">
            {translate('onboarding.connectionCodeDialog.content')}
            <b className="bold">
              {translate('onboarding.connectionCodeDialog.connectAccount')}
            </b>
            {translate('onboarding.connectionCodeDialog.content2')}
          </p>

          <div className="connection-code-dialog-buttons">
            <div
              className={`connection-code-dialog-loader${
                isCtaLoading ? ' displayed' : ''
              }`}
            >
              <Loader scale={0.3} />
            </div>
            <OsmoButton
              className={`connection-code-dialog-button${
                !isCtaLoading ? ' displayed' : ''
              }`}
              text={translate('onboarding.connectionCodeDialog.cta')}
              onClick={_onClick}
              isDisabled={!game}
            />
          </div>
        </CenterContent>
      </Container>
    </div>
  );
};

export default ConnectionCodeDialog;
