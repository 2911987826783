import React from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import Container from 'src/components/displays/Container';
import OnboardingFooter from 'src/components/footers/OnboardingFooter';
import FireInstructionsHeader from './components/FireInstructionsHeader';
import FireInstructionsCarrousel from './components/FireInstructionsCarrousel';

import { productsData } from 'src/data/products';

import { REDEEM_CODE_ROUTE } from 'src/router/redeem/routes';

import './style.scss';

const FireInstructions = (props) => {
  const { kit: kitFromProps } = props;

  /* * * Navigation * * */

  const { kit: kitFromParams } = useParams();
  const history = useHistory();

  /* * * Computed variables * * */

  const kit = kitFromParams || kitFromProps;

  const kitData = productsData[kit];

  if (!kit || !kitData) {
    console.error(`${kit} doesn't have a productsData config`);

    // Redirect to redeem if there's no data or kit param
    history.push(REDEEM_CODE_ROUTE);
    return null;
  }

  return (
    <div className="fire-instructions-page">
      <Container>
        {kitData.header && (
          <FireInstructionsHeader
            headerData={kitData.header}
            gamesIncluded={kitData.gamesIncluded}
          />
        )}
        <FireInstructionsCarrousel
          gamesIncluded={kitData.gamesIncluded}
          gameVersions={kitData.gameVersions}
        />
      </Container>
      <OnboardingFooter />
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(FireInstructions);
