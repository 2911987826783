import * as actions from 'src/actions';

export const trajectories = {
  CREATION: 'creation',
  CREATION_TEACHER: 'creation-teacher',
  LOGIN: 'login',
};

const initialState = {
  user: {},
  product: {},
  trajectory: trajectories.CREATION,
  tmpAccountInformation: {},
  redeemError: null,
  downloadedGames: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.onboarding.SET_TRAJECTORY_TYPE: {
      const { trajectory } = action.payload;

      return { ...initialState, ...state, trajectory };
    }

    case actions.onboarding.SET_PRODUCT: {
      const { product } = action.payload;

      return { ...initialState, ...state, product };
    }

    case actions.onboarding.SET_USER: {
      const { user } = action.payload;

      return { ...initialState, ...state, user };
    }

    case actions.onboarding.SET_REDEEM_ERROR: {
      const { redeemError } = action.payload;

      return {
        ...initialState,
        ...state,
        redeemError,
      };
    }

    case actions.onboarding.SET_TMP_ACCOUNT_INFORMATION: {
      const { tmpAccountInformation } = action.payload;

      return {
        ...initialState,
        ...state,
        tmpAccountInformation: {
          ...(state.tmpAccountInformation || {}),
          ...tmpAccountInformation,
        },
      };
    }

    case actions.onboarding.SET_DOWNLOADED_GAMES: {
      const { games } = action.payload;

      return {
        ...initialState,
        ...state,
        downloadedGames: [...state.downloadedGames, ...games],
      };
    }

    case actions.onboarding.SET_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
