import { createSelector } from 'reselect';

export const getRedeemObjectSelector = (state) => state.redeem || {};

// // ===========================================================================
// // ============================= EMAIL =======================================
// // ===========================================================================

// Get email
export const getRedeemEmailSelector = createSelector(
  getRedeemObjectSelector,
  (redeem) => {
    return redeem.email || '';
  },
);

// Get email verification id
export const getRedeemEmailVerificationIdSelector = createSelector(
  getRedeemObjectSelector,
  (redeem) => {
    return redeem.emailVerificationId || '';
  },
);

// // ===========================================================================
// // ============================= USER ========================================
// // ===========================================================================

// Get user
export const getRedeemUserSelector = createSelector(
  getRedeemObjectSelector,
  (redeem) => {
    return redeem.user || {};
  },
);

// Get user id
export const getRedeemUserIdSelector = createSelector(
  getRedeemUserSelector,
  (user) => {
    return user.user_id || '';
  },
);

// // ===========================================================================
// // ===================== TMP ACCOUNT INFORMATION =============================
// // ===========================================================================

// Get redeem code object
export const getTmpAccountInformationSelector = createSelector(
  getRedeemObjectSelector,
  (redeem) => {
    return redeem.tmpAccountInformation || '';
  },
);

// // ===========================================================================
// // ============================= REDEEM ======================================
// // ===========================================================================

// Get redeem code object
export const getRedeemCodeObjectSelector = createSelector(
  getRedeemObjectSelector,
  (redeem) => {
    return redeem.redeemCode || '';
  },
);

// Get redeem code
export const getRedeemCodeSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.code || '';
  },
);

// Get redeem devices
export const getRedeemDevicesSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.devices || [];
  },
);

// Get redeem single device
export const getRedeemDeviceSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.devices[0] || '';
  },
);

// Get boolean if there's multiple devices linked to that code
export const getHasMultipleDevicesSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.devices.length > 1 || false;
  },
);

// Get is already redeemed code
export const getIsAlreadyRedeemedCode = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.isRedeemed || false;
  },
);

// Get redeemed product
export const getRedeemProductSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.product || '';
  },
);

// Get is only digital apps
export const getIsOnlyDigitalAppsSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.digitalAppsOnly || false;
  },
);

export const getRedeemAppsSelector = createSelector(
  getRedeemCodeObjectSelector,
  (redeemCode) => {
    return redeemCode.apps || [];
  },
);
