import React from 'react';

/**
 *  Error icon
 */

const ErrorMessage = React.memo(() => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#ea3729" cx="8" cy="8" r="8" />
        <circle fill="#fff" cx="8" cy="12" r="1" />
        <rect fill="#fff" x="7" y="3" width="2" height="6.996" rx="1" />
      </g>
    </svg>
  );
});

export default ErrorMessage;
