import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import * as selectors from 'src/selectors';
import * as actions from 'src/actions';

/**
 *  This HoC can be used to easily
 *  access modal methods
 */

const withModal = (WrappedComponent) => (props) => {
  const { dispatch } = props;

  function showModal(type, props) {
    dispatch({
      type: actions.modal.SHOW_MODAL,
      payload: { type, props },
    });
  }

  function hideModal() {
    dispatch({
      type: actions.modal.HIDE_MODAL,
    });
  }

  return (
    <WrappedComponent showModal={showModal} hideModal={hideModal} {...props} />
  );
};

function mapStateToProps(state) {
  return {
    modalShow: selectors.modal.getModalShowSelector(state),
    modalProps: selectors.modal.getModalPropsSelector(state),
    modalType: selectors.modal.getModalTypeSelector(state),
  };
}

const enhancedWithModal = compose(connect(mapStateToProps), withModal);

export default enhancedWithModal;
