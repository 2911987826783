import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';
import DevicePicker from './components/DevicePicker';

import { devicePickerData } from 'src/data/redeem-flow/devicePicker';

import {
  REDEEM_CODE_ROUTE,
  CONTINUE_SETUP_ROUTE,
} from 'src/router/redeem/routes';

import './style.scss';

const SelectDevice = (props) => {
  const { dispatch, devices } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onDeviceClick(device) {
    dispatch({
      type: actions.redeem.SET_REDEEM_CODE_DEVICE,
      payload: {
        device,
      },
    });

    history.push(CONTINUE_SETUP_ROUTE);
  }

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    if (!devices || !devices.length) {
      history.push(REDEEM_CODE_ROUTE);
    }
  });

  return (
    <div className="select-device-page">
      <Container>
        <CenterContent>
          <MainHeader title="What device are you playing on?" />
          <div className="select-device-page__devices">
            {!!devices?.length &&
              devices.map((device) => {
                return devicePickerData[device] ? (
                  <DevicePicker
                    key={device}
                    illustration={devicePickerData[device].illustration}
                    logo={devicePickerData[device].logo}
                    onClick={() => _onDeviceClick(device)}
                  />
                ) : null;
              })}
          </div>
        </CenterContent>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    devices: selectors.redeem.getRedeemDevicesSelector(state),
  };
}

export default connect(mapStateToProps)(SelectDevice);
