import { carrouselComponents } from 'src/data/carrousel';

import FireOnboardingStep01a from 'src/assets/images/instructions/fire-onboarding-step-01-a.jpg';
import FireOnboardingStep01b from 'src/assets/images/instructions/fire-onboarding-step-01-b.jpg';
import FireOnboardingStep02a from 'src/assets/images/instructions/fire-onboarding-step-02-a.jpg';
import FireOnboardingStep02b from 'src/assets/images/instructions/fire-onboarding-step-02-b.jpg';
import FireOnboardingStep02c from 'src/assets/images/instructions/fire-onboarding-step-02-c.jpg';
import FireOnboardingStep02d from 'src/assets/images/instructions/fire-onboarding-step-02-d.jpg';
import FireOnboardingStep03a from 'src/assets/images/instructions/fire-onboarding-step-03-a.jpg';
import FireOnboardingStep03b from 'src/assets/images/instructions/fire-onboarding-step-03-b.jpg';
import FireOnboardingStep03c from 'src/assets/images/instructions/fire-onboarding-step-03-c.jpg';
import FireOnboardingStep04a from 'src/assets/images/instructions/fire-onboarding-step-04-a.jpg';
import FireOnboardingStep04b from 'src/assets/images/instructions/fire-onboarding-step-04-b.jpg';
import FireOnboardingStep04c from 'src/assets/images/instructions/fire-onboarding-step-04-c.jpg';
import FireOnboardingStep04d from 'src/assets/images/instructions/fire-onboarding-step-04-d.jpg';
import FireOnboardingStep04e from 'src/assets/images/instructions/fire-onboarding-step-04-e.jpg';
import FireOnboardingStep05a from 'src/assets/images/instructions/fire-onboarding-step-05-a.jpg';
import FireOnboardingStep05b from 'src/assets/images/instructions/fire-onboarding-step-05-b.jpg';
import FireOnboardingStep05c from 'src/assets/images/instructions/fire-onboarding-step-05-c.jpg';
import AmazonKidsLogo from 'src/assets/images/instructions/amazon-kids-logo.jpg';
import AppStoreLogo from 'src/assets/images/instructions/app-store-logo.jpg';

export const fireInstructionsData = (games, versions) => {
  const hasMultipleGames = games?.length > 1;
  const wordingForGame = hasMultipleGames ? 'games' : 'game';

  return [
    {
      label: 'Check your current profile on your Fire Tablet',
      content: [
        {
          type: carrouselComponents.TEXT,
          noBorder: true,
          text: {
            __html: `To download your ${wordingForGame}, you must be on an adult profile—games cannot be downloaded on the child profile (Amazon Kids).`,
          },
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep01a,
                alt: 'Fire Onboarding Step 1a',
              },
              labels: {
                title: 'Child Profile (Amazon Kids)',
                text: {
                  __html: `<p>Within Amazon Kids, your child will only have access to the content you provide.</p><p>If you are on a child profile, continue to <span class='is-emphasized'>step 02.</span></p>`,
                },
              },
            },
            {
              image: {
                src: FireOnboardingStep01b,
                alt: 'Fire Onboarding Step 1b',
              },
              labels: {
                title: 'Parent Profile',
                text: {
                  __html: `<p>When in an adult profile, all content on the Fire Tablet is accessible</p><p>If you are on a parent profile, continue to <span class='is-emphasized'>step 03.</span></p>`,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Switch from a child profile (Amazon Kids) to an adult profile',
      content: [
        {
          type: carrouselComponents.IMAGES,
          noBorder: true,
          images: [
            {
              image: {
                src: FireOnboardingStep02a,
                alt: 'Fire Onboarding Step 02a',
              },
              labels: {
                text: {
                  __html: `a) Start by <em class='bold'>swiping down from the top of the screen</em> to reveal the quick settings menu.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep02b,
                alt: 'Fire Onboarding Step 02b',
              },
              labels: {
                text: {
                  __html: `b) Press the <em class='bold'>exit profile icon</em> to exit the child profile.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep02c,
                alt: 'Fire Onboarding Step 02c',
              },
              labels: {
                text: {
                  __html: `c) One the top right of the screen, <em class='bold'>select your adult profile</em>.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep02d,
                alt: 'Fire Onboarding Step 02d',
              },
              labels: {
                text: {
                  __html: `d) If your profile is password protected, you will need to <em class='bold'>enter your pin</em> to exit the child profile.`,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: `Download your ${wordingForGame} from an adult profile`,
      content: [
        {
          type: carrouselComponents.IMAGES,
          noBorder: true,
          images: [
            {
              image: {
                src: FireOnboardingStep03a,
                alt: 'Fire Onboarding Step 03a',
              },
              labels: {
                text: {
                  __html: `<p>a) On the home screen, select the <em class='bold'>Appstore</em> icon to find your ${wordingForGame}.</p><img src='${AppStoreLogo}' class='logo' alt='App Store logo' />`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep03b,
                alt: 'Fire Onboarding Step 03b',
              },
              labels: {
                text: {
                  __html: `b) Tap the <em class='bold'>search icon</em> on the top right of the screen to search for your ${wordingForGame}${
                    !hasMultipleGames ? '.' : ', which are listed below.'
                  }`,
                },
                games: hasMultipleGames,
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep03c,
                alt: 'Fire Onboarding Step 03c',
              },
              labels: {
                text: {
                  __html: `c) Find your game in the search results, and tap the <em class='bold'>GET</em> button to download. ${
                    !hasMultipleGames
                      ? ''
                      : ` Repeat this process for all ${games?.length} of your games.`
                  }`,
                },
              },
            },
          ],
        },
        // Add a special section if there are different versions for this product
        ...getVersions(versions),
      ],
    },
    {
      label: `Share content from an adult profile to a child profile (Amazon Kids)`,
      content: [
        {
          type: carrouselComponents.IMAGES,
          noBorder: true,
          images: [
            {
              image: {
                src: FireOnboardingStep04a,
                alt: 'Fire Onboarding Step 04a',
              },
              labels: {
                text: {
                  __html: `a) On the home screen, select the <em class='bold'>Amazon Kids</em> app.</p><img src='${AmazonKidsLogo}' class='logo' alt='Amazon Kids logo' />`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep04b,
                alt: 'Fire Onboarding Step 04b',
              },
              labels: {
                text: {
                  __html: `b) Tap the <em class='bold'>settings/gear icon</em> to the right of the child profile.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep04c,
                alt: 'Fire Onboarding Step 04c',
              },
              labels: {
                text: {
                  __html: `c) Select <em class='bold'>add content</em>.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep04d,
                alt: 'Fire Onboarding Step 04d',
              },
              labels: {
                text: {
                  __html: `d) Select <em class='bold'>share content</em>.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep04e,
                alt: 'Fire Onboarding Step 04e',
              },
              labels: {
                text: {
                  __html: `e) Select the <em class='bold'>GAMES & APPS</em> tab, and then select the ${wordingForGame} you’ve downloaded (a checkmark will appear). Press the <em class='bold'>DONE</em> button when you are finished.`,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: `Switch to a child profile (Amazon Kids) to install and play the ${wordingForGame}!`,
      content: [
        {
          type: carrouselComponents.IMAGES,
          noBorder: true,
          images: [
            {
              image: {
                src: FireOnboardingStep05a,
                alt: 'Fire Onboarding Step 05a',
              },
              labels: {
                text: {
                  __html: `a) Press the back arrow to return to the Amazon Kids profiles. Then <em class='bold'>tap on the child profile</em> to enter it.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep05b,
                alt: 'Fire Onboarding Step 05b',
              },
              labels: {
                text: {
                  __html: `b) Select the <em class='bold'>For You</em> tab. The ${wordingForGame} you’ve added will appear in the <em class='bold'>Recent section</em>. Tap on the ${wordingForGame} to install ${
                    hasMultipleGames ? 'them' : 'it'
                  }.`,
                },
              },
            },
          ],
        },
        {
          type: carrouselComponents.IMAGES,
          images: [
            {
              image: {
                src: FireOnboardingStep05c,
                alt: 'Fire Onboarding Step 05c',
              },
              labels: {
                text: {
                  __html: `c) Once installation is complete, the app icon will have a checkmark. Your game is now ready to play!`,
                },
              },
            },
          ],
        },
      ],
    },
  ];
};

const getVersions = (versions) => {
  if (!versions || versions.length < 1) {
    return [];
  }

  return [
    {
      type: carrouselComponents.TEXT,
      text: {
        __html: `Note that there are versions of our apps intended for classrooms, labeled “EDUCATION” on the app icon. These should only be downloaded if you are using these apps within a school setting.`,
      },
    },
    {
      type: carrouselComponents.GAMES_VERSIONS,
    },
  ];
};
