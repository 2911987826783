import React, { useState, useEffect } from 'react';

import { translate } from 'src/i18n';

import MainHeader from 'src/components/headers/MainHeader';
import withLoading from 'src/components/hoc/withLoading';
import OsmoCheckbox from 'src/components/inputs/OsmoCheckbox';
import OsmoButton from 'src/components/buttons/OsmoButton';
import ErrorMessage from 'src/components/messages/ErrorMessage';
import ConsentItem from './components/ConsentItem';

import LockSVG from 'src/assets/images/illustrations/lock.svg';
import FolderSvg from 'src/assets/images/illustrations/folder.svg';

import './style.scss';
import ExitIntentPopup from './components/ConsentItem/ExitIntentPopup';

const Consent = (props) => {
  const {
    isLoading,
    onNext,
    setEmailsAreChecked,
    emailsAreChecked,
    submitButtonLabel,
    disableSubmitButton = false,
    errors: errorsFromParent = {},
  } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [termsAreChecked, setTermsAreChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [nextClickedOnce, setNextClickedOnce] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    setErrors({
      ...errors,
      ...errorsFromParent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsFromParent]);

  // ===========================================================================
  // ============================ ERRORS =====================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // Check if all fields are filled, if not display errors
  function _checkIfFieldsAreFilled() {
    let newErrors = {};

    if (!termsAreChecked) {
      newErrors.terms = {
        message: translate('consent.acceptTermsErrors'),
      };
    }

    setErrors({ ...newErrors });
  }

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _handleEmailsChange() {
    setEmailsAreChecked(!emailsAreChecked);
  }

  function _handleTermsChange() {
    setTermsAreChecked(!termsAreChecked);
  }

  function _onSubmit() {
    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Remove all errors to update to new ones
    _resetErrors();

    // Display required errors
    _checkIfFieldsAreFilled();

    // If one field is empty return function
    if (!termsAreChecked) {
      return;
    }

    if (!nextClickedOnce && !emailsAreChecked) {
      setNextClickedOnce(true);
      setShowModal(true);
      return;
    }

    onNext();
  }

  // ===========================================================================
  // ====================== COMPUTED VARIABLES =================================
  // ===========================================================================

  function _getSubHeading() {
    const privacyPolicyUrl = 'https://www.playosmo.com/--/privacy-policy/';
    const termsAndConditionsUrl = 'https://www.playosmo.com/en/terms/';

    return (
      <div className="consent__subheading">
        {translate('consent.subheader')}
        <div className="consent__links">
          <a
            href={termsAndConditionsUrl}
            target="_blank"
            className="consent__link"
            rel="noopener noreferrer"
          >
            {translate('general.termsAndConditions')}
          </a>
          <a
            href={privacyPolicyUrl}
            target="_blank"
            className="consent__link"
            rel="noopener noreferrer"
          >
            {translate('general.privacyPolicy')}
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="consent">
      <MainHeader title={translate('consent.title')} />
      {_getSubHeading()}
      <ExitIntentPopup
        showModal={showModal}
        setShowModal={setShowModal}
        setEmailsAreChecked={setEmailsAreChecked}
        onNext={onNext}
      />
      <ConsentItem
        title={translate('consent.dataTitle')}
        content={translate('consent.dataContent')}
        image={FolderSvg}
      />

      <ConsentItem
        title={translate('consent.standardsTitle')}
        content={translate('consent.standardsContent')}
        image={LockSVG}
      />

      <OsmoCheckbox
        className="consent__terms"
        id="emails"
        label={translate('consent.emailSubscription')}
        onChange={_handleEmailsChange}
        checked={emailsAreChecked}
        error={errors.emails}
      />

      <OsmoCheckbox
        className="consent__terms"
        id="terms"
        label={translate('consent.acceptTCs')}
        onChange={_handleTermsChange}
        checked={termsAreChecked}
        error={errors.terms}
      />

      {errors?.status && (
        <div className="consent__message">
          <ErrorMessage message={errors?.status?.message} />
        </div>
      )}

      <div className="consent__button">
        <OsmoButton
          text={submitButtonLabel || translate('general.next')}
          onClick={_onSubmit}
          isDisabled={disableSubmitButton || !termsAreChecked || showModal}
        />
      </div>
    </div>
  );
};

export default withLoading(Consent);
