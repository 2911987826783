import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const CarrouselText = (props) => {
  const { component } = props;

  const { text, noBorder } = component;

  if (!text) {
    return null;
  }

  const carrouselImagesClassNames = classNames('carrousel-text', {
    'carrousel-text--no-border': noBorder,
  });

  return (
    <div className={carrouselImagesClassNames} dangerouslySetInnerHTML={text} />
  );
};

CarrouselText.propTypes = {
  component: PropTypes.object.isRequired,
};

export default CarrouselText;
