import React from 'react';
import moment from 'moment';

import SiteHeader from 'src/components/headers/SiteHeader';
import FireInstructions from 'src/pages/onboarding/FireInstructions';
import GetMathWizardNotReleased from 'src/pages/games/GetMathWizardNotReleased';

import './style.scss';

const GetMathWizardFire = () => {
  const hasBeenReleased = moment().isAfter('2020-11-01');

  if (!hasBeenReleased) {
    return <GetMathWizardNotReleased />;
  }

  return (
    <>
      <SiteHeader />
      <FireInstructions kit="math-wizard" hideGamesInHeader={true} />
    </>
  );
};

export default GetMathWizardFire;
