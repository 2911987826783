import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProductSelection from 'src/pages/redeemFaq/ProductSelection';
import ProductInstructions from 'src/pages/redeemFaq/ProductInstructions';

import * as routes from './routes';

const RedeemRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.PRODUCT_SELECTION_ROUTE}
        component={ProductSelection}
      />
      <Route
        exact
        path={routes.PRODUCT_INSTRUCTIONS_ROUTE}
        component={ProductInstructions}
      />
    </Switch>
  );
};

export default RedeemRoutes;
