import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import withLoading from 'src/components/hoc/withLoading';
import withModal from 'src/components/hoc/withModal';
import VerificationCodeForm from 'src/modules/VerificationCodeForm';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

import { modalTypes } from 'src/data/modals';

import { ENTER_EMAIL_ROUTE, REDEEM_CODE_ROUTE } from 'src/router/redeem/routes';

import './style.scss';

const VerifyEmail = (props) => {
  const {
    email,
    dispatch,
    setLoadingOn,
    setLoadingOff,
    showModal,
    hideModal,
  } = props;

  const history = useHistory();
  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================ HOOKS ========================================
  // ===========================================================================

  useEffect(() => {
    if (!email) {
      history.push(REDEEM_CODE_ROUTE);
    }
  });

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onComplete({ verificationCode }) {
    // Dispatch loading on
    setLoadingOn();

    // Dispatch request
    dispatch({
      type: actions.redeem.SEND_VERIFICATION_CODE_TO_SERVER_SAGA,
      payload: {
        params: {
          email,
          verificationCode: verificationCode.current,
        },
        callback: ({ nextRoute, shouldDisplayWarnModal } = {}) => {
          setLoadingOff();

          // Open a modal that warn that it is a new account
          if (!!shouldDisplayWarnModal) {
            // Clear email and return to email form
            const onCancel = () => {
              hideModal();

              dispatch({
                type: actions.redeem.CLEAR_EMAIL,
              });

              history.push(ENTER_EMAIL_ROUTE);
            };

            // Go to next route
            const onContinue = () => {
              hideModal();

              history.push(nextRoute);
            };

            showModal(modalTypes.WARNING_NEW_ACCOUNT, {
              onContinue,
              onCancel,
            });
          } else if (nextRoute) {
            history.push(nextRoute);
          }
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            code: {
              message,
            },
          });
        },
      },
    });
  }

  return (
    <div className="verify-email-page">
      <Container>
        <CenterContent>
          <MainHeader title="Verify your email address" />
          <VerificationCodeForm
            onComplete={_onComplete}
            email={email}
            errors={errors}
          />
        </CenterContent>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    email: selectors.redeem.getRedeemEmailSelector(state),
  };
}

export default connect(mapStateToProps)(
  compose(withLoading, withModal)(VerifyEmail),
);
