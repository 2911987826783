import React from 'react';
import PropTypes from 'prop-types';

import OsmoButton from 'src/components/buttons/OsmoButton';

import './style.scss';

const KeepYourCodeModal = (props) => {
  const { onContinue } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleClick() {
    if (onContinue) {
      onContinue();
    }
  }

  return (
    <div className="keep-your-code-modal">
      <div className="keep-your-code-modal__title">
        {'Please Keep Your Code Safe'}
      </div>
      <p className="keep-your-code-modal__text">
        {
          'Do not share your redemption code. If the code is used on a different account, you will lose access to the games on this account.'
        }
      </p>
      <div className="keep-your-code-modal__button">
        <OsmoButton text={'Got it'} onClick={_handleClick} />
      </div>
    </div>
  );
};

KeepYourCodeModal.propTypes = {
  onContinue: PropTypes.func,
};

export default KeepYourCodeModal;
