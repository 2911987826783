// fetch polyfill for unsupported browsers.
import 'whatwg-fetch';

import { buildQueryParameters } from './http';

import { getApiUrl } from 'src/config/environment-urls';

const DEFAULT_REQUEST_TIMEOUT = 25000;

function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('timeout'));
    }, ms);
    promise.then(resolve, reject);
  });
}

export function fetchOsmoServer(
  params,
  returnResponse = false,
  timeout = DEFAULT_REQUEST_TIMEOUT,
) {
  const headers = {
    Accept: 'application/json',
  };

  if (params.method === 'POST' || params.method === 'PUT') {
    if (params.body && params.body instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
    }
  }

  if (params.requestToken) {
    headers['X-OSMO-Request-Token'] = params.requestToken;
  }

  if (params.accountCreationToken) {
    headers['X-osmo-account-creation-token'] = params.accountCreationToken;
  }

  const data = {
    method: params.method || 'GET',
    headers,
    credentials: 'include',
  };

  if (params.body) {
    if (params.body instanceof FormData) {
      data.body = params.body;
    } else {
      data.body = JSON.stringify(params.body);
    }
  }

  let { endpoint } = params;
  if (params.urlParams) {
    endpoint += buildQueryParameters(params.urlParams);
  }

  const apiUrl = params.apiUrl || getApiUrl();
  const url = `${apiUrl}${endpoint}`;

  return timeoutPromise(
    timeout || DEFAULT_REQUEST_TIMEOUT,
    fetch(url, data),
  ).then((response) => {
    return returnResponse ? response : response.json();
  });
}
