import React from 'react';
import { Route } from 'react-router-dom';

import SurveyCallback from 'src/pages/survey/SurveyCallback';

import * as routes from './routes';

const SurveyRoutes = () => [
  <Route
    key={routes.SURVEY_ROUTE}
    path={routes.SURVEY_ROUTE}
    component={SurveyCallback}
  />,
];

export default SurveyRoutes;
