export function buildQueryParameters(params) {
  const queryStr = Object.keys(params)
    .map((key) => {
      return params[key] && params[key].length > 0
        ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        : '';
    })
    .join('&');
  return '?' + queryStr;
}
