import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { translate } from 'src/i18n';

import { trajectories } from 'src/store/onboarding/reducers';

import * as selectors from 'src/selectors';

import { ONBOARDING_DOWNLOAD_ROUTE } from 'src/router/onboarding/routes';

import Lottie from 'src/components/animations/Lottie';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

import animationData from './animation/lottie-data.js';
import subscribedAnimation from './animation/subscribed-lottie-data.json';

import './style.scss';

const Welcome = React.memo(
  ({
    // From Redux
    trajectoryType,
    user,
  }) => {
    const userSubscribedPromo =
      user &&
      user.welcome_credits &&
      user.welcome_credits.credits_eligibility &&
      user.welcome_credits.credits_eligibility === true;

    const history = useHistory();
    const [playTitleAnimation, setPlayTitleAnimation] = useState(false);
    const [playLottieAnimation, setPlayLottieAnimation] = useState(false);
    const [playBottomAnimation, setPlayBottomAnimation] = useState(false);
    const [playExitAnimation, setPlayExitAnimation] = useState(false);
    const [
      lottieAnimationStartsPlaying,
      setLottieAnimationStartsPlaying,
    ] = useState(false);

    // ===========================================================================
    // ================================ HOOKS ====================================
    // ===========================================================================

    useEffect(() => {
      setPlayTitleAnimation(true);
      setTimeout(() => {
        setPlayLottieAnimation(true);
      }, 400);
    }, []);

    useEffect(() => {
      if (!lottieAnimationStartsPlaying) {
        return;
      }
      setTimeout(() => {
        setPlayBottomAnimation(true);
      }, 1300);
      setTimeout(() => {
        setPlayExitAnimation(true);
      }, 3800);
      setTimeout(() => {
        history.push(ONBOARDING_DOWNLOAD_ROUTE);
      }, 4000);
    }, [lottieAnimationStartsPlaying, history]);

    return (
      <div className="onboarding-welcome">
        <Container>
          <CenterContent>
            <div
              className={classNames('onboarding-welcome__content', {
                'onboarding-welcome__content--exited': playExitAnimation,
              })}
            >
              <div
                className={classNames('onboarding-welcome__title', {
                  'onboarding-welcome__title--displayed': playTitleAnimation,
                })}
              >
                {trajectoryType === trajectories.LOGIN
                  ? translate('onboarding.welcome.welcomeBack')
                  : userSubscribedPromo
                  ? translate('onboarding.welcome.great')
                  : translate('onboarding.welcome.welcome')}
              </div>

              <MainHeader
                title={
                  trajectoryType === trajectories.LOGIN
                    ? translate('onboarding.welcome.subtitleAgain', {
                        firstName: user?.first_name || '',
                      })
                    : userSubscribedPromo
                    ? translate('onboarding.welcome.stepAwayFrom$20')
                    : translate('onboarding.welcome.subtitleMeet', {
                        firstName: user?.first_name || '',
                      })
                }
                className={classNames('onboarding-welcome__subtitle', {
                  'onboarding-welcome__subtitle--displayed': playTitleAnimation,
                })}
              />

              <Lottie
                animationData={
                  userSubscribedPromo && trajectoryType !== trajectories.LOGIN
                    ? subscribedAnimation
                    : animationData
                }
                play={playLottieAnimation}
                height={530}
                settings={{
                  loop: false,
                  autoplay: false,
                }}
                onPlay={() => setLottieAnimationStartsPlaying(true)}
              />

              <div
                className={classNames('onboarding-welcome__bottom', {
                  'onboarding-welcome__bottom--displayed': playBottomAnimation,
                })}
              >
                {userSubscribedPromo && trajectoryType !== trajectories.LOGIN
                  ? translate('onboarding.welcome.nextStep$20')
                  : translate('onboarding.welcome.nextStep')}
              </div>
            </div>
          </CenterContent>
        </Container>
      </div>
    );
  },
);

const mapStateToProps = (state) => ({
  trajectoryType: selectors.onboarding.getOnboardingTrajectorySelector(state),
  user: selectors.onboarding.getOnboardingUserSelector(state),
});

export default connect(mapStateToProps)(Welcome);
