import React from 'react';
import { translate } from 'src/i18n';

import './style.scss';

const EmailHeader = (props) => {
  const { email } = props;

  return (
    <div className="email-header">
      {translate('verifyEmail.messageSentTo')}
      <p className="email-header__email">{email}</p>
    </div>
  );
};

export default EmailHeader;
