import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import * as selectors from 'src/selectors';

import Loading from 'src/components/loader/Loading';
import ModalRoot from 'src/components/modals/ModalRoot';
import SiteHeader from 'src/components/headers/SiteHeader';

import GA from 'src/components/analytics/GoogleAnalytics';

import RedeemRoutes from './redeem';
import RedeemFaqRoutes from './redeemFaq';
import OnboardingRoutes from './onboarding';
import ParentRoutes from './parent';
import GamesRoutes from './games';
import SurveyRoutes from './survey';

import { REDEEM_PATH } from './redeem/routes';
import { REDEEM_FAQ_PATH } from './redeemFaq/routes';
import { ONBOARDING_PATH, ONBOARDING_WELCOME_ROUTE } from './onboarding/routes';

const pagesWithoutHeader = [
  'onboarding/start',
  'onboarding/setup',
  ONBOARDING_WELCOME_ROUTE,
];

export const hideHeaderForPath = (path) => {
  for (let index = 0; index < pagesWithoutHeader.length; index++) {
    const element = pagesWithoutHeader[index];
    if (path.includes(element)) {
      return true;
    }
  }
  return false;
};

const hasTouch = () => {
  return (
    'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

const AppRoutes = (props) => {
  const { isLoading } = props;
  const location = useLocation();
  const isFireOnboarding = location.pathname.includes('/fire/');
  const isOnboarding = location.pathname.includes('/onboarding/');

  useEffect(() => {
    let b = document.documentElement;
    b.setAttribute('data-useragent', navigator.userAgent);
    b.setAttribute('data-platform', navigator.platform);
    b.setAttribute('data-fire', navigator.platform?.includes('Linux armv'));
    if (hasTouch()) b.setAttribute('data-touch', true);
  });

  return (
    <Switch>
      {/* Games */}
      {GamesRoutes()}

      {/* Survey */}
      {SurveyRoutes()}

      <>
        {/* Loading */}
        {isLoading && <Loading />}

        {/* Modal */}
        <ModalRoot />

        {/* Header */}
        {!hideHeaderForPath(location.pathname) && (
          <SiteHeader
            size={isOnboarding && !isFireOnboarding ? 'big' : undefined}
            color={isOnboarding && !isFireOnboarding ? 'white' : undefined}
          />
        )}

        {/* Routes */}

        {/* Redeem */}
        <Route path={REDEEM_PATH} component={RedeemRoutes} />

        {/* Redeem Faq */}
        <Route path={REDEEM_FAQ_PATH} component={RedeemFaqRoutes} />

        {/* Onboarding */}
        <Route path={ONBOARDING_PATH} component={OnboardingRoutes} />

        {/* Parent */}
        {ParentRoutes()}
      </>
    </Switch>
  );
};

const AppRouter = (props) => {
  const { isLoading } = props;

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        {/* Google Analytics */}
        {GA.init() && <GA.RouteTracker />}

        <AppRoutes isLoading={isLoading} />
      </QueryParamProvider>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: selectors.loading.getIsLoadingSelector(state),
  };
}

export default connect(mapStateToProps)(AppRouter);
