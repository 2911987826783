import * as actions from 'src/actions';

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.loading.SET_LOADING_OFF: {
      return { ...initialState, ...state, isLoading: false };
    }

    case actions.loading.SET_LOADING_ON: {
      return { ...initialState, ...state, isLoading: true };
    }

    default:
      return state;
  }
}
