import React from 'react';

import MainHeader from 'src/components/headers/MainHeader';

import { isIpad } from 'src/utils/device';

import { appsData } from 'src/data/redeem-flow/apps';

import OsmoWorldIconSrc from 'src/assets/images/icons/osmo-world.png';

import './style.scss';

const IpadContinuationComponent = (props) => {
  const { isOnlyDigitalApps, apps } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function onAppClick(url) {
    if (!isIpad() || !url) {
      return;
    }

    // If device is ipad then redirect to app store
    window.open(url);
  }

  function onOsmoWorldClick() {
    if (!isIpad()) {
      return;
    }

    // If device is ipad then redirect to app store
    window.open('https://apps.apple.com/us/app/osmo-world/id1350943542');
  }

  return (
    <div className="ipad-continuation-component">
      <MainHeader
        title={`Your ${
          apps.length > 1 ? 'games have' : 'game has'
        } been unlocked!`}
      />

      <div className="ipad-continuation-component__container">
        <div className="ipad-continuation-component__instructions-title">
          {'Continue your Osmo adventure with these steps:'}
        </div>

        <p className="ipad-continuation-component__instruction">
          {/* Sentence is different depending on displaying games or Osmo World */}
          {isOnlyDigitalApps
            ? `1.	On your iPad, download ${
                apps.length > 1 ? 'these apps' : 'this app'
              } from the App store:`
            : '1.	On your iPad, download Osmo World from the App Store'}
        </p>

        {isOnlyDigitalApps && !!apps.length ? (
          <div className="ipad-continuation-component__osmo-apps">
            {apps.map((app) => {
              // Check if data for this app exists
              if (!appsData[app]) {
                return null;
              }

              return (
                <div
                  className="ipad-continuation-component__osmo-app"
                  onClick={() => onAppClick(appsData[app].storeUrl)}
                  key={appsData[app].name}
                >
                  <img
                    className="ipad-continuation-component__osmo-app-icon"
                    src={appsData[app].icon.src}
                    alt={appsData[app].icon.alt}
                  />
                  <p className="ipad-continuation-component__osmo-app-label">
                    {appsData[app].name}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="ipad-continuation-component__osmo-app"
            onClick={onOsmoWorldClick}
          >
            <img
              className="ipad-continuation-component__osmo-app-icon"
              src={OsmoWorldIconSrc}
              alt="Osmo World"
            />
            <p className="ipad-continuation-component__osmo-app-label">
              {'Osmo World'}
            </p>
          </div>
        )}

        <p className="ipad-continuation-component__instruction">
          {'2.	Login with the '}
          <strong>{'same email address'}</strong>
          {' you used to redeem your code'}
        </p>

        {!isOnlyDigitalApps ? (
          <>
            <p className="ipad-continuation-component__instruction">
              {'3.	Find your product'}
            </p>
            <p className="ipad-continuation-component__instruction">
              {`4.	Download your ${
                apps.length > 1 ? 'games' : 'game'
              } and start playing!`}
            </p>
          </>
        ) : (
          <p className="ipad-continuation-component__instruction">
            {`3.	Start playing your ${apps.length > 1 ? 'games' : 'game'}!`}
          </p>
        )}

        <p className="ipad-continuation-component__link-to-faq">
          <span>{'Questions? '}</span>
          <a href="https://playosmo.com/redeemfaq" target="__blank">
            {'View our FAQ'}
          </a>
        </p>
      </div>
    </div>
  );
};

export default IpadContinuationComponent;
