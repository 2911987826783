"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var platformNames = {
    ipad: 'iPad',
    fire: 'Fire',
};
function platformName(platform) {
    return platformNames[platform] || platform;
}
exports.default = platformName;
