import { fetchOsmoServer } from 'src/apis/helpers/fetch';
import { getPlayOsmoApiUrl } from 'src/config/environment-playosmo-urls';

export async function getInfo(params) {
  const response = await fetchOsmoServer({
    endpoint: 'api/info',
    method: 'GET',
    apiUrl: getPlayOsmoApiUrl(),
  });

  return response;
}
