"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultColor = void 0;
exports.defaultColor = {
    neutralLight: '#fff',
    primary: '#ff8a33',
    primaryDark: '#f77219',
    red: '#ea3729',
    disabledInput: '#eeeeee',
    orange: '#ff8a33',
    // grays
    gray10: '#373737',
    gray20: '#595959',
    gray30: '#818181',
    gray40: '#aaaaaa',
    gray50: '#c9c9c9',
    gray60: '#e1e1e1',
    gray70: '#eeeeee',
    gray80: '#f5f5f5',
};
