import * as Sentry from '@sentry/browser';

import { IS_LOCAL, IS_PRODUCTION } from 'src/config/environment';
import { SENTRY_ENVIRONMENT, SENTRY_DSN } from 'src/config/sentry';

class ErrorsService {
  init = () => {
    if (!IS_LOCAL) {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
      });
    }
  };

  log = (e, extra) => {
    if (!IS_PRODUCTION) {
      console.info(e, extra);
    }

    if (!IS_LOCAL) {
      this.capture(e, extra);
    }
  };

  report = (e, extra) => {
    if (!IS_LOCAL) {
      Sentry.captureException(e, {
        extra,
      });
    }
    this.log(e, extra);
  };

  capture = (message, extra) => {
    Sentry.addBreadcrumb({
      message,
      category: 'capture',
      data: extra || null,
    });
  };
}

const Service = new ErrorsService();

export const log = Service.log;
export const report = Service.report;
export default Service;
