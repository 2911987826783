import React from 'react';
import { useLocation } from 'react-router-dom';

import { translate } from 'src/i18n';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';

import OsmoLogo from 'src/assets/images/onboarding/osmo-original-logo.svg';
import IpadIlluSrc from 'src/assets/images/onboarding/illustration-ipad.svg';
import MobileIpadIlluSrc from 'src/assets/images/onboarding/illustration-ipad-mobile.svg';

import './style.scss';

const VisitPageWithSupportedDevice = () => {
  const { pathname } = useLocation();
  const productTargetDevice = pathname.includes('fire') ? 'fire' : 'ipad';
  return (
    <div className="app-container">
      <Container>
        <CenterContent>
          <div className="onb-visit-page-with-supported-device">
            <div className="onb-visit-page-with-supported-device__container">
              <div className="onb-visit-page-with-supported-device__content">
                <img
                  src={OsmoLogo}
                  className="onb-visit-page-with-supported-device__osmo-logo"
                  alt="Osmo by BYJU's Logo"
                />

                <p className="onb-visit-page-with-supported-device__title">
                  {translate('onboarding.visitSupportedDevice.title')}
                </p>
                <p className="onb-visit-page-with-supported-device__text">
                  {productTargetDevice === 'ipad'
                    ? translate('onboarding.visitSupportedDevice.ipadContent')
                    : productTargetDevice === 'fire'
                    ? translate('onboarding.visitSupportedDevice.fireContent')
                    : translate(
                        'onboarding.visitSupportedDevice.unknownDeviceContent',
                      )}
                </p>
                <p className="onb-visit-page-with-supported-device__promise">
                  {translate('onboarding.visitSupportedDevice.promise')}
                </p>
              </div>

              <div className="onb-visit-page-with-supported-device__devices">
                <img
                  className="onb-visit-page-with-supported-device__device desktop"
                  src={IpadIlluSrc}
                  alt=""
                />
                <img
                  className="onb-visit-page-with-supported-device__device mobile"
                  src={MobileIpadIlluSrc}
                  alt=""
                />
              </div>
            </div>
          </div>
        </CenterContent>
      </Container>
    </div>
  );
};

export default VisitPageWithSupportedDevice;
