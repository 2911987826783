import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { translate } from 'src/i18n';

import ErrorMessage from 'src/components/messages/ErrorMessage';

import './style.scss';

const CodeRetry = (props) => {
  const { onRetry, isLoading, retried, error } = props;

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onRetry() {
    // Disabled on retry if already retried or loading
    if (retried || isLoading) {
      return;
    }

    if (onRetry) {
      onRetry();
    }
  }

  const codeRetryLinkClassNames = classNames('code-retry__link', {
    'code-retry__link--disabled': isLoading,
  });

  return (
    <div className="code-retry">
      <div className="code-retry__inner">
        {retried ? (
          <>
            <span className="code-retry__new-email">
              {translate('verifyEmail.emailSent')}
            </span>
          </>
        ) : (
          <>
            <span>{translate('verifyEmail.emailNotReceived')}</span>
            <span className={codeRetryLinkClassNames} onClick={_onRetry}>
              {translate('verifyEmail.tryAgain')}
            </span>
          </>
        )}
      </div>
      {error && (
        <div className="code-retry__message">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </div>
  );
};

CodeRetry.propTypes = {
  onRetry: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  retried: PropTypes.bool,
  error: PropTypes.object,
};

export default CodeRetry;
