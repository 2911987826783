import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import classNames from 'classnames';

import { translate } from 'src/i18n';
import * as actions from 'src/actions';

import { isOldFireDevice } from 'src/utils/device';

import pathsMapping from 'src/data/onboarding/paths-mapping.js';
import { productsData, productTypes } from 'src/data/products';

import { ONBOARDING_OVERVIEW_ROUTE } from 'src/router/onboarding/routes';

import withLoading from 'src/components/hoc/withLoading';
import CenterContent from 'src/components/displays/CenterContent';
import OsmoButton from 'src/components/buttons/OsmoButton';
import Lottie from 'src/components/animations/Lottie';

import OsmoByjusLogo from 'src/assets/images/onboarding/logo-osmo-byjus.svg';

import getAnimationDataForProduct from './animation/lottie-data.js';

import './style.scss';

const animSettings = {
  loop: false,
  autoplay: false,
};

const Home = (props) => {
  const { setLoadingOn, setLoadingOff } = props;
  const [playTitleAnimation, setPlayTitleAnimation] = useState(false);
  const [playBottomAnimation, setPlayBottomAnimation] = useState(false);
  const [animationStartsPlaying, setAnimationStartsPlaying] = useState(false);
  const dispatch = useDispatch();

  /* * * Navigation * * */

  const { pathname, search } = useLocation();
  const { product: productFromParams } = useParams();
  const history = useHistory();

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  // Product
  const productName = pathsMapping[productFromParams]?.name;
  const product = productsData[productName];
  const productType = productsData[productName]?.type;
  const platform = pathname.includes('fire') ? 'fire' : 'ipad';

  // Redeem code
  const redeemCode = queryString.parse(search)?.code;

  // Animation
  const animationData = useMemo(
    () => productName && getAnimationDataForProduct(productName),
    [productName],
  );

  // ===========================================================================
  // ================================ HOOKS ====================================
  // ===========================================================================

  useEffect(() => {
    dispatch({
      type: actions.onboarding.SET_REDEEM_ERROR,
      payload: {
        redeemError: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    setLoadingOff();

    if (!!product) {
      setLoadingOn();
    }
  }, [setLoadingOn, setLoadingOff, product]);

  useEffect(() => {
    if (redeemCode) {
      // Store the redeem code for later
      dispatch({
        type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
        payload: {
          tmpAccountInformation: {
            redeemCode,
          },
        },
      });
    } else {
      // Reset redeem code
      dispatch({
        type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
        payload: {
          tmpAccountInformation: {
            redeemCode: null,
          },
        },
      });
    }
  }, [redeemCode, dispatch]);

  useEffect(() => {
    // Initialize product in the Redux store
    dispatch({
      type: actions.onboarding.SET_PRODUCT,
      payload: {
        product: {
          ...product,
          platform,
          type: productType,
        },
      },
    });
  }, [product, productType, platform, dispatch]);

  useEffect(() => {
    if (!animationStartsPlaying) {
      return;
    }

    setLoadingOff();

    // Initialize animations
    setTimeout(
      () => {
        setPlayTitleAnimation(true);
      },
      isOldFireDevice() ? 0 : 1900,
    );

    setTimeout(
      () => {
        setPlayBottomAnimation(true);
      },
      isOldFireDevice() ? 500 : 2400,
    );
  }, [animationStartsPlaying, setLoadingOff]);

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  const _onClick = () => {
    history.push(ONBOARDING_OVERVIEW_ROUTE);
  };

  // If no products found, show error screen
  if (!product) {
    return (
      <div>
        <CenterContent className="onboarding-home__center__content">
          <p className="onboarding-home__content__product-name">
            Oops, looks like you are lost!
          </p>

          <OsmoButton
            text={'Go to playosmo.com'}
            onClick={() => (window.location = 'https://www.playosmo.com')}
          />
        </CenterContent>
      </div>
    );
  }

  return (
    <div className="onboarding-home">
      <CenterContent className="onboarding-home__center__content">
        <div
          className={classNames('onboarding-home__content', {
            'onboarding-home__content--displayed': playTitleAnimation,
          })}
        >
          <img
            src={OsmoByjusLogo}
            alt=""
            className="onboarding-home__content__logo"
          />
          <p className="onboarding-home__content__product-name">
            {productType === productTypes.KIT
              ? translate('onboarding.home.titleWithPlatform', {
                  productName: product?.header?.name,
                  platform: platform === 'ipad' ? 'iPad' : 'Amazon Fire',
                })
              : translate('onboarding.home.title', {
                  productName: product?.header?.name,
                })}
          </p>
          <OsmoButton
            text={translate('onboarding.home.cta')}
            onClick={_onClick}
          />
        </div>

        <div className="onboarding-home__render">
          <Lottie
            animationData={animationData}
            settings={animSettings}
            onPlay={() => setAnimationStartsPlaying(true)}
          />
        </div>

        <div
          className={classNames('onboarding-home__resources', {
            'onboarding-home__resources--displayed': playBottomAnimation,
          })}
        >
          <div className="onboarding-home__resources__render">
            {product?.onboardingAssets?.packageRender && (
              <img src={product.onboardingAssets.packageRender} alt="" />
            )}
          </div>
          <div className="onboarding-home__resources__content">
            <div className="onboarding-home__resources__content__title">
              {translate('onboarding.home.resources')}
            </div>
            <div className="onboarding-home__resources__content__links">
              <a
                href="https://playosmo.com/devices"
                target="_blank"
                rel="noopener noreferrer"
                className="onboarding-home__resources__content__link"
              >
                {translate('onboarding.home.devices')}
              </a>
              <a
                href="https://www.playosmo.com/en/gamemanuals/"
                target="_blank"
                rel="noopener noreferrer"
                className="onboarding-home__resources__content__link"
              >
                {translate('onboarding.home.manuals')}
              </a>
              <a
                href="https://support.playosmo.com/hc/en-us?referrer=onboarding-v3"
                target="_blank"
                rel="noopener noreferrer"
                className="onboarding-home__resources__content__link"
              >
                {translate('onboarding.home.support')}
              </a>
            </div>
          </div>
        </div>
      </CenterContent>
    </div>
  );
};

export default withLoading(Home);
