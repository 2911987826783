import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

/**
 *  This component is used to wrap children
 *  and center it within the page.
 */

const Container = React.memo((props) => {
  const { children } = props;

  if (!children) {
    return null;
  }

  return <div className="container">{children}</div>;
});

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
