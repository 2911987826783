const prefix = 'REDEEM_STATE_ACTIONS_';

// ===========================================================================
// ================================= GLOBAL ==================================
// ===========================================================================

export const SET_REDEEM_CODE = `${prefix}SET_REDEEM_CODE`;
export const SET_REDEEM_CODE_DEVICE = `${prefix}SET_REDEEM_CODE_DEVICE`;
export const SET_EMAIL = `${prefix}SET_EMAIL`;
export const SET_EMAIL_VERIFICATION_ID = `${prefix}SET_EMAIL_VERIFICATION_ID`;
export const SET_TMP_ACCOUNT_INFORMATION = `${prefix}SET_TMP_ACCOUNT_INFORMATION`;
export const CLEAR_EMAIL = `${prefix}CLEAR_EMAIL`;
export const SET_USER = `${prefix}SET_USER`;
export const SET_INITIAL_STATE = `${prefix}SET_INITIAL_STATE`;

// Sagas
export const SEND_REDEEM_CODE_TO_SERVER_SAGA = `${prefix}SEND_REDEEM_CODE_TO_SERVER`;
export const SEND_EMAIL_TO_SERVER_SAGA = `${prefix}SEND_EMAIL_TO_SERVER`;
export const SEND_VERIFICATION_CODE_TO_SERVER_SAGA = `${prefix}SEND_VERIFICATION_CODE_TO_SERVER_SAGA`;
export const CREATE_ACCOUNT_SAGA = `${prefix}CREATE_ACCOUNT_SAGA`;
export const UPDATE_ACQUISITION_SOURCES_SAGA = `${prefix}UPDATE_ACQUISITION_SOURCES_SAGA`;
export const UPDATE_SCHOOL_USAGES_SAGA = `${prefix}UPDATE_SCHOOL_USAGES_SAGA`;
