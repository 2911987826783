import { useRef, useState } from 'react';

// You need to use this custom hook if your state is not synchronized
// because you call a callback in a settimeout or an event listener
// More context: https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
export default function useSetStateRef(defaultValue) {
  const [state, _setState] = useState(defaultValue);

  const stateRef = useRef(state);
  const setState = (data) => {
    stateRef.current = data;
    _setState(data);
  };

  return [stateRef, setState];
}
