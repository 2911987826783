import React, { useState } from 'react';
import { translate } from 'src/i18n';

import { validateEmail } from 'src/utils/validations';

import withLoading from 'src/components/hoc/withLoading';
import OsmoInput from 'src/components/inputs/OsmoInput';
import OsmoButton from 'src/components/buttons/OsmoButton';
import ErrorMessage from 'src/components/messages/ErrorMessage';
import SelectInput from 'src/components/inputs/SelectInput';
import CountrySelector from 'src/components/inputs/CountrySelector';

import './style.scss';

const AccountInformationForm = (props) => {
  const {
    isLoading,
    onSubmit,
    initialEmail = '',
    withEmailInput = true,
    withCountry = true,
  } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(initialEmail);
  const [place, setPlace] = useState(null);
  const [country, setCountry] = useState('US');
  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================ ERRORS =====================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // Check if all fields are filled, if not display errors
  function _checkIfFieldsAreFilled() {
    let newErrors = {};

    if (!firstName) {
      newErrors.firstName = {
        message: translate('general.firstNameRequiredError'),
      };
    }

    if (!lastName) {
      newErrors.lastName = {
        message: translate('general.lastNameRequiredError'),
      };
    }

    if (withEmailInput) {
      if (!email) {
        newErrors.email = { message: translate('general.emailRequiredError') };
      }

      // Set email is not valid
      if (!validateEmail(email)) {
        newErrors.email = { message: translate('general.invalidEmailError') };
      }
    }

    if (withCountry && !country) {
      newErrors.country = { message: translate('general.optionRequiredError') };
    }

    if (!place) {
      newErrors.terms = {
        message: translate('general.optionRequiredError'),
      };
    }

    setErrors({ ...newErrors });
    return newErrors;
  }

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _handleFirstNameChange(e) {
    // If error state, on first name change remove it.
    setErrors({ ...errors, firstName: null });

    setFirstName(e.target.value);
  }

  function _handleLastNameChange(e) {
    // If error state, on last name change remove it.
    setErrors({ ...errors, lastName: null });

    setLastName(e.target.value);
  }

  function _handleCountryChange(e) {
    // If error state, on last name change remove it.
    setErrors({ ...errors, country: null });

    setCountry(e.value);
  }

  function _handleEmailChange(e) {
    // If error state, on last name change remove it.
    setErrors({ ...errors, email: null });

    setEmail(e.target.value);
  }

  function _handlePlaceChange(value) {
    // If error state, on email change remove it.
    setErrors({ ...errors, place: null });

    setPlace(value);
  }

  function _onKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      _onSubmit();
    }
  }

  function _onSubmit() {
    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Remove all errors to update to new ones
    _resetErrors();

    // Display required errors
    const errorsBeforeSubmit = _checkIfFieldsAreFilled();

    console.log(
      { errors, errorsBeforeSubmit },
      withCountry && !!errors.country,
    );

    // If one field is empty return function
    if (
      !firstName ||
      !firstName.length ||
      !lastName ||
      !lastName.length ||
      !place ||
      (withEmailInput && !!errorsBeforeSubmit.email)
    ) {
      return;
    }

    onSubmit({ firstName, lastName, place, email, country });
  }

  // ===========================================================================
  // ====================== COMPUTED VARIABLES =================================
  // ===========================================================================

  const submitButtonDisabled =
    !firstName ||
    !firstName.length ||
    !lastName ||
    !lastName.length ||
    !place ||
    (withEmailInput && (!email || !email.length));

  return (
    <div className="account-information-form">
      <div className="account-information-form__row">
        <div className="account-information-form__col">
          <OsmoInput
            className="account-information-form__input"
            placeHolder="First name"
            onChange={_handleFirstNameChange}
            value={firstName}
            error={errors.firstName}
            onKeyDown={_onKeyDown}
          />
        </div>
        <div className="account-information-form__col">
          <OsmoInput
            className="account-information-form__input"
            placeHolder="Last name"
            onChange={_handleLastNameChange}
            value={lastName}
            error={errors.lastName}
            onKeyDown={_onKeyDown}
          />
        </div>
      </div>
      {withEmailInput && (
        <div className="account-information-form__row">
          <div className="account-information-form__col">
            <OsmoInput
              placeHolder="Email"
              onChange={_handleEmailChange}
              value={email}
              error={errors.email}
              onKeyDown={_onKeyDown}
              type="email"
            />
          </div>
        </div>
      )}

      <div className="account-information-form__row">
        <SelectInput
          onChange={_handlePlaceChange}
          label={translate('accountForm.usage')}
          placeholder={translate('accountForm.usage')}
          value={place}
          error={errors.place}
          options={[
            {
              value: 'home',
              label: translate('accountForm.usageKids'),
            },
            {
              value: 'classroom',
              label: translate('accountForm.usageStudents'),
            },
          ]}
        />
      </div>

      {withCountry && (
        <div className="account-information-form__row">
          <div className="account-information-form__col">
            <CountrySelector
              id="country"
              name="country"
              label={translate('accountForm.country')}
              onChange={_handleCountryChange}
              value={country}
              error={errors.country}
            />
          </div>
        </div>
      )}

      {errors?.status && (
        <div className="account-information-form__message">
          <ErrorMessage message={errors?.status?.message} />
        </div>
      )}

      <div className="account-information-form__button">
        <OsmoButton
          text={translate('general.next')}
          onClick={_onSubmit}
          isDisabled={submitButtonDisabled}
        />
      </div>
    </div>
  );
};

export default withLoading(AccountInformationForm);
