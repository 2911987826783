import React from 'react';

import SiteFooter from 'src/components/footers/SiteFooter';
import Container from 'src/components/displays/Container';

import OsmoLogoSrc from 'src/assets/images/icons/osmo-logo-animated.gif';
import FireBaseIlluSrc from 'src/assets/images/illustrations/illustration-fire-base.svg';
import IPadBaseIlluSrc from 'src/assets/images/illustrations/illustration-ipad-base.svg';

import './style.scss';

const VisitPageWithSupportedDevice = () => {
  return (
    <div className="app-container">
      <Container>
        <div className="visit-page-with-supported-device">
          <img
            src={OsmoLogoSrc}
            className="visit-page-with-supported-device__osmo-logo"
            alt="Osmo Logo"
          />

          <p className="visit-page-with-supported-device__text">
            {'Hey Osmonaut!'}
            <br />
            {'Visit this page on your iPad or Fire Tablet :)'}
          </p>

          <div className="visit-page-with-supported-device__devices">
            <img
              className="visit-page-with-supported-device__device"
              src={IPadBaseIlluSrc}
              alt="iPad Base"
            />
            <img
              className="visit-page-with-supported-device__device"
              src={FireBaseIlluSrc}
              alt="Fire Base"
            />
          </div>

          <a
            className="visit-page-with-supported-device__link"
            href="https://www.playosmo.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {'or learn more about Osmo'}
          </a>
        </div>
      </Container>

      <SiteFooter />
    </div>
  );
};

export default VisitPageWithSupportedDevice;
