export const REDEEM_PATH = '/redeem';

export const REDEEM_CODE_ROUTE = `${REDEEM_PATH}/`;
export const ENTER_EMAIL_ROUTE = `${REDEEM_PATH}/email`;
export const VERIFY_EMAIL_ROUTE = `${REDEEM_PATH}/verify-email`;
export const ACCOUNT_INFORMATION_ROUTE = `${REDEEM_PATH}/complete-account`;
export const REFERRAL_TEACHER_SURVEY_ROUTE = `${REDEEM_PATH}/referral/teacher`;
export const SELECT_DEVICE_ROUTE = `${REDEEM_PATH}/select-device`;
export const CONTINUE_SETUP_ROUTE = `${REDEEM_PATH}/continue-setup`;
export const CONSENT_ROUTE = `${REDEEM_PATH}/consent`;
