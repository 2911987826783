import React from 'react';

import Container from 'src/components/displays/Container';
import SiteFooter from 'src/components/footers/SiteFooter';

import OpaIllustrationSrc from 'src/assets/images/illustrations/illustration-opa@2x.png';
import ThumbSrc from 'src/assets/images/osmojis/thumb@2x.png';
import TrophySrc from 'src/assets/images/osmojis/trophy@2x.png';
import StarSrc from 'src/assets/images/osmojis/star@2x.png';
import AppStoreBadgeSrc from 'src/assets/images/icons/app-store-badge@2x.png';
import GooglePlayBadgeSrc from 'src/assets/images/icons/google-play-badge@2x.png';

import './style.scss';

const appPerks = [
  {
    id: 'engage',
    title: 'Engage with your child',
    text: 'View what your child has created and send a reaction.',
    image: {
      src: ThumbSrc,
      alt: 'Thumb Osmoji',
    },
  },
  {
    id: 'activity',
    title: 'View your child’s activity',
    text: 'Track your child’s progress in Osmo games.',
    image: {
      src: TrophySrc,
      alt: 'Trophy Osmoji',
    },
  },
  {
    id: 'unlock',
    title: 'Unlock the magic of Osmo',
    text: 'Learn tips and tricks for getting the most out of your Osmo.',
    image: {
      src: StarSrc,
      alt: 'Star Osmoji',
    },
  },
];

const GetParentApp = () => {
  return (
    <div className="app-container">
      <div className="get-opa-page">
        <Container>
          <div className="get-opa-page__container">
            <img
              className="get-opa-page__app-illustration"
              src={OpaIllustrationSrc}
              alt="Osmo Parent app illustration"
            />
            <div className="get-opa-page__content">
              <div className="get-opa-page__subtitle">
                {'Setup instructions for'}
              </div>
              <div className="title-h1">{'The Osmo Parent App'}</div>
              <div className="get-opa-page__perks">
                {!!appPerks?.length &&
                  appPerks.map((perk) => (
                    <div key={perk.id} className="get-opa-page__perk">
                      {!!perk.image?.src && (
                        <img
                          className="get-opa-page__osmoji"
                          src={perk.image.src}
                          alt={perk.image.alt}
                        />
                      )}
                      <div className="get-opa-page__perk-texts">
                        {!!perk.title && (
                          <div className="get-opa-page__perk-title">
                            {perk.title}
                          </div>
                        )}
                        {!!perk.text && <p>{perk.text}</p>}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="get-opa-page__stores">
                <a
                  href="https://itunes.apple.com/app/id1348402278"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="get-opa-page__app-store-badge"
                    src={AppStoreBadgeSrc}
                    alt="App Store icon"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.tangibleplay.osmo.parent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="get-opa-page__google-play-badge"
                    src={GooglePlayBadgeSrc}
                    alt="Google Play icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <SiteFooter />
    </div>
  );
};

export default GetParentApp;
