import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as selectors from 'src/selectors';
import * as actions from 'src/actions';
import { translate } from 'src/i18n';

import { ONBOARDING_WELCOME_ROUTE } from 'src/router/onboarding/routes';

import VerificationCodeForm from 'src/modules/VerificationCodeForm';
import withLoading from 'src/components/hoc/withLoading';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

const VerifyEmail = (props) => {
  const {
    setLoadingOn,
    setLoadingOff,
    // From Redux
    tmpAccountInformation,
    dispatch,
  } = props;
  const history = useHistory();
  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onComplete({ verificationCode }) {
    // Dispatch loading on
    setLoadingOn();

    // Dispatch request
    dispatch({
      type: actions.onboarding.SEND_VERIFICATION_CODE_TO_SERVER_SAGA,
      payload: {
        params: {
          email: tmpAccountInformation.email,
          verificationCode: verificationCode.current,
        },
        callback: () => {
          setLoadingOff();
          history.push(ONBOARDING_WELCOME_ROUTE);
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            code: {
              message,
            },
          });
        },
        errorRedeemCallback: (message) => {
          setLoadingOff();
          history.push(ONBOARDING_WELCOME_ROUTE);
        },
      },
    });
  }

  return (
    <div className="onboarding-verify-email">
      <Container>
        <CenterContent>
          <MainHeader title={translate('onboarding.verifyEmail.title')} />
          <VerificationCodeForm
            onComplete={_onComplete}
            email={tmpAccountInformation.email}
            errors={errors}
          />
        </CenterContent>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tmpAccountInformation: selectors.onboarding.getTmpAccountInformationSelector(
    state,
  ),
});

export default connect(mapStateToProps)(withLoading(VerifyEmail));
