import React from 'react';

import './styles.scss';

const Loading = React.memo(({ scale }) => {
  return (
    <div
      className="loader"
      style={{
        transform: !!scale && `scale(${scale})`,
      }}
    ></div>
  );
});

export default Loading;
