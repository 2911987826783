import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import GameSet from 'src/components/games/GameSet';
import GameVersions from 'src/components/games/GameVersions';
import CarrouselText from '../CarrouselText';
import CarrouselImages from '../CarrouselImages';

import { carrouselComponents } from 'src/data/carrousel';

import './style.scss';

const CarrouselComponent = (props) => {
  const { component, gamesIncluded, gameVersions } = props;

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  function _getComponent() {
    const { type } = component;

    switch (type) {
      case carrouselComponents.TEXT: {
        return <CarrouselText component={component} />;
      }

      case carrouselComponents.IMAGES: {
        return <CarrouselImages games={gamesIncluded} component={component} />;
      }

      case carrouselComponents.GAMES: {
        return <GameSet games={gamesIncluded} isInline={true} isLarge={true} />;
      }

      case carrouselComponents.GAMES_VERSIONS: {
        return <GameVersions versions={gameVersions} />;
      }

      default: {
        return null;
      }
    }
  }

  const carrouselComponentClassNames = classNames(
    'carrousel-component',
    component.type,
  );

  return <div className={carrouselComponentClassNames}>{_getComponent()}</div>;
};

CarrouselComponent.propTypes = {
  component: PropTypes.object.isRequired,
  gamesIncluded: PropTypes.arrayOf(PropTypes.string),
};

export default CarrouselComponent;
