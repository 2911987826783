import { getEmailStatus as getEmailStatusAPI } from 'src/apis/login';

const getEmailStatus = ({
  email,
  existCallback,
  notFoundCallback,
  errorCallback,
}) => {
  return getEmailStatusAPI({ email })
    .then((response) => {
      if (response?.code > 308) {
        errorCallback(response);
        return;
      }
      if (response.valid_account) {
        existCallback();
      } else {
        notFoundCallback();
      }
    })
    .catch((err) => {
      errorCallback(err);
    });
};

export default getEmailStatus;
