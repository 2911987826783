import React from 'react';
import { Route } from 'react-router-dom';

import GetApp from 'src/pages/parent/GetApp';

import * as routes from './routes';

const ParentRoutes = () => [
  <Route key={routes.GET_APP_ROUTE} path={routes.GET_APP_ROUTE}>
    <GetApp />
  </Route>,
];

export default ParentRoutes;
