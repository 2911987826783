import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ErrorMessage from 'src/components/messages/ErrorMessage';

import './style.scss';

/**
 *  Custom Input
 */

const Checkbox = (props) => {
  const { id, className, label, checked, onChange, error } = props;

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  const checkboxClassNames = classNames('osmo-checkbox', className);

  return (
    <div className={checkboxClassNames}>
      <div className="osmo-checkbox__inner">
        <input
          type="checkbox"
          id={id}
          className="osmo-checkbox__input"
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={id} className="osmo-checkbox__label">
          {label}
        </label>
      </div>
      {error && (
        <div className="osmo-checkbox__message">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.object,
};

export default Checkbox;
