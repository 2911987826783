import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SlideDown from 'src/components/general/SlideDown';
import CarrouselComponent from '../CarrouselComponent';

import { fireInstructionsData } from 'src/data/instructions/fire';

import './style.scss';

const FireInstructionsCarrousel = (props) => {
  const { gamesIncluded, gameVersions } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [slidedDownIndex, setSlidedDownIndex] = useState(-1);

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleSlideDownClick(index) {
    const updatedIndex = index === slidedDownIndex ? -1 : index;
    setSlidedDownIndex(updatedIndex);
  }

  // ===========================================================================
  // ============================== LABEL ======================================
  // ===========================================================================

  function _getLabel(label, index) {
    return (
      <>
        <span className="fire-instructions-carrousel__index">{`${
          index + 1
        }.`}</span>
        <span className="fire-instructions-carrousel__label">{label}</span>
      </>
    );
  }

  // ===========================================================================
  // ======================= COMPUTED VARIABLES ================================
  // ===========================================================================

  const data = fireInstructionsData(gamesIncluded, gameVersions);

  if (!data.length) {
    return null;
  }

  return (
    <>
      {data.map((instruction, index) => (
        <SlideDown
          key={index}
          label={_getLabel(instruction.label, index)}
          onClick={() => _handleSlideDownClick(index)}
          active={slidedDownIndex === index}
        >
          {!!instruction.content?.length &&
            instruction.content.map((component, index) => (
              <CarrouselComponent
                component={component}
                key={index}
                gamesIncluded={gamesIncluded}
                gameVersions={gameVersions}
              />
            ))}
        </SlideDown>
      ))}
    </>
  );
};

FireInstructionsCarrousel.propTypes = {
  gamesIncluded: PropTypes.arrayOf(PropTypes.string),
};

export default FireInstructionsCarrousel;
