import { redeemedGameDevices } from './devices';

import FireLogoSrc from 'src/assets/images/icons/fire-logo.svg';
import FireBaseIlluSrc from 'src/assets/images/illustrations/illustration-fire-base.svg';
import IPadLogoSrc from 'src/assets/images/icons/ipad-logo.svg';
import IPadBaseIlluSrc from 'src/assets/images/illustrations/illustration-ipad-base.svg';

export const devicePickerData = {
  [redeemedGameDevices.FIRE]: {
    illustration: {
      src: FireBaseIlluSrc,
      alt: 'Fire Osmo base',
    },
    logo: {
      src: FireLogoSrc,
      alt: 'Fire logo',
    },
  },
  [redeemedGameDevices.IPAD]: {
    illustration: {
      src: IPadBaseIlluSrc,
      alt: 'iPad Osmo base',
    },
    logo: {
      src: IPadLogoSrc,
      alt: 'iPad logo',
    },
  },
};
