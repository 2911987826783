import React from 'react';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';

import { ONBOARDING_CONSENT_ROUTE } from 'src/router/onboarding/routes';

import withLoading from 'src/components/hoc/withLoading';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';
import TeacherReferralForm from 'src/modules/TeacherReferralForm';
import { translate } from 'src/i18n';

const TeacherSurvey = (props) => {
  const { dispatch, setLoadingOn, setLoadingOff } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onSubmit({ schoolUsages }) {
    // Set loading on
    setLoadingOn();

    dispatch({
      type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          schoolUsages,
        },
      },
    });

    history.push(ONBOARDING_CONSENT_ROUTE);

    setLoadingOff();
  }

  return (
    <div className="onboarding-teacher-survey">
      <Container>
        <CenterContent>
          <MainHeader title={translate('onboarding.teacherSurvey.title')} />

          <TeacherReferralForm onSubmit={_onSubmit} />
        </CenterContent>
      </Container>
    </div>
  );
};

export default withLoading(TeacherSurvey);
