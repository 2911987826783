import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import GameSet from 'src/components/games/GameSet';

import './style.scss';

const CarrouselImages = (props) => {
  const { component, games } = props;

  const { images, noBorder } = component;

  if (!images?.length) {
    return null;
  }

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  const hasASingleImage = images.length === 1;

  const carrouselImagesClassNames = classNames('carrousel-images', {
    'carrousel-images--single-image': hasASingleImage,
    'carrousel-images--no-border': noBorder,
  });

  return (
    <div className={carrouselImagesClassNames}>
      {images.map((item, index) => (
        <div
          className={classNames('carrousel-images__image-holder', {
            'carrousel-images__image-holder--reverse-mobile': !!item.labels
              .title,
          })}
          key={index}
        >
          {item.labels && (
            <div className="carrousel-images__labels">
              {item.labels.title && (
                <div className="carrousel-images__title">
                  {item.labels.title}
                </div>
              )}
              {item.labels.text && (
                <p
                  className="carrousel-images__text"
                  dangerouslySetInnerHTML={item.labels.text}
                />
              )}
              {item.labels.games && (
                <div className="carrousel-images__game-set">
                  <GameSet games={games} isInline={true} column={true} />
                </div>
              )}
            </div>
          )}
          <img
            className="carrousel-images__image"
            src={item.image.src}
            alt={item.image.alt}
          />
          {item.labels && item.labels.title && (
            <div className="carrousel-images__title carrousel-images__title--small-devices">
              {item.labels.title}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

CarrouselImages.propTypes = {
  component: PropTypes.object.isRequired,
};

export default CarrouselImages;
