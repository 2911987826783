import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';

import withLoading from 'src/components/hoc/withLoading';

import TeacherReferralForm from 'src/modules/TeacherReferralForm';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

import { CONSENT_ROUTE } from 'src/router/redeem/routes';

import './style.scss';

const TeacherReferralSurvey = (props) => {
  const { dispatch, setLoadingOn, setLoadingOff } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onSubmit({ schoolUsages }) {
    // Set loading on
    setLoadingOn();

    dispatch({
      type: actions.redeem.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          schoolUsages,
        },
      },
    });

    history.push(CONSENT_ROUTE);

    setLoadingOff();
  }

  return (
    <div className="teacher-referral-survey-page">
      <Container>
        <CenterContent>
          <MainHeader title="How are you using Osmo in your classroom?" />

          <TeacherReferralForm onSubmit={_onSubmit} />
        </CenterContent>
      </Container>
    </div>
  );
};

export default connect()(withLoading(TeacherReferralSurvey));
