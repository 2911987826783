import React from 'react';
import { useHistory } from 'react-router-dom';

import MainHeader from 'src/components/headers/MainHeader';
import OsmoButton from 'src/components/buttons/OsmoButton';

import { getOnboardingFireRoute } from 'src/router/onboarding/routes';

import OsmoFireIlluSrc from 'src/assets/images/illustrations/illustration-fire-with-characters.svg';

import './style.scss';

const FireContinuationComponent = (props) => {
  const { product, apps } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onClick() {
    const productId = product || apps[0];

    if (!productId) {
      return;
    }

    const nextPath = getOnboardingFireRoute(productId);

    history.push(nextPath);
  }

  return (
    <div className="fire-continuation-component">
      <MainHeader
        title={`Your ${apps.length > 1 ? 'games' : 'game'} have been unlocked!`}
      />
      <div className="fire-continuation-component__container">
        <img
          className="fire-continuation-component__reflector-illustration"
          src={OsmoFireIlluSrc}
          alt="Osmo with Fire tablet"
        />
        <div className="fire-continuation-component__content">
          <p className="fire-continuation-component__label">
            {
              'Continue on with the setup instructions provided with your product.'
            }
          </p>
          <div className="fire-continuation-component__button">
            <OsmoButton text="Continue Setup" onClick={_onClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FireContinuationComponent;
