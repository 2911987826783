import {
  PRODUCT_SELECTION_ROUTE,
  PRODUCT_INSTRUCTIONS_ROUTE,
} from 'src/router/redeemFaq/routes';

import { productsData } from './products';

// Return an array of navigation elements used for Breadcrumb
export const getNavigationElements = (product) => {
  const navigationElements = [
    {
      id: 'redemption-codes',
      label: 'Redemption Codes',
      path: PRODUCT_SELECTION_ROUTE,
    },
  ];

  if (product && productsData[product]) {
    navigationElements.push({
      id: product,
      label: productsData[product].title,
      path: PRODUCT_INSTRUCTIONS_ROUTE,
    });
  }

  return navigationElements;
};
