import React, { useEffect, useState } from 'react';

import RotateDevice from 'src/components/general/RotateDevice';

const WithRotateDevice = (WrappedComponent) => {
  const Component = () => {
    const [orientation, setOrientation] = useState(null);
    const [currentWindowWidth, setCurrentWindowWidth] = useState(
      window.innerWidth,
    );

    // There's an exception for this HOC in the onboarding router (the Fire tablet onboarding pages)
    const ignoreOrientation = window.location.pathname.includes(
      'onboarding/fire/',
    );

    useEffect(() => {
      const listenToResize = () => {
        setCurrentWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', listenToResize);

      return () => window.addEventListener('resize', listenToResize);
    }, []);

    useEffect(() => {
      if (window.matchMedia('(orientation: landscape)').matches) {
        setOrientation('landscape');
      } else {
        setOrientation('portrait');
      }
    }, [currentWindowWidth]);

    // If the tablet is in landscape mode, we ask to switch to portrait
    if ((orientation === 'landscape') & !ignoreOrientation) {
      return <RotateDevice />;
    }

    return <WrappedComponent />;
  };

  return Component;
};

export default WithRotateDevice;
