import React from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import './style.scss';

/**
 *  This is used to display text with main title style
 */

const Title = React.memo((props) => {
  const { children, text, isDark, className } = props;

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  const titleH1ClassNames = classNames('title-h1', className, {
    'title-h1--dark': isDark,
  });

  if (!children && !text) {
    return null;
  }

  return <div className={titleH1ClassNames}>{children || text}</div>;
});

Title.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  isDark: PropTypes.bool,
  className: PropTypes.string,
};

export default Title;
