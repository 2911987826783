import React from 'react';
import PropTypes from 'prop-types';

import GameSet from 'src/components/games/GameSet';

import './style.scss';

/**
 *  Game versions that displays one section for each version
 */

const GameVersions = React.memo((props) => {
  const { versions } = props;

  if (!versions || versions.length < 1) {
    return null;
  }

  return (
    <div className="game-versions">
      {versions.map((version) => (
        <div key={version.label} className="game-version">
          {!!version.label && (
            <div className="game-version__label">{version.label}</div>
          )}
          <GameSet games={version.games} isInline={true} column={true} />
        </div>
      ))}
    </div>
  );
});

GameVersions.propTypes = {
  versions: PropTypes.object.isRequired,
};

export default GameVersions;
