import { games } from 'src/data/games';

import GeniusSKImage from 'src/assets/images/kits/genius-starter-kit-fire.jpg';
import GeniusSKPlusImage from 'src/assets/images/kits/genius-starter-kit-plus-fire.png';
import LittleGeniusSKImage from 'src/assets/images/kits/little-genius-starter-kit-fire.png';
import LittleGeniusSKPlusImage from 'src/assets/images/kits/little-genius-starter-kit-plus-fire.png';
import CodingSKImage from 'src/assets/images/kits/coding-starter-kit-fire.png';
import CreativeSKImage from 'src/assets/images/kits/creative-starter-kit-fire.png';
import FamilyGameNightBundleImage from 'src/assets/images/bundles/family_game_night_bundle.png';
import EarlyMathAdventureBundle from 'src/assets/images/bundles/early_math_adventure_bundle.png';
import MathChampionBundle from 'src/assets/images/bundles/math_champion_bundle.png';
import EssentialMathBundle from 'src/assets/images/bundles/essential_math_bundle.png';
import LiteracyBundle from 'src/assets/images/bundles/osmo_literacy_bundle.jpg';
import UltimateExpansionBundle from 'src/assets/images/bundles/ultimate_expansion_bundle.jpg';

import MathWizardProductImage from 'src/assets/images/games/math-wizard/math-wizard-product-image.png';

import WordsExplorersIcon from 'src/assets/images/games/products/words-explorers.png';
import WordsChompIcon from 'src/assets/images/games/products/words-chomp.png';
import CookingChaosIcon from 'src/assets/images/games/products/cooking-chaos.png';

/** Onboarding assets */
import CodingSKPackageRender from 'src/assets/images/onboarding/home/package-renders/coding-sk-ipad.png';
import GeniusSKPackageRender from 'src/assets/images/onboarding/home/package-renders/genius-sk-ipad.png';
import GeniusSKPlusPackageRender from 'src/assets/images/onboarding/home/package-renders/genius-sk-plus-ipad.png';
import CreativeSKPackageRender from 'src/assets/images/onboarding/home/package-renders/creative-sk-ipad.png';
import LittleGeniusSKPackageRender from 'src/assets/images/onboarding/home/package-renders/little-genius-sk-ipad.png';
import LittleGeniusSKPlusPackageRender from 'src/assets/images/onboarding/home/package-renders/little-genius-sk-plus-ipad.png';
import WordsSKPackageRender from 'src/assets/images/onboarding/home/package-renders/words-sk-ipad.png';
// import WordsPackageRender from 'src/assets/images/onboarding/home/package-renders/words.png';
import NumbersSKPackageRender from 'src/assets/images/onboarding/home/package-renders/numbers-sk-ipad.png';
// import NumbersPackageRender from 'src/assets/images/onboarding/home/package-renders/numbers.png';
import DetectivePackageRender from 'src/assets/images/onboarding/home/package-renders/detective.png';
import DetectiveSKPackageRender from 'src/assets/images/onboarding/home/package-renders/detective-sk-ipad.png';
import PizzaPackageRender from 'src/assets/images/onboarding/home/package-renders/pizza.png';
import PizzaSKPackageRender from 'src/assets/images/onboarding/home/package-renders/pizza-sk-ipad.png';
import SSFrozenPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-frozen.png';
// import SSFrozenSKPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-frozen-sk-ipad.png';
import SSMickeyPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-mickey.png';
import SSMickeySKPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-mickey-sk-ipad.png';
import SSPrincessPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-princess.png';
import SSPrincessSKPackageRender from 'src/assets/images/onboarding/home/package-renders/superstudio-princess-sk-ipad.png';
// import SticksPackageRender from 'src/assets/images/onboarding/home/package-renders/sticks-rings.png';
import SticksSKPackageRender from 'src/assets/images/onboarding/home/package-renders/sticks-rings-sk-ipad.png';

export const products = {
  GENIUS_SK: 'genius-sk',
  GENIUS_SK_PLUS: 'genius-sk-plus',
  LITTLE_GENIUS_SK: 'little-genius-sk',
  LITTLE_GENIUS_SK_PLUS: 'little-genius-sk-plus',
  CODING_SK: 'coding-sk',
  CREATIVE_SK: 'creative-sk',
  DETECTIVE_SK: 'detective-sk',
  DETECTIVE: 'detective',
  SUPERSTUDIO_PRINCESS_SK: 'superstudio-princess-sk',
  SUPERSTUDIO_PRINCESS: 'superstudio-princess',
  SUPERSTUDIO_FROZEN_SK: 'superstudio-frozen2-sk',
  SUPERSTUDIO_FROZEN: 'superstudio-frozen2',
  SUPERSTUDIO_MICKEY_SK: 'superstudio-mickey-sk',
  SUPERSTUDIO_MICKEY: 'superstudio-mickey',
  NUMBERS_SK: 'numbers-sk',
  NUMBERS: 'numbers',
  WORDS_SK: 'words-sk',
  WORDS: 'words',
  PIZZA_SK: 'pizza-sk',
  PIZZA: 'pizza',
  STICKS_RINGS_SK: 'sticks-and-rings-sk',
  FGN_BUNDLE: 'family-game-night-bundle',
  EMA_BUNDLE: 'early-math-adventure-bundle',
  ESSENTIAL_MATH_BUNDLE: 'essential-math-bundle',
  MATH_CHAMPION_BUNDLE: 'math-champion-bundle',
  LITERACY_BUNDLE: 'literacy-bundle',
  UE_BUNDLE: 'ultimate-expansion-bundle',
};

export const productTypes = {
  GAME: 'game',
  KIT: 'kit',
  BUNDLE: 'bundle',
};

export const productsData = {
  // Kits
  [products.GENIUS_SK]: {
    name: products.GENIUS_SK,
    type: productTypes.KIT,
    header: {
      image: {
        src: GeniusSKImage,
        alt: 'Genius Starter Kit',
      },
      name: 'Genius Starter Kit',
    },
    onboardingAssets: {
      packageRender: GeniusSKPackageRender,
    },
    gamesIncluded: [
      games.NUMBERS,
      games.WORDS,
      games.TANGRAM,
      games.MASTERPIECE,
      games.NEWTON,
    ],
    gameVersions: [
      {
        label: 'Osmo for Home',
        games: [games.NUMBERS, games.TANGRAM, games.WORDS],
      },
      {
        label: 'Osmo for Schools',
        games: [games.NUMBERS_EDU, games.TANGRAM_EDU, games.WORDS_EDU],
      },
    ],
    hasActivityTray: true,
  },
  [products.GENIUS_SK_PLUS]: {
    name: products.GENIUS_SK_PLUS,
    type: productTypes.KIT,
    header: {
      image: {
        src: GeniusSKPlusImage,
        alt: 'Genius Starter Kit + Family Game Night',
      },
      name: 'Genius Starter Kit + Family Game Night',
    },
    gamesIncluded: [
      games.NUMBERS,
      games.WORDS,
      games.TANGRAM,
      games.MASTERPIECE,
      games.NEWTON,
      games.MATHBUZZ,
      games.LETTERTOPIA,
    ],
    onboardingAssets: {
      packageRender: GeniusSKPlusPackageRender,
    },
    hasActivityTray: true,
  },
  [products.LITTLE_GENIUS_SK]: {
    name: products.LITTLE_GENIUS_SK,
    type: productTypes.KIT,
    header: {
      image: {
        src: LittleGeniusSKImage,
        alt: 'Little Genius Starter Kit',
      },
      name: 'Little Genius Starter Kit',
    },
    gamesIncluded: [
      games.ABCS,
      games.SQUIGGLE_MAGIC,
      games.COSTUME_PARTY,
      games.STORIES,
    ],
    onboardingAssets: {
      packageRender: LittleGeniusSKPackageRender,
    },
    hasActivityTray: true,
  },
  [products.LITTLE_GENIUS_SK_PLUS]: {
    name: products.LITTLE_GENIUS_SK_PLUS,
    type: productTypes.KIT,
    header: {
      image: {
        src: LittleGeniusSKPlusImage,
        alt: 'Little Genius Starter Kit + Early Math Adventure',
      },
      name: 'Little Genius Starter Kit + Early Math Adventure',
    },
    gamesIncluded: [
      games.ABCS,
      games.SQUIGGLE_MAGIC,
      games.COSTUME_PARTY,
      games.STORIES,
      games.COUNTING_COUNTY,
      games.SHAPES,
    ],
    onboardingAssets: {
      packageRender: LittleGeniusSKPlusPackageRender,
    },
    hasActivityTray: true,
  },
  [products.CODING_SK]: {
    name: products.CODING_SK,
    type: productTypes.KIT,
    header: {
      image: {
        src: CodingSKImage,
        alt: 'Coding Starter Kit',
      },
      name: 'Coding Starter Kit',
    },
    gamesIncluded: [games.CODING, games.CODING_DUO, games.CODING_JAM],
    hasActivityTray: true,
    onboardingAssets: {
      packageRender: CodingSKPackageRender,
    },
  },
  [products.CREATIVE_SK]: {
    name: products.CREATIVE_SK,
    type: productTypes.KIT,
    header: {
      image: {
        src: CreativeSKImage,
        alt: 'Creative Starter Kit',
      },
      name: 'Creative Starter Kit',
    },
    gamesIncluded: [games.MONSTER, games.MASTERPIECE, games.NEWTON],
    hasActivityTray: true,
    onboardingAssets: {
      packageRender: CreativeSKPackageRender,
    },
  },

  // Bundles
  [products.FGN_BUNDLE]: {
    name: products.FGN_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: FamilyGameNightBundleImage,
        alt: 'Family Game Night Bundle',
      },
      name: 'Family Game Night Bundle',
    },
    gamesIncluded: [games.LETTERTOPIA, games.MATHBUZZ],
  },
  [products.EMA_BUNDLE]: {
    name: products.EMA_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: EarlyMathAdventureBundle,
        alt: 'Early Math Adventure Bundle',
      },
      name: 'Early Math Adventure Bundle',
    },
    gamesIncluded: [games.SHAPES, games.COUNTING_COUNTY],
  },
  [products.ESSENTIAL_MATH_BUNDLE]: {
    name: products.ESSENTIAL_MATH_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: EssentialMathBundle,
        alt: 'Essential Math Bundle',
      },
      name: 'Essential Math Bundle',
    },
    gamesIncluded: [
      games.NUMBERS,
      games.COUNTING_COUNTY,
      games.SHAPES,
      games.TOYBOX,
    ],
  },
  [products.MATH_CHAMPION_BUNDLE]: {
    name: products.MATH_CHAMPION_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: MathChampionBundle,
        alt: 'Math Champion Bundle',
      },
      name: 'Math Champion Bundle',
    },
    gamesIncluded: [games.PIZZACO, games.MATHBUZZ, games.TOYBOX],
  },
  [products.LITERACY_BUNDLE]: {
    name: products.LITERACY_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: LiteracyBundle,
        alt: 'Literacy Bundle',
      },
      name: 'Literacy Bundle',
    },
    gamesIncluded: [
      games.LETTERTOPIA,
      games.WORDS_CHOMP,
      games.WORDS_EXPLORERS,
    ],
  },
  [products.UE_BUNDLE]: {
    name: products.UE_BUNDLE,
    type: productTypes.BUNDLE,
    header: {
      image: {
        src: UltimateExpansionBundle,
        alt: 'Ultimate Expansion Bundle',
      },
      name: 'Ultimate Expansion Bundle',
    },
    gamesIncluded: [
      games.LETTERTOPIA,
      games.WORDS_EXPLORERS,
      games.WORDS_CHOMP,
      games.MATHBUZZ,
      games.TOYBOX,
      games.NUMBERS_COOKING_CHAOS,
    ],
  },

  // Digital games
  aztext: {
    type: productTypes.GAME,
    header: {
      image: {
        src: WordsExplorersIcon,
        alt: 'Words Explorers',
      },
      name: 'Osmo Words Explorers',
      hideGamesInHeader: true,
    },
    gamesIncluded: [games.WORDS_EXPLORERS],
  },
  [games.WORDS_CHOMP]: {
    name: games.WORDS_CHOMP,
    type: productTypes.GAME,
    header: {
      image: {
        src: WordsChompIcon,
        alt: 'Words Chomp',
      },
      name: 'Osmo Words Chomp',
      hideGamesInHeader: true,
    },
    gamesIncluded: [games.WORDS_CHOMP],
  },
  [games.NUMBERS_COOKING_CHAOS]: {
    name: games.NUMBERS_COOKING_CHAOS,
    type: productTypes.GAME,
    header: {
      image: {
        src: CookingChaosIcon,
        alt: 'Cooking Chaos',
      },
      name: 'Osmo Numbers Cooking Chaos',
      hideGamesInHeader: true,
    },
    gamesIncluded: [games.NUMBERS_COOKING_CHAOS],
  },

  // Games with hardware
  [games.MATH_WIZARD]: {
    name: games.MATH_WIZARD,
    type: productTypes.GAME,
    header: {
      image: {
        src: MathWizardProductImage,
        alt: 'Math Wizard Product Image',
      },
      name: 'Osmo Math Wizard',
      hideGamesInHeader: true,
    },
    gamesIncluded: [games.MATH_WIZARD],
  },
  [products.DETECTIVE]: {
    name: products.DETECTIVE,
    type: productTypes.GAME,
    header: {
      name: 'Detective Agency',
      image: {
        src: DetectivePackageRender,
        alt: 'Detective Agency Product Image',
      },
    },
    onboardingAssets: {
      packageRender: DetectivePackageRender,
    },
    gamesIncluded: [games.DETECTIVE],
  },
  [products.DETECTIVE_SK]: {
    name: products.DETECTIVE_SK,
    type: productTypes.KIT,
    header: {
      name: 'Detective Agency Starter Kit',
    },
    onboardingAssets: {
      packageRender: DetectiveSKPackageRender,
    },
    gamesIncluded: [games.DETECTIVE],
  },
  [products.SUPERSTUDIO_PRINCESS]: {
    name: products.SUPERSTUDIO_PRINCESS,
    type: productTypes.GAME,
    header: {
      name: 'Super Studio Disney Princess',
      image: {
        src: SSPrincessPackageRender,
        alt: 'Super Studio Disney Princess Product Image',
      },
    },
    onboardingAssets: {
      packageRender: SSPrincessPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_PRINCESS],
  },
  [products.SUPERSTUDIO_PRINCESS_SK]: {
    name: products.SUPERSTUDIO_PRINCESS_SK,
    type: productTypes.KIT,
    header: {
      name: 'Super Studio Disney Princess Starter Kit',
      image: {
        src: SSPrincessSKPackageRender,
        alt: 'Super Studio Disney Princess Starter Kit Product Image',
      },
    },
    onboardingAssets: {
      packageRender: SSPrincessSKPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_PRINCESS],
  },
  [products.SUPERSTUDIO_FROZEN]: {
    name: products.SUPERSTUDIO_FROZEN,
    type: productTypes.GAME,
    header: {
      name: 'Super Studio Frozen 2',
      image: {
        src: SSFrozenPackageRender,
        alt: 'Super Studio Frozen 2 Product Image',
      },
    },
    onboardingAssets: {
      packageRender: SSFrozenPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_FROZEN],
  },
  [products.SUPERSTUDIO_FROZEN_SK]: {
    name: products.SUPERSTUDIO_FROZEN_SK,
    type: productTypes.KIT,
    header: {
      name: 'Super Studio Frozen 2 Starter Kit',
    },
    onboardingAssets: {
      // packageRender: SSFrozenSKPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_FROZEN],
  },
  [products.SUPERSTUDIO_MICKEY]: {
    name: products.SUPERSTUDIO_MICKEY,
    type: productTypes.GAME,
    header: {
      name: 'Super Studio Disney Mickey Mouse & Friends',
      image: {
        src: SSMickeyPackageRender,
        alt: 'Super Studio Disney Mickey Mouse & Friends Product Image',
      },
    },
    onboardingAssets: {
      packageRender: SSMickeyPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_MICKEY],
  },
  [products.SUPERSTUDIO_MICKEY_SK]: {
    name: products.SUPERSTUDIO_MICKEY_SK,
    type: productTypes.KIT,
    header: {
      name: 'Super Studio Disney Mickey Mouse & Friends Starter Kit',
    },
    onboardingAssets: {
      packageRender: SSMickeySKPackageRender,
    },
    gamesIncluded: [games.SUPERSTUDIO_MICKEY],
  },
  [products.NUMBERS]: {
    name: products.NUMBERS,
    type: productTypes.GAME,
    header: {
      name: 'Osmo Numbers',
    },
    // onboardingAssets: {
    //   packageRender: NumbersPackageRender,
    // },
    gamesIncluded: [games.NUMBERS],
  },
  [products.NUMBERS_SK]: {
    name: products.NUMBERS_SK,
    type: productTypes.KIT,
    header: {
      name: 'Osmo Numbers Starter Kit',
    },
    onboardingAssets: {
      packageRender: NumbersSKPackageRender,
    },
    gamesIncluded: [games.NUMBERS],
  },
  [products.WORDS]: {
    name: products.WORDS,
    type: productTypes.GAME,
    header: {
      name: 'Osmo Words',
    },
    onboardingAssets: {
      // packageRender: WordsPackageRender,
    },
    gamesIncluded: [games.WORDS],
  },
  [products.WORDS_SK]: {
    name: products.WORDS_SK,
    type: productTypes.KIT,
    header: {
      name: 'Osmo Words Starter Kit',
    },
    onboardingAssets: {
      packageRender: WordsSKPackageRender,
    },
    gamesIncluded: [games.WORDS],
  },
  [products.PIZZA]: {
    name: products.PIZZA,
    type: productTypes.GAME,
    header: {
      name: 'Osmo Pizza Co.',
      image: {
        src: PizzaPackageRender,
        alt: 'Osmo Pizza Co. Product Image',
      },
    },
    onboardingAssets: {
      packageRender: PizzaPackageRender,
    },
    gamesIncluded: [games.PIZZACO],
  },
  [products.PIZZA_SK]: {
    name: products.PIZZA_SK,
    type: productTypes.KIT,
    header: {
      name: 'Osmo Pizza Co. Starter Kit',
    },
    onboardingAssets: {
      packageRender: PizzaSKPackageRender,
    },
    gamesIncluded: [games.PIZZACO],
  },
  [products.STICKS_RINGS_SK]: {
    name: products.STICKS_RINGS_SK,
    type: productTypes.KIT,
    header: {
      name: 'Stick & Rings Starter Kit',
    },
    onboardingAssets: {
      packageRender: SticksSKPackageRender,
    },
    gamesIncluded: [games.ABCS, games.SQUIGGLE_MAGIC],
  },
};
