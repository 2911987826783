export default {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 75,
  w: 360,
  h: 360,
  nm: 'onboarding_2022-animation-rotate_device 2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 3',
      parent: 4,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 0,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.08],
                y: [1],
              },
              o: {
                x: [0.089],
                y: [0.002],
              },
              t: 28,
              s: [-90],
            },
            {
              i: {
                x: [0.08],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 37,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 66,
              s: [0],
            },
            {
              t: 74,
              s: [-90],
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [0, 0, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'x-left',
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 28,
              s: [0.125, -0.375, 0],
              to: [-1.5, 1.333, 0],
              ti: [1.5, -1.333, 0],
            },
            {
              i: {
                x: 0.833,
                y: 0.833,
              },
              o: {
                x: 0.167,
                y: 0.167,
              },
              t: 37,
              s: [-8.875, 7.625, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 66,
              s: [-8.875, 7.625, 0],
              to: [1.5, -1.333, 0],
              ti: [-1.5, 1.333, 0],
            },
            {
              t: 74,
              s: [0.125, -0.375, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                t: 28,
                s: [
                  {
                    i: [
                      [1.024, 0],
                      [0.781, 0.781],
                      [0, 0],
                      [-1.562, 1.561],
                      [-1.562, -1.562],
                      [0, 0],
                      [1.562, -1.562],
                    ],
                    o: [
                      [-1.024, 0],
                      [0, 0],
                      [-1.562, -1.561],
                      [1.562, -1.562],
                      [0, 0],
                      [1.562, 1.562],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [12.5, 16.695],
                      [9.671, 15.523],
                      [-14.329, -8.477],
                      [-14.329, -14.133],
                      [-8.671, -14.133],
                      [15.329, 9.867],
                      [15.329, 15.523],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: {
                  x: 0.833,
                  y: 1,
                },
                o: {
                  x: 0.05,
                  y: 1,
                },
                t: 37,
                s: [
                  {
                    i: [
                      [1.023, 0],
                      [0.781, 0.781],
                      [0, 0],
                      [-1.562, 1.562],
                      [-1.561, -1.561],
                      [0, 0],
                      [1.562, -1.562],
                    ],
                    o: [
                      [-1.024, 0],
                      [0, 0],
                      [-1.562, -1.561],
                      [1.562, -1.562],
                      [0, 0],
                      [1.562, 1.562],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [5.793, 9.988],
                      [2.964, 8.816],
                      [-7.621, -1.77],
                      [-7.621, -7.426],
                      [-1.964, -7.426],
                      [8.621, 3.16],
                      [8.621, 8.816],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                t: 66,
                s: [
                  {
                    i: [
                      [1.023, 0],
                      [0.781, 0.781],
                      [0, 0],
                      [-1.562, 1.562],
                      [-1.561, -1.561],
                      [0, 0],
                      [1.562, -1.562],
                    ],
                    o: [
                      [-1.024, 0],
                      [0, 0],
                      [-1.562, -1.561],
                      [1.562, -1.562],
                      [0, 0],
                      [1.562, 1.562],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [5.793, 9.988],
                      [2.964, 8.816],
                      [-7.621, -1.77],
                      [-7.621, -7.426],
                      [-1.964, -7.426],
                      [8.621, 3.16],
                      [8.621, 8.816],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 74,
                s: [
                  {
                    i: [
                      [1.024, 0],
                      [0.781, 0.781],
                      [0, 0],
                      [-1.562, 1.561],
                      [-1.562, -1.562],
                      [0, 0],
                      [1.562, -1.562],
                    ],
                    o: [
                      [-1.024, 0],
                      [0, 0],
                      [-1.562, -1.561],
                      [1.562, -1.562],
                      [0, 0],
                      [1.562, 1.562],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [12.5, 16.695],
                      [9.671, 15.523],
                      [-14.329, -8.477],
                      [-14.329, -14.133],
                      [-8.671, -14.133],
                      [15.329, 9.867],
                      [15.329, 15.523],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [278.688, 369.219],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 4,
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [3.758, 0.477],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [23.606, 20.36],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'x-right',
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 28,
              s: [0.125, -0.375, 0],
              to: [0.667, 0.25, 0],
              ti: [-0.667, -0.25, 0],
            },
            {
              i: {
                x: 1,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0.167,
              },
              t: 37,
              s: [4.125, 1.125, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 66,
              s: [4.125, 1.125, 0],
              to: [-0.667, -0.25, 0],
              ti: [0.667, 0.25, 0],
            },
            {
              t: 74,
              s: [0.125, -0.375, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 1,
            k: [
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                t: 28,
                s: [
                  {
                    i: [
                      [1.024, 0],
                      [0.781, 0.781],
                      [-1.562, 1.562],
                      [0, 0],
                      [-1.562, -1.562],
                      [1.562, -1.561],
                      [0, 0],
                    ],
                    o: [
                      [-1.024, 0],
                      [-1.562, -1.562],
                      [0, 0],
                      [1.562, -1.562],
                      [1.562, 1.561],
                      [0, 0],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [-11.5, 16.695],
                      [-14.329, 15.523],
                      [-14.329, 9.867],
                      [9.671, -14.133],
                      [15.329, -14.133],
                      [15.329, -8.477],
                      [-8.671, 15.523],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: {
                  x: 1,
                  y: 1,
                },
                o: {
                  x: 0.167,
                  y: 0,
                },
                t: 37,
                s: [
                  {
                    i: [
                      [1.36, 0],
                      [0.644, 0.386],
                      [-1.135, 1.896],
                      [0, 0],
                      [-1.897, -1.137],
                      [1.135, -1.896],
                      [0, 0],
                    ],
                    o: [
                      [-0.699, 0],
                      [-1.896, -1.135],
                      [0, 0],
                      [1.134, -1.895],
                      [1.896, 1.135],
                      [0, 0],
                      [-0.75, 1.252],
                    ],
                    v: [
                      [-6.64, 16.705],
                      [-8.691, 16.136],
                      [-10.069, 10.649],
                      [4.204, -13.191],
                      [9.691, -14.568],
                      [11.069, -9.082],
                      [-3.204, 14.759],
                    ],
                    c: true,
                  },
                ],
              },
              {
                i: {
                  x: 0.667,
                  y: 1,
                },
                o: {
                  x: 0.333,
                  y: 0,
                },
                t: 66,
                s: [
                  {
                    i: [
                      [1.36, 0],
                      [0.644, 0.386],
                      [-1.135, 1.896],
                      [0, 0],
                      [-1.897, -1.137],
                      [1.135, -1.896],
                      [0, 0],
                    ],
                    o: [
                      [-0.699, 0],
                      [-1.896, -1.135],
                      [0, 0],
                      [1.134, -1.895],
                      [1.896, 1.135],
                      [0, 0],
                      [-0.75, 1.252],
                    ],
                    v: [
                      [-6.64, 16.705],
                      [-8.691, 16.136],
                      [-10.069, 10.649],
                      [4.204, -13.191],
                      [9.691, -14.568],
                      [11.069, -9.082],
                      [-3.204, 14.759],
                    ],
                    c: true,
                  },
                ],
              },
              {
                t: 74,
                s: [
                  {
                    i: [
                      [1.024, 0],
                      [0.781, 0.781],
                      [-1.562, 1.562],
                      [0, 0],
                      [-1.562, -1.562],
                      [1.562, -1.561],
                      [0, 0],
                    ],
                    o: [
                      [-1.024, 0],
                      [-1.562, -1.562],
                      [0, 0],
                      [1.562, -1.562],
                      [1.562, 1.561],
                      [0, 0],
                      [-0.781, 0.781],
                    ],
                    v: [
                      [-11.5, 16.695],
                      [-14.329, 15.523],
                      [-14.329, 9.867],
                      [9.671, -14.133],
                      [15.329, -14.133],
                      [15.329, -8.477],
                      [-8.671, 15.523],
                    ],
                    c: true,
                  },
                ],
              },
            ],
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [278.688, 369.219],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 4,
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-1.078, -0.328],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [25.285, 18.278],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'circle',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [180, 180, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 28,
              s: [100, 100, 100],
            },
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 37,
              s: [110, 110, 100],
            },
            {
              t: 40,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [-15.464, 0],
                [0, -15.464],
                [15.464, 0],
                [0, 15.464],
              ],
              o: [
                [15.464, 0],
                [0, 15.464],
                [-15.464, 0],
                [0, -15.464],
              ],
              v: [
                [0.5, -27.5],
                [28.5, 0.5],
                [0.5, 28.5],
                [-27.5, 0.5],
              ],
              c: true,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [74.543, 68.211],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 4,
              },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 28,
                    s: [0.78823530674, 0.78823530674, 0.78823530674, 1],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 37,
                    s: [0.992156863213, 0.709803938866, 0.098039217293, 1],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [0.833],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: 66,
                    s: [0.992156863213, 0.709803938866, 0.098039217293, 1],
                  },
                  {
                    t: 74,
                    s: [0.78823530674, 0.78823530674, 0.78823530674, 1],
                  },
                ],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-0.729, -0.895],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 1,
      nm: 'White Solid 2',
      parent: 8,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [80.25, 114.399, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [180, 180, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [-3.332, 0],
                [0, -3.331],
                [3.332, 0],
                [0, 3.332],
              ],
              o: [
                [3.332, 0],
                [0, 3.332],
                [-3.332, 0],
                [0, -3.331],
              ],
              v: [
                [180, 277.905],
                [186.032, 283.937],
                [180, 289.97],
                [173.968, 283.937],
              ],
              c: true,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      sw: 360,
      sh: 360,
      sc: '#eeeeee',
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 1,
      nm: 'Gray Solid 1',
      parent: 8,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [80.25, 111.899, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [180, 180, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [-1.325, 0],
                [0, -1.325],
                [1.325, 0],
                [0, 1.325],
              ],
              o: [
                [1.325, 0],
                [0, 1.325],
                [-1.325, 0],
                [0, -1.325],
              ],
              v: [
                [180, 77.601],
                [182.399, 80],
                [180, 82.399],
                [177.601, 80],
              ],
              c: true,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      sw: 360,
      sh: 360,
      sc: '#818181',
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 1,
      nm: 'White Solid 1',
      parent: 8,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [80.25, 114.399, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [180, 180, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [1.367, 0],
                [0, 0],
                [0, 1.367],
                [0, 0],
                [-1.367, 0],
                [0, 0],
                [0, -1.367],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-1.367, 0],
                [0, 0],
                [0, -1.367],
                [0, 0],
                [1.367, 0],
                [0, 0],
                [0, 1.365],
              ],
              v: [
                [248.477, 273.576],
                [111.523, 273.576],
                [109.049, 271.102],
                [109.049, 88.898],
                [111.523, 86.424],
                [248.477, 86.424],
                [250.951, 88.898],
                [250.951, 271.102],
              ],
              c: true,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      sw: 360,
      sh: 360,
      sc: '#ffffff',
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'device front Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.1],
                y: [1],
              },
              o: {
                x: [0.09],
                y: [0.002],
              },
              t: 28,
              s: [-90],
            },
            {
              i: {
                x: [0.999],
                y: [1],
              },
              o: {
                x: [0],
                y: [0],
              },
              t: 37,
              s: [0],
            },
            {
              i: {
                x: [0.1],
                y: [1],
              },
              o: {
                x: [0.09],
                y: [-0.002],
              },
              t: 66,
              s: [0],
            },
            {
              t: 74,
              s: [-90],
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [180, 180, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [80.25, 114.398, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.488, 0],
                    [0, 0],
                    [0, 6.488],
                    [0, 0],
                    [-6.488, 0],
                    [0, 0],
                    [0, -6.488],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-6.488, 0],
                    [0, 0],
                    [0, -6.488],
                    [0, 0],
                    [6.488, 0],
                    [0, 0],
                    [0, 6.488],
                  ],
                  v: [
                    [68.251, 114.148],
                    [-68.251, 114.148],
                    [-80, 102.399],
                    [-80, -102.4],
                    [-68.251, -114.148],
                    [68.251, -114.148],
                    [80, -102.4],
                    [80, 102.399],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.784313785329, 0.784313785329, 0.784313785329, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [80.25, 114.399],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 1,
      nm: 'Light Gray Solid 2',
      parent: 8,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [80.25, 117.399, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [180, 180, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [6.489, 0],
                [0, 0],
                [0, 6.489],
                [0, 0],
                [-6.489, 0],
                [0, 0],
                [0, -6.489],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-6.489, 0],
                [0, 0],
                [0, -6.489],
                [0, 0],
                [6.489, 0],
                [0, 0],
                [0, 6.489],
              ],
              v: [
                [248.251, 294.148],
                [111.749, 294.148],
                [100, 282.399],
                [100, 77.601],
                [111.749, 65.852],
                [248.251, 65.852],
                [260, 77.601],
                [260, 282.399],
              ],
              c: true,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      sw: 360,
      sh: 360,
      sc: '#aaaaaa',
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'speckle 5 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [0],
            },
            {
              t: 53,
              s: [100],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [231.441, 72.283, 0],
              to: [9.083, 0.083, 0],
              ti: [-9.083, -0.083, 0],
            },
            {
              t: 42,
              s: [285.941, 72.783, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [70, 70, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'speckle 4 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [20],
            },
            {
              t: 53,
              s: [140],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [231.441, 72.283, 0],
              to: [14.083, -8.75, 0],
              ti: [-14.083, 8.75, 0],
            },
            {
              t: 42,
              s: [315.941, 19.783, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'speckle 3 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [40],
            },
            {
              t: 53,
              s: [160],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [231.441, 72.283, 0],
              to: [5.75, -7.417, 0],
              ti: [-5.75, 7.417, 0],
            },
            {
              t: 42,
              s: [265.941, 27.783, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [40, 40, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'speckle 2 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [0],
            },
            {
              t: 53,
              s: [100],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [110.441, 80.283, 0],
              to: [-6.5, -10.333, 0],
              ti: [6.5, 10.333, 0],
            },
            {
              t: 42,
              s: [71.441, 18.283, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [70, 70, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'speckle Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [20],
            },
            {
              t: 53,
              s: [140],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [110.441, 80.283, 0],
              to: [-9.083, -2.833, 0],
              ti: [9.083, 2.833, 0],
            },
            {
              t: 42,
              s: [55.941, 63.283, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'speckle 6 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 34,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 35,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 43,
              s: [100],
            },
            {
              t: 53,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 33,
              s: [40],
            },
            {
              t: 53,
              s: [160],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [110.441, 80.283, 0],
              to: [-2.417, -6.25, 0],
              ti: [2.417, 6.25, 0],
            },
            {
              t: 42,
              s: [95.941, 42.783, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [8.157, 7.239, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [40, 40, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.859, 1.368],
                    [0, 0],
                    [1.613, 0.058],
                    [0, 0],
                    [-0.759, -1.427],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.858, -1.371],
                    [0, 0],
                    [-1.614, -0.058],
                    [0, 0],
                    [0.754, 1.43],
                  ],
                  v: [
                    [1.438, 5.62],
                    [7.049, -3.34],
                    [5.346, -6.552],
                    [-5.218, -6.931],
                    [-7.148, -3.85],
                    [-2.193, 5.488],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.996078491211, 0.89019613827, 0.509803921569, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [8.156, 7.239],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
