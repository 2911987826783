import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQueryParam } from 'use-query-params';

import { trajectories } from 'src/store/onboarding/reducers';

import * as selectors from 'src/selectors';
import * as actions from 'src/actions';

import withLoading from 'src/components/hoc/withLoading';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import SignUp from './components/SignUp';
import LogIn from './components/LogIn';
import NeedSignUp from './components/NeedSignUp';

import './style.scss';

export const views = {
  SIGNUP: 'SIGNUP',
  LOGIN: 'LOGIN',
  NEED_SIGNUP: 'NEED_SIGNUP',
};

const Account = ({
  // From Redux
  trajectoryType,
  dispatch,
}) => {
  const [currentView, setCurrentView] = useState(views.SIGNUP);
  const [viewFormURL] = useQueryParam('view');

  // ===========================================================================
  // ================================ HOOKS ====================================
  // ===========================================================================

  useEffect(() => {
    if (viewFormURL === 'login') {
      setCurrentView(views.LOGIN);
    }
  }, [viewFormURL]);

  useEffect(() => {
    let trajectory = trajectoryType;
    switch (currentView) {
      case views.LOGIN:
        trajectory = trajectories.LOGIN;
        break;

      case views.NEED_SIGNUP:
        trajectory = trajectories.CREATION;
        break;

      default:
        break;
    }
    // Set trajectory
    dispatch({
      type: actions.onboarding.SET_TRAJECTORY_TYPE,
      payload: {
        trajectory,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  // ===========================================================================
  // =============================== RENDER ====================================
  // ===========================================================================

  const getView = () => {
    switch (currentView) {
      case views.SIGNUP:
        return <SignUp updateAccountView={(view) => setCurrentView(view)} />;

      case views.LOGIN:
        return <LogIn updateAccountView={(view) => setCurrentView(view)} />;

      case views.NEED_SIGNUP:
        return (
          <NeedSignUp updateAccountView={(view) => setCurrentView(view)} />
        );

      default:
        break;
    }
  };

  return (
    <div className="onboarding-account">
      <Container>
        <CenterContent>{getView()}</CenterContent>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trajectoryType: selectors.onboarding.getOnboardingTrajectorySelector(state),
});

export default connect(mapStateToProps)(withLoading(Account));
