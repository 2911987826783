import { fetchOsmoServer } from 'src/apis/helpers/fetch';

export async function updateUser(params) {
  const { userId, payload } = params;

  const { schoolSettings } = payload;

  const response = await fetchOsmoServer({
    endpoint: `/api/v3/users/${userId}`,
    method: 'PUT',
    body: {
      user: {
        school_settings: {
          school_usage: schoolSettings?.schoolUsage,
          update_school_usage: schoolSettings?.updateSchoolUsage,
        },
      },
    },
  });

  return response;
}
