import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { IS_PRODUCTION } from 'src/config/environment';

import rootReducer from 'src/reducers';

import sagas from 'src/store/sagas';

export default function configureStore(initialState = {}) {
  // Add redux-saga middleware
  const sagaMiddlewareParams = {};
  const sagaMiddleware = createSagaMiddleware(sagaMiddlewareParams);

  const enhancers = [];
  const middleware = [sagaMiddleware];

  // Load devtools on local
  if (!IS_PRODUCTION) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const persistedReducer = rootReducer;

  const store = createStore(persistedReducer, initialState, composedEnhancers);

  sagaMiddleware.run(sagas);

  let persistor = persistStore(store);
  return { store, persistor };
}
