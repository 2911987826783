import React from 'react';
import Loader from '../Loader';

import './styles.scss';

/**
 *  Osmo Loading with 4 dots
 */

const Loading = React.memo(() => {
  return (
    <div className="loading">
      <div className="loading__inner">
        <Loader />
      </div>
    </div>
  );
});

export default Loading;
