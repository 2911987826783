/**
 * Used to check if the 'Enter' keydown event occured on any element
 */
export const isEnterKeydownEvent = (e) =>
  (e.which || e.keyCode) && (e.which === 13 || e.keyCode === 13);

/**
 * Used to check if the 'Escape' keydown event occured on any element
 */
export const isEscKeydownEvent = (e) =>
  (e.which || e.keyCode) && (e.which === 27 || e.keyCode === 27);

/**
 * Used to check if the 'Escape' keydown event occured on any element
 */
export const isDownKeydownEvent = (e) =>
  (e.which || e.keyCode) && (e.which === 40 || e.keyCode === 40);

/**
 * Used to check if the 'Escape' keydown event occured on any element
 */
export const isUpKeydownEvent = (e) =>
  (e.which || e.keyCode) && (e.which === 38 || e.keyCode === 38);

/**
 * Used to check if the 'Escape' keydown event occured on any element
 */
export const isSpaceKeydownEvent = (e) =>
  (e.which || e.keyCode) &&
  (e.which === 32 || e.keyCode === 32 || e.which === 0 || e.keyCode === 0);
