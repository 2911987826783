import * as actions from 'src/actions';

const initialState = {
  redeemCode: {
    code: '',
    devices: [],
    isRedeemed: false,
    product: '',
    digitalAppsOnly: false,
    apps: [],
  },
  email: '',
  emailVerificationId: '',
  user: {},
  tmpAccountInformation: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.redeem.SET_REDEEM_CODE: {
      const {
        code,
        devices,
        product,
        isRedeemed,
        digitalAppsOnly,
        apps,
      } = action.payload;

      const redeemCode = {
        code,
        devices,
        product,
        isRedeemed,
        digitalAppsOnly,
        apps,
      };

      return { ...initialState, ...state, redeemCode };
    }

    case actions.redeem.SET_REDEEM_CODE_DEVICE: {
      const { device } = action.payload;

      const redeemCode = { ...state.redeemCode, devices: [device] };

      return { ...initialState, ...state, redeemCode };
    }

    case actions.redeem.SET_EMAIL: {
      const { email } = action.payload;

      return { ...initialState, ...state, email };
    }

    case actions.redeem.CLEAR_EMAIL: {
      return {
        ...initialState,
        ...state,
        email: initialState.email,
        emailVerificationId: initialState.emailVerificationId,
      };
    }

    case actions.redeem.SET_EMAIL_VERIFICATION_ID: {
      const { emailVerificationId } = action.payload;

      return { ...initialState, ...state, emailVerificationId };
    }

    case actions.redeem.SET_USER: {
      const { user } = action.payload;

      return { ...initialState, ...state, user };
    }

    case actions.redeem.SET_TMP_ACCOUNT_INFORMATION: {
      const { tmpAccountInformation } = action.payload;

      return {
        ...initialState,
        ...state,
        tmpAccountInformation: {
          ...(state.tmpAccountInformation || {}),
          ...tmpAccountInformation,
        },
      };
    }

    case actions.redeem.SET_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
