import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const DevicePicker = (props) => {
  const { illustration, logo, onClick } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div className="device-picker" onClick={_onClick}>
      <div className="device-picker__container">
        {illustration && (
          <img
            className="device-picker__illustration"
            src={illustration.src}
            alt={illustration.alt}
          />
        )}

        {logo && (
          <div className="device-picker__logo-holder">
            <img
              className="device-picker__logo"
              src={logo.src}
              alt={logo.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DevicePicker.propTypes = {
  illustration: PropTypes.object,
  logo: PropTypes.object,
  onClick: PropTypes.func,
};

export default DevicePicker;
