import { products, productsData } from './products';

import { games, gamesData } from 'src/data/games';

import GeniusSKImagePlusIpadSheet from 'src/assets/images/illustrations/illustration-gsk-plus-ipad-sheet.png';
import GeniusSKImagePlusFireSheet from 'src/assets/images/illustrations/illustration-gsk-plus-fire-sheet.png';
import LittleGeniusSKImagePlusIpadSheet from 'src/assets/images/illustrations/illustration-lgsk-plus-ipad-sheet.png';
import LittleGeniusSKImagePlusFireSheet from 'src/assets/images/illustrations/illustration-lgsk-plus-fire-sheet.png';
import FamilyGameNightSheet from 'src/assets/images/illustrations/illustration-family-night-bundle-sheet.png';
import EarlyMathAdventureSheet from 'src/assets/images/illustrations/illustration-early-math-bundle-sheet.png';

// Product instructions data
export const productInstructionsData = {
  [products.GENIUS_SK]: {
    id: productsData[products.GENIUS_SK].title,
    title: 'Genius Starter Kit + Family Game Night',
    description: {
      __html:
        '<strong>Genius Starter Kit + Family Game Night</strong> includes <strong>7</strong> games in total. To play two of these games (Math Buzz and Lettertopia) you will need to redeem the code which comes in the box.',
    },
    content: _getSetupInstructionsForKits({
      productName: productsData[products.GENIUS_SK].title,
      imageIpad: GeniusSKImagePlusIpadSheet,
      imageFire: GeniusSKImagePlusFireSheet,
    }),
  },
  [products.LITTLE_GENIUS_SK]: {
    id: productsData[products.LITTLE_GENIUS_SK].title,
    title: 'Little Genius Starter Kit + Early Math Adventure',
    description: {
      __html:
        '<strong>The Little Genius Starter Kit + Early Math</strong> includes <strong>6</strong> games in total. To play two of these games (Counting Town and Shape Builder) you will need to redeem the code which comes in the box.',
    },
    content: _getSetupInstructionsForKits({
      productName: productsData[products.LITTLE_GENIUS_SK].title,
      imageIpad: LittleGeniusSKImagePlusIpadSheet,
      imageFire: LittleGeniusSKImagePlusFireSheet,
    }),
  },
  [products.FAMILY_GAME_NIGHT_BUNDLE]: {
    id: productsData[products.FAMILY_GAME_NIGHT_BUNDLE].title,
    title: 'Family Game Night Bundle',
    description: {
      __html: `<p><strong>The Family Game Night Bundle</strong> includes <strong>2</strong> downloadable games: <strong>Math Buzz</strong> and <strong>Lettertopia</strong>. It can be purchased on the Osmo website and directly from the App Store on your compatible device.</p>
        <p><strong>Please note:</strong> The Numbers tiles and the Words tiles are required to play these games and are sold separately or as part of the Genius Starter Kit.</p>`,
    },
    content: _getSetupInstructionsForBundles({
      productName: productsData[products.FAMILY_GAME_NIGHT_BUNDLE].title,
      image: FamilyGameNightSheet,
      games: [games.MATHBUZZ, games.LETTERTOPIA],
    }),
  },
  [products.EARLY_MATH_ADVENTURE_BUNDLE]: {
    id: productsData[products.EARLY_MATH_ADVENTURE_BUNDLE].title,
    title: 'Early Math Adventure Bundle',
    description: {
      __html: `<p><strong>The Early Math Adventure Bundle</strong> includes <strong>2</strong> downloadable games: <strong>Counting Town</strong> and <strong>Shape Builder</strong>. It can be purchased on the Osmo website and directly from the App Store on your compatible device.</p>
        <p><strong>Please note:</strong> The Little Genius Sticks & Rings are required to play these games and are sold separately or as part of the Little Genius Starter Kit.</p>`,
    },
    content: _getSetupInstructionsForBundles({
      productName: productsData[products.EARLY_MATH_ADVENTURE_BUNDLE].title,
      image: EarlyMathAdventureSheet,
      games: [games.COUNTING_COUNTY, games.SHAPES],
    }),
  },
  [products.ESSENTIAL_MATH_ADVENTURE_BUNDLE]: {
    id: productsData[products.ESSENTIAL_MATH_ADVENTURE_BUNDLE].title,
    title: 'Essential Math Bundle',
    description: {
      __html:
        '<strong>The Essential Math Bundle</strong> includes <strong>4</strong> games in total. To play three of these games (Counting Town, Shape Builder and Number Toybox) you will need to redeem the code which comes in the box.',
    },
    content: _getSetupInstructionsForBundles({
      productName: productsData[products.ESSENTIAL_MATH_ADVENTURE_BUNDLE].title,
      image: EarlyMathAdventureSheet,
      games: [games.NUMBERS, games.COUNTING_COUNTY, games.SHAPES, games.TOYBOX],
    }),
  },
  [products.MATH_CHAMPION_BUNDLE]: {
    id: productsData[products.MATH_CHAMPION_BUNDLE].title,
    title: 'Math Champion Bundle',
    description: {
      __html:
        '<strong>The Math Champion Bundle</strong> includes <strong>3</strong> games in total. To play two of these games (Math buzz and Numbers Toybox) you will need to redeem the code which comes in the box.',
    },
    content: _getSetupInstructionsForBundles({
      productName: productsData[products.MATH_CHAMPION_BUNDLE].title,
      image: EarlyMathAdventureSheet,
      games: [games.PIZZACO, games.MATHBUZZ, games.TOYBOX],
    }),
  },
};

// ===========================================================================
// ======================= SETUP INSTRUCTIONS GETTERS ========================
// ===========================================================================

// Get setup steps common to iPad and Fire
function _getSetupSteps({ productName, image } = {}) {
  return [
    {
      text: {
        __html:
          'Open the box and locate the slip of paper that says <strong>“Set Up”</strong> in red font.',
      },
      image: {
        src: image,
        alt: productName,
      },
    },
    {
      text: {
        __html:
          'Locate your <strong>redemption code</strong> located in the white box on this slip of paper.',
      },
    },
    {
      text: {
        __html:
          'Use the browser on your device to navigate to <strong>playosmo.com/redeem</strong>',
      },
    },
    {
      text: {
        __html:
          'Enter your <strong>redemption code</strong> into the space provided.',
      },
    },
    {
      text: {
        __html: `When prompted, <strong>sign in to your Osmo account</strong>. If you don't have a Osmo account, you will be prompted to create one in order to redeem your purchase.`,
      },
    },
  ];
}

// Get setup instructions for iPad
function _getSetupInstructionsForIPad({ productName, image }) {
  return {
    label: 'Setup Instructions for iPad',
    steps: [
      ..._getSetupSteps({ productName, image }),
      {
        text: {
          __html:
            'You will then receive a prompt to download Osmo World app to your device. The Osmo World app is a hub that allows you to access all of the Osmo game apps from one place.',
        },
      },
      {
        text: {
          __html:
            'Launch Osmo World and <strong>sign in to your Osmo account</strong> using your email address.',
        },
      },
      {
        text: {
          __html: `When prompted to choose which games you purchased, select <strong>${productName}</strong>.`,
        },
      },
      {
        text: {
          __html: `Download the apps to your device, you're ready to start playing!`,
        },
      },
    ],
  };
}

// Get setup instructions for Fire
function _getSetupInstructionsForFire({ productName, image } = {}) {
  return {
    label: 'Setup Instructions for Fire Tablet',
    steps: [
      ..._getSetupSteps({ productName, image }),
      {
        text: {
          __html:
            'Follow the steps on the website to install the apps onto your Fire Tablet.',
        },
      },
      {
        text: {
          __html:
            'Make sure to sign in with the same account you used to redeem the code.',
        },
      },
    ],
  };
}

// Get setup instructions for kits
function _getSetupInstructionsForKits({
  productName,
  imageIpad,
  imageFire,
} = {}) {
  return [
    _getSetupInstructionsForIPad({ productName, image: imageIpad }),
    _getSetupInstructionsForFire({ productName, image: imageFire }),
  ];
}

// Get setup instructions for digital bundles
function _getSetupInstructionsForBundles({ productName, image, games } = {}) {
  const gameAppNames = games.map(
    (game) => gamesData[game]?.appName || gamesData[game]?.name,
  );

  // Format the line that displays included games
  const gameAppNamesString = gameAppNames.reduce((string, appName, index) => {
    if (gameAppNames.length - 1 === index) {
      string = `${string} and <strong>${appName}</strong>.`;
    } else if (index === 0) {
      string = string + `<strong>${appName}</strong>`;
    } else {
      string = `${string}, <strong>${appName}</strong>`;
    }

    return string;
  }, '');

  return [
    {
      label: 'Setup Instructions:',
      steps: [
        {
          text: {
            __html:
              'Go to your email inbox and locate the email from Osmo with the subject <strong>“Game Redemption Code.”</strong>',
          },
          image: {
            src: image,
            alt: productName,
          },
        },
        {
          text: {
            __html:
              'Use the browser on your device to navigate to <strong>playosmo.com/redeem</strong>',
          },
        },
        {
          text: {
            __html:
              'Enter your <strong>redemption code</strong> into the space provided.',
          },
        },
        {
          text: {
            __html: `When prompted, <strong>sign in to your Osmo account</strong>. If you don't have an Osmo account, you will be prompted to create one in order to redeem your purchase.`,
          },
        },
        {
          text: {
            __html: `You will then receive a prompt to download the ${games.length} included games: ${gameAppNamesString}`,
          },
        },
        {
          text: {
            __html:
              'Once the games are downloaded to your device, launch one of the games and <strong>sign in to your Osmo account</strong>.',
          },
        },
        {
          text: {
            __html: 'Tap the <strong>"Play"</strong> button to begin playing!',
          },
        },
      ],
    },
  ];
}
