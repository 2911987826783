import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { countries as countriesList } from 'countries-list';
import PropTypes from 'prop-types';
import { CountrySelector as CountrySelectorComponent } from '@tangibleplay/osmo-js-ui';

import { getInfo } from 'src/apis/info';

import ErrorMessage from 'src/components/messages/ErrorMessage';

import './style.scss';

const countries = Object.entries(countriesList)
  .map(([key, value]) => ({
    code: key,
    name: value.name,
  }))
  .sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

/**
 *  Custom Input
 */

const CountrySelector = (props) => {
  const { id, className, name, label, onChange, error } = props;
  const [initialCountry, setInitialCountry] = useState('US');

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  const CountrySelectorClassNames = classNames(
    'osmo-country-selector',
    className,
  );

  // ===========================================================================
  // ============================ USE EFFECTS ==================================
  // ===========================================================================
  useEffect(() => {
    getInfo()
      .then((res) => {
        if (res?.country) {
          setInitialCountry(res.country);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  return (
    <div className={CountrySelectorClassNames}>
      <div className="osmo-country-selector__inner">
        <CountrySelectorComponent
          countries={countries}
          id={id}
          name={name}
          label={label}
          onChange={onChange}
          defaultCountry={initialCountry}
          inputCustomClass="osmo-country-selector__input"
          wrapperCustomClass="osmo-country-selector__wrapper"
        />
      </div>
      {error && (
        <div className="osmo-country-selector__message">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </div>
  );
};

CountrySelector.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.object,
};

export default CountrySelector;
