import { createSelector } from 'reselect';

export const getLoadingObjectSelector = (state) => state.loading || {};

// // ===========================================================================
// // ============================= IS LOADING ==================================
// // ===========================================================================

// Get is loading
export const getIsLoadingSelector = createSelector(
  getLoadingObjectSelector,
  (loading) => {
    return loading.isLoading || false;
  },
);
