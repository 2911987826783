import KeepYourCodeModal from 'src/components/modals/KeepYourCodeModal';
import CodeAlreadyUsedModal from 'src/components/modals/CodeAlreadyUsedModal';
import WarningNewAccountModal from 'src/components/modals/WarningNewAccountModal';

export const modalTypes = {
  KEEP_YOUR_CODE: 'keep-your-code',
  CODE_ALREADY_USED: 'code-already-used',
  WARNING_NEW_ACCOUNT: 'warning-new-account',
};

export const modalComponents = {
  [modalTypes.KEEP_YOUR_CODE]: KeepYourCodeModal,
  [modalTypes.CODE_ALREADY_USED]: CodeAlreadyUsedModal,
  [modalTypes.WARNING_NEW_ACCOUNT]: WarningNewAccountModal,
};
