import React from 'react';
import ReactCodeInput from 'react-verification-code-input';
import PropTypes from 'prop-types';

import './style.scss';

/**
 *  Custom Code Input
 */

const CodeInput = (props) => {
  const { onChange, onComplete } = props;

  return (
    <ReactCodeInput
      className="input-code"
      fieldWidth={48}
      fieldHeight={48}
      onChange={onChange}
      onComplete={onComplete}
    />
  );
};

CodeInput.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
};

export default CodeInput;
