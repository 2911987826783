import { createSelector } from 'reselect';

export const getModalObjectSelector = (state) => state.modal || {};

// // ===========================================================================
// // ============================= MODALS ======================================
// // ===========================================================================

// Get modal show boolean
export const getModalShowSelector = createSelector(
  getModalObjectSelector,
  (modal) => {
    return modal.show || false;
  },
);

// Get modal props
export const getModalPropsSelector = createSelector(
  getModalObjectSelector,
  (modal) => {
    return modal.props || {};
  },
);

// Get modal type
export const getModalTypeSelector = createSelector(
  getModalObjectSelector,
  (modal) => {
    return modal.type || '';
  },
);
