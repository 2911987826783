import React from 'react';

import Container from 'src/components/displays/Container';
import Breadcrumb from 'src/components/general/Breadcrumb';
import Title from 'src/components/texts/Title';
import ProductBlock from './components/ProductBlock';

import { getNavigationElements } from 'src/data/redeem-faq/navigation';
import { orderedProductElements } from 'src/data/redeem-faq/products';

import './style.scss';

const ProductSelection = () => {
  const navigationElements = getNavigationElements();

  return (
    <div className="product-selection-page">
      <Container>
        <Breadcrumb elements={navigationElements} />
        <Title
          className="product-selection-page__title"
          text="I purchased a product that comes with a redemption code. How do I get started?"
          isDark={true}
        />

        <div className="product-selection-page__container">
          <div className="product-selection-page__selection-title">
            {'Select your product for more information:'}
          </div>

          {!!orderedProductElements?.length && (
            <div className="product-selection-page__products">
              {orderedProductElements.map((product) => (
                <ProductBlock key={product.id} product={product} />
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ProductSelection;
