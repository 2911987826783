import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import redeem from 'src/store/redeem/reducers';
import loading from 'src/store/loading/reducers';
import onboarding from 'src/store/onboarding/reducers';
import modal from 'src/store/modal/reducers';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['downloadedGames'],
};

const rootReducer = combineReducers({
  redeem,
  loading,
  onboarding: persistReducer(persistConfig, onboarding),
  modal,
});

export default rootReducer;
