import { fetchOsmoServer } from 'src/apis/helpers/fetch';

import {
  APP_VERSION,
  ONBOARDING_2022_APP_VERSION,
  APP_SOURCE,
  APP_LANG,
} from 'src/config/app';

const getAppVersion = (flow) =>
  flow === 'onboarding2022' ? ONBOARDING_2022_APP_VERSION : APP_VERSION;

export async function getEmailLoginCode(params) {
  const { email, flow } = params;

  const language = APP_LANG;
  const source = APP_SOURCE;
  const version = getAppVersion(flow);

  const response = await fetchOsmoServer({
    endpoint: '/api/v4/login/message/email',
    method: 'POST',
    body: {
      email,
      language,
      version,
      source,
    },
  });

  return response;
}

export async function confirmEmailLoginCode(params) {
  const { email, code, flow } = params;

  const language = APP_LANG;
  const source = APP_SOURCE;
  const version = getAppVersion(flow);

  const response = await fetchOsmoServer({
    endpoint: '/api/v4/login/message/email',
    method: 'PUT',
    body: {
      email,
      code,
      language,
      version,
      source,
    },
  });

  return response;
}

export async function createAccount(params) {
  const {
    firstName,
    lastName,
    email,
    country,
    emailVerificationId,
    isSubscribingToEmails,
    onboardingProduct,
    onboardingGames,
    schoolSettings,
    flow,
  } = params;

  const language = APP_LANG;
  const source = APP_SOURCE;
  const version = getAppVersion(flow);

  const requestParams = {
    firstname: firstName,
    lastname: lastName,
    email_verification_id: emailVerificationId,
    onboarding_product: onboardingProduct,
    onboarding_games: onboardingGames,
    email,
    country,
    language,
    version,
    source,
    subscribe_emails: isSubscribingToEmails,
    school_settings: {
      school_usage: schoolSettings?.schoolUsage,
    },
  };

  const response = await fetchOsmoServer({
    endpoint: '/api/v3/register',
    method: 'POST',
    body: requestParams,
  });

  return response;
}

export async function getEmailStatus(params) {
  const { email } = params;

  const response = await fetchOsmoServer({
    endpoint: `/api/v4/login/status`,
    method: 'GET',
    urlParams: { email },
  });

  return response;
}
