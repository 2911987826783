import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as selectors from 'src/selectors';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';

import IpadContinuationComponent from './components/IpadContinuationComponent';
import FireContinuationComponent from './components/FireContinuationComponent';

import { redeemedGameDevices } from 'src/data/redeem-flow/devices';

import { REDEEM_CODE_ROUTE } from 'src/router/redeem/routes';

import './style.scss';

const ContinueSetup = (props) => {
  const { device, product, isOnlyDigitalApps, apps } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    if (!device || (!apps && !product)) {
      history.push(REDEEM_CODE_ROUTE);
    }
  });

  // ===========================================================================
  // ======================= COMPUTED VARIABLES ================================
  // ===========================================================================

  function _getComponentForSpecificDevice() {
    switch (device) {
      case redeemedGameDevices.FIRE: {
        return <FireContinuationComponent product={product} apps={apps} />;
      }

      case redeemedGameDevices.IPAD: {
        return (
          <IpadContinuationComponent
            isOnlyDigitalApps={isOnlyDigitalApps}
            apps={apps}
          />
        );
      }

      default:
        return null;
    }
  }

  return (
    <div className="continue-setup-page">
      <Container>
        <CenterContent>{_getComponentForSpecificDevice()}</CenterContent>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    device: selectors.redeem.getRedeemDeviceSelector(state),
    product: selectors.redeem.getRedeemProductSelector(state),
    isOnlyDigitalApps: selectors.redeem.getIsOnlyDigitalAppsSelector(state),
    apps: selectors.redeem.getRedeemAppsSelector(state),
  };
}

export default connect(mapStateToProps)(ContinueSetup);
