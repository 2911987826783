import React from 'react';
import { Route } from 'react-router-dom';

import GetMathWizard from 'src/pages/games/GetMathWizard';
import GetMathWizardFire from 'src/pages/games/GetMathWizardFire';

import * as routes from './routes';

const GamesRoutes = () => [
  <Route key={routes.GET_MATH_WIZARD_ROUTE} path={routes.GET_MATH_WIZARD_ROUTE}>
    <GetMathWizard />
  </Route>,
  <Route
    key={routes.GET_MATH_WIZARD_ROUTE}
    path={routes.GET_MATH_WIZARD_FIRE_ROUTE}
  >
    <GetMathWizardFire />
  </Route>,
];

export default GamesRoutes;
