import { trajectories } from 'src/store/onboarding/reducers';
import * as routes from 'src/router/onboarding/routes';

// The progression is different depending on the type of user (new, existing, teacher)
// So we are defining the different trajectories and picking the right one based on the Redux state value
const createAccountPagesTrajectory = [
  routes.ONBOARDING_ACCOUNT_ROUTE,
  routes.ONBOARDING_CONSENT_ROUTE,
  routes.ONBOARDING_WELCOME_ROUTE,
];

const createAccountTeacherPagesTrajectory = [
  routes.ONBOARDING_ACCOUNT_ROUTE,
  routes.ONBOARDING_SURVEY_ROUTE,
  routes.ONBOARDING_CONSENT_ROUTE,
  routes.ONBOARDING_WELCOME_ROUTE,
];

const loginPagesTrajectory = [
  routes.ONBOARDING_ACCOUNT_ROUTE,
  routes.ONBOARDING_VERIFY_EMAIL_ROUTE,
  routes.ONBOARDING_CONSENT_ROUTE,
  routes.ONBOARDING_WELCOME_ROUTE,
];

const getSteps = (trajectory) => [
  {
    id: 'account',
    label: 'Account',
    pages:
      trajectory === trajectories.CREATION
        ? createAccountPagesTrajectory
        : trajectory === trajectories.CREATION_TEACHER
        ? createAccountTeacherPagesTrajectory
        : loginPagesTrajectory,
  },
  {
    id: 'connect',
    label: 'Connect',
    pages: [
      routes.ONBOARDING_DOWNLOAD_ROUTE,
      routes.ONBOARDING_CONNECTION_CODE_ROUTE,
    ],
  },
  {
    id: 'get-games',
    label: 'Get Games',
    pages: [],
  },
  {
    id: 'child-profile',
    label: 'Child Profile',
    pages: [],
  },
  {
    id: 'hardware',
    label: 'Hardware',
    pages: [],
  },
];

export default getSteps;
