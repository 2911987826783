"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkVerificationCode = exports.checkEmail = void 0;
var regex_1 = require("../constants/regex");
var checkEmail = function (email) {
    var isEmailValid = regex_1.emailRegEx.test(email);
    return {
        isValid: isEmailValid,
        errorMessage: isEmailValid ? '' : 'Email is not valid',
    };
};
exports.checkEmail = checkEmail;
var checkVerificationCode = function (verificationCode) {
    var isVerificationCodeValid = regex_1.verificationCodeRegEx.test(verificationCode);
    return {
        isValid: isVerificationCodeValid,
        errorMessage: isVerificationCodeValid ? '' : 'Verification code is not valid',
    };
};
exports.checkVerificationCode = checkVerificationCode;
