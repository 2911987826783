import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Title from 'src/components/texts/Title';

import './style.scss';

/**
 *  This component is the main header
 *  with red title
 */

const MainHeader = React.memo((props) => {
  const { title, className } = props;

  if (!title) {
    return null;
  }

  return (
    <div className={classNames('main-header', className)}>
      <Title text={title} />
    </div>
  );
});

MainHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MainHeader;
