import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { gamesData } from 'src/data/games';

import './style.scss';

/**
 *  Game item that includes icon and game name
 */

const GameItem = React.memo((props) => {
  const { game, className, isLarge, isInverted } = props;

  const gameData = gamesData[game];

  if (!gameData) {
    return null;
  }

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  const gameItemClassNames = classNames('game-item', className, {
    'game-item--large': isLarge,
    'game-item--inverted': isInverted,
  });

  return (
    <div className={gameItemClassNames}>
      {gameData.icon && (
        <img
          className="game-item__icon"
          src={gameData.icon.src}
          alt={gameData.icon.alt}
        />
      )}
      {gameData.name && <div className="game-item__name">{gameData.name}</div>}
    </div>
  );
});

GameItem.propTypes = {
  game: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  isInverted: PropTypes.bool,
};

export default GameItem;
