import { createSelector } from 'reselect';

export const getOnboardingObjectSelector = (state) => state.onboarding || {};

// Get trajectory
export const getOnboardingTrajectorySelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.trajectory || '';
  },
);

// Get product
export const getOnboardingProductSelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.product || '';
  },
);

// // ===========================================================================
// // ============================= USER ========================================
// // ===========================================================================

// Get user
export const getOnboardingUserSelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.user || {};
  },
);

// Get user id
export const getOnboardingUserIdSelector = createSelector(
  getOnboardingUserSelector,
  (user) => {
    return user.user_id || '';
  },
);

// // ===========================================================================
// // ===================== TMP ACCOUNT INFORMATION =============================
// // ===========================================================================

// Get onboarding code object
export const getTmpAccountInformationSelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.tmpAccountInformation || '';
  },
);

// // ===========================================================================
// // ============================== OTHER ======================================
// // ===========================================================================

// Get redeem API call error
export const getRedeemErrorSelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.redeemError || '';
  },
);

// Get downloaded games
export const getDownloadedGamesSelector = createSelector(
  getOnboardingObjectSelector,
  (onboarding) => {
    return onboarding.downloadedGames || '';
  },
);
