import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';

import withLoading from 'src/components/hoc/withLoading';
import withModal from 'src/components/hoc/withModal';
import OsmoInput from 'src/components/inputs/OsmoInput';
import OsmoButton from 'src/components/buttons/OsmoButton';
import ErrorMessage from 'src/components/messages/ErrorMessage';

import { modalTypes } from 'src/data/modals';

import { ENTER_EMAIL_ROUTE } from 'src/router/redeem/routes';

import './style.scss';

const RedeemCodeForm = (props) => {
  const {
    redeemCodeFromUrl,
    dispatch,
    setLoadingOn,
    setLoadingOff,
    isLoading,
    showModal,
    hideModal,
  } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== STATES =====================================
  // ===========================================================================

  const [redeemCode, setRedeemCode] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (redeemCodeFromUrl) {
      setRedeemCode(redeemCodeFromUrl);
    }
  }, [redeemCodeFromUrl]);

  // ===========================================================================
  // ============================== ERRORS =====================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // ===========================================================================
  // ============================= HANDLERS ====================================
  // ===========================================================================

  function _handleRedeemCodeChange(e) {
    setErrors({ ...errors, redeemCode: null });
    setRedeemCode(e.target.value);
  }

  function _onSubmit(e) {
    if (e) {
      // If the form has been submitted by pressing the enter key
      // Prevent the default refresh behavior
      e.preventDefault();
    }

    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Reset errors state
    _resetErrors();

    if (!redeemCode) {
      setErrors({
        ...errors,
        redeemCode: { message: 'Please enter your redemption code' },
      });

      return;
    }

    // Set loading on
    setLoadingOn();

    const redeemCodeWithoutHypen = redeemCode.replace(/-|\s/g, '');

    dispatch({
      type: actions.redeem.SEND_REDEEM_CODE_TO_SERVER_SAGA,
      payload: {
        params: {
          redeemCode: redeemCodeWithoutHypen,
        },
        callback: (isRedeemed) => {
          setLoadingOff();

          // Open a modal that warn to keep code safe
          // or to confirm override
          const onCancel = () => hideModal();
          const onContinue = () => {
            hideModal();
            history.push(ENTER_EMAIL_ROUTE);
          };

          const modalShown = isRedeemed
            ? modalTypes.CODE_ALREADY_USED
            : modalTypes.KEEP_YOUR_CODE;

          showModal(modalShown, {
            onContinue,
            onCancel,
          });
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            status: { message },
          });
        },
      },
    });
  }

  const redeemMask = '****-****-*****';

  return (
    <form className="redeem-code-form" onSubmit={_onSubmit}>
      <OsmoInput
        placeHolder="#### – #### – ####"
        mask={redeemMask}
        disableAnimation={true}
        onChange={_handleRedeemCodeChange}
        value={redeemCode}
        error={errors.redeemCode}
      />

      {errors?.status && (
        <div className="redeem-code-form__message">
          <ErrorMessage message={errors?.status?.message} />
        </div>
      )}

      <div className="redeem-code-form__button">
        <OsmoButton text={'Redeem'} onClick={_onSubmit} />
      </div>
    </form>
  );
};

export default compose(withLoading, withModal)(RedeemCodeForm);
