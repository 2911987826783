import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translate } from 'src/i18n';

import getEmailStatus from 'src/apis/utils/getEmailStatus';

import * as selectors from 'src/selectors';
import * as actions from 'src/actions';

import { ONBOARDING_VERIFY_EMAIL_ROUTE } from 'src/router/onboarding/routes';

import EmailForm from 'src/modules/EmailForm';
import withLoading from 'src/components/hoc/withLoading';
import MainHeader from 'src/components/headers/MainHeader';
import ErrorMessage from 'src/components/messages/ErrorMessage';

import { views } from '../../Account';

import './style.scss';

const LogIn = (props) => {
  const {
    setLoadingOn,
    setLoadingOff,
    updateAccountView,
    // From Redux
    tmpAccountInformation,
    dispatch,
  } = props;
  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState(null);
  const history = useHistory();

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  const _onSignUpClick = () => {
    updateAccountView(views.SIGNUP);
  };

  const _onSubmit = ({ email }) => {
    const sendEmail = () => {
      dispatch({
        type: actions.onboarding.SEND_EMAIL_TO_SERVER_SAGA,
        payload: {
          params: {
            email,
          },
          callback: () => {
            setLoadingOff();
            history.push(ONBOARDING_VERIFY_EMAIL_ROUTE);
          },
          errorCallback: (message) => {
            setLoadingOff();

            setErrors({
              status: { message },
            });
          },
        },
      });
    };

    // Set loading on
    setLoadingOn();

    // Check wether email exists or not
    getEmailStatus({
      email,
      existCallback: () => {
        sendEmail();
      },
      notFoundCallback: () => {
        setLoadingOff();
        updateAccountView(views.NEED_SIGNUP);
      },
      errorCallback: (res) => {
        setGlobalError(res?.message);
        setLoadingOff();
      },
    });

    dispatch({
      type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          email,
        },
      },
    });

    dispatch({
      type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          email,
        },
      },
    });
  };

  return (
    <div className="onboarding-log-in">
      <MainHeader title={translate('onboarding.logIn.title')} />
      <EmailForm
        onSubmit={_onSubmit}
        submitButtonLabel={translate('general.next')}
        errors={errors}
        initialEmailValue={tmpAccountInformation?.email}
      />
      {globalError?.length && (
        <div className="onboarding-account__message">
          <ErrorMessage message={globalError} />
        </div>
      )}
      <p className="onboarding-account__link">
        {translate('onboarding.logIn.goBack')}
        <button className="onboarding-link" onClick={_onSignUpClick}>
          {translate('onboarding.logIn.accountCreation')}
        </button>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tmpAccountInformation: selectors.onboarding.getTmpAccountInformationSelector(
    state,
  ),
});

export default connect(mapStateToProps)(withLoading(LogIn));
