import React from 'react';
import { connect } from 'react-redux';

import { translate } from 'src/i18n';

import * as selectors from 'src/selectors';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import OsmoButton from 'src/components/buttons/OsmoButton';
import MainHeader from 'src/components/headers/MainHeader';

import { views } from '../../Account';

import './style.scss';

const NeedSignUp = (props) => {
  const { updateAccountView, tmpAccountInformation } = props;

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  const _onSignUpClick = () => {
    updateAccountView(views.SIGNUP);
  };
  const _onLogInClick = () => {
    updateAccountView(views.LOGIN);
  };

  return (
    <div className="onboarding-need-signup">
      <Container>
        <CenterContent>
          <MainHeader title={translate('onboarding.needSignUp.title')} />
          <p className="onboarding-need-signup-subtitle">
            {translate('onboarding.needSignUp.content', {
              email: tmpAccountInformation.email,
            })}
          </p>
          <OsmoButton
            text={translate('onboarding.needSignUp.createAccount')}
            onClick={_onSignUpClick}
            className="onboarding-need-signup-button"
          />
          <p className="onboarding-need-signup-retry">
            {translate('onboarding.needSignUp.logIn')}
            <button className="onboarding-link" onClick={_onLogInClick}>
              {translate('onboarding.needSignUp.logInButton')}
            </button>
            .
          </p>
        </CenterContent>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tmpAccountInformation: selectors.onboarding.getTmpAccountInformationSelector(
    state,
  ),
});

export default connect(mapStateToProps)(NeedSignUp);
