import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as selectors from 'src/selectors';
import { translate } from 'src/i18n';

import {
  ONBOARDING_ACCOUNT_ROUTE,
  // ONBOARDING_CHILD_WAIT_ROUTE,
} from 'src/router/onboarding/routes';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import Title from 'src/components/texts/Title';
import OsmoButton from 'src/components/buttons/OsmoButton';
import OnboardingStep from './components/OnboardingStep';

import CheckmarkIcon from 'src/assets/images/onboarding/step-icons/checkmark.svg';

import './style.scss';

const steps = [
  {
    icon: CheckmarkIcon,
    text: translate('onboarding.overview.login'),
    subText: translate('onboarding.overview.subLogin'),
  },
  {
    icon: CheckmarkIcon,
    text: translate('onboarding.overview.connect'),
    subText: translate('onboarding.overview.subConnect'),
  },
  {
    icon: CheckmarkIcon,
    text: translate('onboarding.overview.download'),
    subText: translate('onboarding.overview.subDownload'),
  },
  {
    icon: CheckmarkIcon,
    text: translate('onboarding.overview.profile'),
    subText: translate('onboarding.overview.subProfile'),
  },
];

const SetupOverview = ({ product }) => {
  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  const _onClick = () => {
    history.push(ONBOARDING_ACCOUNT_ROUTE);
    // const hasActivity = product.hasActivityTray;
    // history.push(
    //   hasActivity ? ONBOARDING_CHILD_WAIT_ROUTE : ONBOARDING_ACCOUNT_ROUTE,
    // );
  };

  return (
    <div className="onboarding-setup-overview">
      <Container>
        <CenterContent>
          <Title text={translate('onboarding.overview.title')} isDark={true} />
          <div className="onboarding-setup-overview__steps">
            {steps.map((step, i) => (
              <OnboardingStep
                key={i}
                icon={step.icon}
                text={step.text}
                subText={step.subText}
              />
            ))}
          </div>
          <OsmoButton text={translate('general.letsGo')} onClick={_onClick} />
        </CenterContent>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: selectors.onboarding.getOnboardingProductSelector(state),
});

export default connect(mapStateToProps)(SetupOverview);
