import { select, takeLatest, call } from 'redux-saga/effects';

import { store } from 'src/App';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import { report } from 'src/services/errors';

import {
  getEmailLoginCode,
  confirmEmailLoginCode,
  createAccount,
} from 'src/apis/login';

import { updateUser } from 'src/apis/user';

import { getRedeemCodeStatus, redeemCodeForUser } from 'src/apis/redeem';

import {
  ACCOUNT_INFORMATION_ROUTE,
  SELECT_DEVICE_ROUTE,
  CONTINUE_SETUP_ROUTE,
} from 'src/router/redeem/routes';

// ===========================================================================
// ================================ REDEEM ===================================
// ===========================================================================

function* sendRedeemCode(action) {
  const { params, callback, errorCallback } = action.payload;

  const { redeemCode } = params;

  try {
    const response = yield call(getRedeemCodeStatus, { code: redeemCode });

    // Check if request has failed
    if (!response) {
      if (errorCallback) {
        const message = `Can't redeem code`;

        errorCallback(message);
      }

      return;
    }

    // Check if code is valid
    if (!response.valid) {
      if (errorCallback) {
        const message = `Oops! That didn’t work, did you make a typo?`;

        errorCallback(message);
      }

      return;
    }

    // Check if code hasn't been redeemed
    const isRedeemed = response.redeemed;

    store.dispatch({
      type: actions.redeem.SET_REDEEM_CODE,
      payload: {
        code: params.redeemCode,
        devices: response.devices,
        product: response.product,
        digitalAppsOnly: response.digital_apps_only,
        apps: response.apps,
        isRedeemed,
      },
    });

    if (callback) {
      callback(isRedeemed);
    }
  } catch (e) {
    report(e);
  }
}

function* sendEmail(action) {
  const { params, callback, errorCallback } = action.payload;
  const { email } = params;

  try {
    const response = yield call(getEmailLoginCode, {
      email,
    });

    // Check if response returns code was sent
    if (!response || !response.code_sent) {
      if (errorCallback) {
        if (!response.message) {
          report(
            `Error while send redeem login request (${email}): ${JSON.stringify(
              response,
            )}`,
            response,
          );
        }
        errorCallback(response.message || 'Login request failed');
      }

      return;
    }

    // Store email into redux
    store.dispatch({
      type: actions.redeem.SET_EMAIL,
      payload: { email: params.email },
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    report(`Error in redeem login process (${email}): ${JSON.stringify(e)}`, e);
    if (errorCallback) {
      errorCallback('Login request failed');
    }
  }
}

function* sendVerificationCode(action) {
  const { params, callback, errorCallback } = action.payload;
  const { verificationCode } = params;

  const email = yield select(selectors.redeem.getRedeemEmailSelector);

  try {
    const response = yield call(confirmEmailLoginCode, {
      email,
      code: verificationCode,
    });

    if (!response || response.users === undefined) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work, did you make a typo?';

        errorCallback(message);
      }
      return;
    }

    // If user already exists then store it into redux
    // then redirect to referrals survey
    if (response.users && response.users[0]) {
      const user = response.users[0];

      // Store user
      store.dispatch({
        type: actions.redeem.SET_USER,
        payload: {
          user,
        },
      });

      // If user was already existing then just redeem the code for user
      const redeemCode = yield select(selectors.redeem.getRedeemCodeSelector);
      const isAlreadyRedemeed = yield select(
        selectors.redeem.getIsAlreadyRedeemedCode,
      );

      const redeemCodeResponse = yield call(redeemCodeForUser, {
        code: redeemCode,
        override: isAlreadyRedemeed,
      });

      if (
        !redeemCodeResponse ||
        !redeemCodeResponse.valid ||
        !redeemCodeResponse.redeemed
      ) {
        if (errorCallback) {
          const message = 'Oops! That didn’t work, did you make a typo?';

          errorCallback(message);
        }
      }

      const hasMultipleDevices = yield select(
        selectors.redeem.getHasMultipleDevicesSelector,
      );

      // Then redirect to continue setup page
      if (callback) {
        // Check if there's multiple devices
        // If yes then redirect to a screen to pick a defined device
        const nextRoute = hasMultipleDevices
          ? SELECT_DEVICE_ROUTE
          : CONTINUE_SETUP_ROUTE;
        callback({ nextRoute });
      }
    } else if (response.email_verification_id) {
      // If user doesn't exist then store email_verification_id
      // then redirect to account creation

      // Store email_verification_id
      store.dispatch({
        type: actions.redeem.SET_EMAIL_VERIFICATION_ID,
        payload: {
          emailVerificationId: response.email_verification_id,
        },
      });

      const isOnlyDigital = yield select(
        selectors.redeem.getIsOnlyDigitalAppsSelector,
      );

      // Redirect to account creation
      if (callback) {
        callback({
          nextRoute: ACCOUNT_INFORMATION_ROUTE,
          shouldDisplayWarnModal: isOnlyDigital,
        });
      }
    }
  } catch (e) {
    report(e);

    if (errorCallback) {
      const message = 'Oops! That didn’t work, did you make a typo?';

      errorCallback(message);
    }
  }
}

function* createAccountRequest(action) {
  const {
    callback,
    errorCallback,
    params: { isSubscribingToEmails },
  } = action.payload;

  const email = yield select(selectors.redeem.getRedeemEmailSelector);
  const emailVerificationId = yield select(
    selectors.redeem.getRedeemEmailVerificationIdSelector,
  );
  const tmpAccountInfo = yield select(
    selectors.redeem.getTmpAccountInformationSelector,
  );

  try {
    const response = yield call(createAccount, {
      email,
      emailVerificationId,
      firstName: tmpAccountInfo.firstName,
      lastName: tmpAccountInfo.lastName,
      isSubscribingToEmails,
    });

    if (!response || !response.users || !response.users[0]) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work.';

        errorCallback(message);
      }
      return;
    }

    const user = response.users[0];

    // Store user
    store.dispatch({
      type: actions.redeem.SET_USER,
      payload: {
        user,
      },
    });

    // After account creation, we redeem code for that user
    const redeemCode = yield select(selectors.redeem.getRedeemCodeSelector);
    const isAlreadyRedemeed = yield select(
      selectors.redeem.getIsAlreadyRedeemedCode,
    );

    const redeemCodeResponse = yield call(redeemCodeForUser, {
      code: redeemCode,
      override: isAlreadyRedemeed,
    });

    if (
      !redeemCodeResponse ||
      !redeemCodeResponse.valid ||
      !redeemCodeResponse.redeemed
    ) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work.';

        errorCallback(message);
      }
    }

    // Send teacher referral survey answers if any
    if (tmpAccountInfo.schoolUsages) {
      store.dispatch({
        type: actions.redeem.UPDATE_SCHOOL_USAGES_SAGA,
        payload: {
          params: {
            schoolUsages: tmpAccountInfo.schoolUsages,
          },
        },
      });
    }

    const hasMultipleDevices = yield select(
      selectors.redeem.getHasMultipleDevicesSelector,
    );

    if (callback) {
      // Check if there's multiple devices
      // If yes then redirect to a screen to pick a defined device
      const nextRoute = hasMultipleDevices
        ? SELECT_DEVICE_ROUTE
        : CONTINUE_SETUP_ROUTE;
      callback(nextRoute);
    }
  } catch (e) {
    report(e);

    if (errorCallback) {
      const message = 'Oops! That didn’t work.';

      errorCallback(message);
    }
  }
}

function* sendSchoolUsages(action) {
  const { params, callback, errorCallback } = action.payload;
  const { schoolUsages } = params;

  const userId = yield select(selectors.redeem.getRedeemUserIdSelector);

  try {
    const response = yield call(updateUser, {
      userId,
      payload: {
        schoolSettings: {
          schoolUsage: schoolUsages,
          updateSchoolUsage: true,
        },
      },
    });

    if (!response || !response.users || !response.users[0]) {
      if (errorCallback) {
        const message = 'School usages update failed';

        errorCallback(message);
      }
      return;
    }

    const user = response.users[0];

    // Store user
    store.dispatch({
      type: actions.redeem.SET_USER,
      payload: {
        user,
      },
    });

    // Redirect to continue screen
    if (callback) {
      callback();
    }
  } catch (e) {
    report(e);
  }
}

export const redeemSagas = [
  takeLatest(actions.redeem.SEND_REDEEM_CODE_TO_SERVER_SAGA, sendRedeemCode),
  takeLatest(actions.redeem.SEND_EMAIL_TO_SERVER_SAGA, sendEmail),
  takeLatest(
    actions.redeem.SEND_VERIFICATION_CODE_TO_SERVER_SAGA,
    sendVerificationCode,
  ),
  takeLatest(actions.redeem.CREATE_ACCOUNT_SAGA, createAccountRequest),
  takeLatest(actions.redeem.UPDATE_SCHOOL_USAGES_SAGA, sendSchoolUsages),
];
