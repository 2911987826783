export function isEmpty(o) {
  for (let key in o) {
    if (o.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export const checkObjEquality = (prevData, nextData, skipKeys) => {
  let match = true;

  for (const key in prevData) {
    if (skipKeys.includes(key)) {
      break;
    }

    if (
      prevData[key] === undefined ||
      nextData[key] === undefined ||
      prevData[key] !== nextData[key]
    ) {
      match = false;
      break;
    }
  }

  return match;
};
