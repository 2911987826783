import React, { useEffect, useState } from 'react';

import { translate } from 'src/i18n';

import withLoading from 'src/components/hoc/withLoading';
import OsmoInput from 'src/components/inputs/OsmoInput';
import OsmoButton from 'src/components/buttons/OsmoButton';
import ErrorMessage from 'src/components/messages/ErrorMessage';

import { validateEmail } from 'src/utils/validations';

import './style.scss';

const EmailForm = (props) => {
  const {
    isLoading,
    onSubmit,
    errors: errorsFromParent = {},
    submitButtonLabel,
    initialEmailValue = '',
  } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [email, setEmail] = useState(initialEmailValue);
  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    setErrors({
      ...errors,
      ...errorsFromParent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsFromParent]);

  // ===========================================================================
  // ============================ ERRORS =====================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _handleEmailChange(event) {
    // If error state, on email change remove it.
    setErrors({ ...errors, email: null });

    setEmail(event.target.value);
  }

  function _onKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      _onSubmit();
    }
  }

  function _onSubmit() {
    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Remove all errors to update to new ones
    _resetErrors();

    // Set error email is required
    if (!email) {
      setErrors({
        email: { message: translate('general.emailRequiredError') },
      });

      return;
    }

    // Set email is not valid
    if (!validateEmail(email)) {
      setErrors({
        email: { message: translate('general.invalidEmailError') },
      });

      return;
    }

    onSubmit({ email });
  }

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  function _isFormValid() {
    return validateEmail(email);
  }

  return (
    <div className="email-form" onSubmit={_onSubmit}>
      <OsmoInput
        className="email-form__input"
        placeHolder={translate('general.email')}
        onChange={_handleEmailChange}
        value={email}
        error={errors.email}
        onKeyDown={_onKeyDown}
        type="email"
      />

      {errors?.status && (
        <div className="email-form__message">
          <ErrorMessage message={errors?.status?.message} />
        </div>
      )}

      <div className="email-form__button">
        <OsmoButton
          text={submitButtonLabel || translate('loginEmailForm.title')}
          onClick={_onSubmit}
          isDisabled={!_isFormValid()}
        />
      </div>
    </div>
  );
};

export default withLoading(EmailForm);
