import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

/**
 *  This component is used to vertically and horizontally
 *  align children node into parent element
 */

const CenterContent = React.memo((props) => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  return (
    <div className={classNames('center-content', className)}>{children}</div>
  );
});

CenterContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CenterContent;
