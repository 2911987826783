import React from 'react';
import PropTypes from 'prop-types';

import OsmoButton from 'src/components/buttons/OsmoButton';

import './style.scss';

const CodeAlreadyUsedModal = (props) => {
  const { onContinue, onCancel } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleCancelClick() {
    if (onCancel) {
      onCancel();
    }
  }

  function _handleContinueClick() {
    if (onContinue) {
      onContinue();
    }
  }

  return (
    <div className="warning-new-account-modal">
      <div className="warning-new-account-modal__title">
        {`You're about to create a new account`}
      </div>
      <p className="warning-new-account-modal__text">
        {
          'The email you entered does not belong to any account yet. If you meant to log in to an existing account, you can try again.'
        }
      </p>
      <div className="warning-new-account-modal__buttons">
        <OsmoButton
          text={'Try a Different Email'}
          onClick={_handleCancelClick}
          isInverted
        />
        <OsmoButton
          text={'Create New Account'}
          onClick={_handleContinueClick}
        />
      </div>
    </div>
  );
};

CodeAlreadyUsedModal.propTypes = {
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CodeAlreadyUsedModal;
