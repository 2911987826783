import { fetchOsmoServer } from 'src/apis/helpers/fetch';

export async function getRedeemCodeStatus(params) {
  const { code } = params;

  const response = await fetchOsmoServer({
    endpoint: `/api/v4/users/apps/redeem?code=${code}`,
    method: 'GET',
  });

  return response;
}

export async function redeemCodeForUser(params) {
  const { code, override } = params;

  const response = await fetchOsmoServer({
    endpoint: '/api/v4/users/apps/redeem',
    method: 'POST',
    body: {
      code,
      override,
    },
  });

  return response;
}
