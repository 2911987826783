import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ErrorMessage from 'src/components/messages/ErrorMessage';

import './style.scss';

/**
 *  Custom Input
 */

const OsmoInput = (props) => {
  const {
    className,
    placeHolder,
    value,
    error,
    mask,
    disableAnimation,
    ...otherProps
  } = props;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [isFocused, setIsFocused] = useState(false);

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onFocus() {
    setIsFocused(true);
  }

  function _onBlur() {
    setIsFocused(false);
  }

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  function _getPlaceHolder() {
    // If hoist animation is disabled then use native placeholder
    return placeHolder && disableAnimation ? placeHolder : null;
  }

  const osmoInputClassNames = classNames('osmo-input', className, {
    // Active state if focused or has value
    'osmo-input--active': value,
    'osmo-input--focused': isFocused,
    'osmo-input--error': error,
  });

  return (
    <div className={osmoInputClassNames}>
      <div className="osmo-input__inner">
        <div className="osmo-input__input-holder">
          {mask ? (
            <InputMask
              className="osmo-input__input"
              {...otherProps}
              mask={mask}
              maskPlaceholder={null}
              onFocus={_onFocus}
              onBlur={_onBlur}
              value={value}
              placeholder={_getPlaceHolder()}
            />
          ) : (
            <input
              className="osmo-input__input"
              {...otherProps}
              onFocus={_onFocus}
              onBlur={_onBlur}
              value={value}
              placeholder={_getPlaceHolder()}
            />
          )}
        </div>
        {!disableAnimation && (
          <div className="osmo-input__label">
            <span>{placeHolder}</span>
          </div>
        )}
      </div>
      {error && (
        <div className="osmo-input__error">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </div>
  );
};

OsmoInput.propTypes = {
  placeHolder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.object,
  mask: PropTypes.string,
  disableAnimation: PropTypes.bool,
};

export default OsmoInput;
