import React from 'react';

import Container from 'src/components/displays/Container';

import { translate } from 'src/i18n';

import './style.scss';

/**
 *  This component is the footer
 *  of the whole application
 */

const OnboardingFooter = React.memo(() => {
  return (
    <div className="onboarding-footer">
      <Container>
        <nav className="onboarding-footer__nav">
          <span className="onboarding-footer__nav__title">
            {translate('onboarding.home.resources')}
          </span>
          <a
            className="onboarding-footer__link"
            href="https://playosmo.com/devices"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('onboarding.home.devices')}
          </a>
          <a
            className="onboarding-footer__link"
            href="https://www.playosmo.com/en/gamemanuals/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('onboarding.home.manuals')}
          </a>
          <a
            className="onboarding-footer__link"
            href="https://support.playosmo.com/hc/en-us?referrer=onboarding-v3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('onboarding.home.support')}
          </a>
        </nav>
        <div className="onboarding-footer__notice">
          <p>{'©2021 Tangible Play, Inc. All rights reserved.'}</p>
        </div>
      </Container>
    </div>
  );
});

export default OnboardingFooter;
