import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from 'src/components/icons/ErrorIcon';

import './styles.scss';

/**
 *  Error icon
 */

const ErrorMessage = React.memo((props) => {
  const { message } = props;

  return (
    <div className="error-message">
      <ErrorIcon />
      <span className="error-message__message">{message}</span>
    </div>
  );
});

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
