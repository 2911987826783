import {
  IS_LOCAL,
  IS_RELC,
  IS_DEVELOP,
  IS_BETA_PARENT,
} from 'src/config/environment';

export const ENVIRONMENT_API_URL = 'https://my.playosmo.com';
export const ENVIRONMENT_BETA_PARENT_API_URL =
  'https://beta-parent-dot-tangibleplay-ugc.appspot.com';
export const ENVIRONMENT_DEVELOP_API_URL =
  'https://develop.dev.my.playosmo.com';
export const ENVIRONMENT_RELC_API_URL =
  'https://relc-dot-tangibleplay-ugc.appspot.com';
export const ENVIRONMENT_LOCAL_API_URL = 'http://localhost:8080';

export function getApiUrl() {
  if (IS_LOCAL) {
    return ENVIRONMENT_LOCAL_API_URL;
  }

  if (IS_DEVELOP) {
    return ENVIRONMENT_DEVELOP_API_URL;
  }

  if (IS_RELC) {
    return ENVIRONMENT_RELC_API_URL;
  }

  if (IS_BETA_PARENT) {
    return ENVIRONMENT_BETA_PARENT_API_URL;
  }

  return ENVIRONMENT_API_URL;
}
