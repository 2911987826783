import React from 'react';
import { connect } from 'react-redux';
import { useQueryParam } from 'use-query-params';

import Container from 'src/components/displays/Container';
import RedeemCodeMain from './components/RedeemCodeMain';

import RedeemCodeIlluSrc from 'src/assets/images/illustrations/illustration-redeem-code.png';

import './style.scss';

const RedeemCode = (props) => {
  const { dispatch } = props;

  // The redemption email links to this page with a query param
  // Example: ?code=ABCDEFGHIJKL
  const [redeemCodeFromUrl] = useQueryParam('code');

  return (
    <div className="redeem-page">
      <Container>
        <div className="redeem-page__container">
          <img
            src={RedeemCodeIlluSrc}
            className="redeem-page__illustration"
            alt="Redeem code"
          />
          <div className="redeem-page__main">
            <RedeemCodeMain
              redeemCodeFromUrl={redeemCodeFromUrl}
              dispatch={dispatch}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(RedeemCode);
