import React from 'react';
import { useHistory } from 'react-router-dom';

import { getProductInstructionsRoute } from 'src/router/redeemFaq/routes';

import './style.scss';

const ProductBlock = (props) => {
  const { product } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onClick() {
    const route = getProductInstructionsRoute(product.id);

    if (route) {
      history.push(route);
    }
  }

  if (!product || !product.title) {
    return null;
  }

  return (
    <div className="product-block" onClick={_onClick}>
      <div className="product-block__container">
        {product.image && (
          <img
            className="product-block__image"
            src={product.image.src}
            alt={product.image.alt}
          />
        )}
        <p className="product-block__name">{product.title}</p>
      </div>
    </div>
  );
};

export default ProductBlock;
