import React from 'react';
import { ExitIntentPopupClearanceSaleContent } from '@tangibleplay/osmo-core-ui';
import './style.scss';

import {
  heading,
  subHeading,
  offerTextSubHeading,
  conditionsText,
  primaryBtnText,
  offerTextOsmo,
} from './PopupContent';
const ExitIntentPopup = ({
  showModal,
  setShowModal,
  setEmailsAreChecked,
  onNext,
}) => {
  const optinBtnClick = () => {
    setShowModal(false);
    setEmailsAreChecked(true);
  };
  const skipBtnClick = () => {
    setShowModal(false);
    onNext();
  };
  return (
    <div className={showModal ? 'modal show-modal' : 'modal'}>
      <div id="exit-intent-popup" className="exit-intent modal-content">
        <a className="close" onClick={() => setShowModal(false)}>
          &times;
        </a>
        <ExitIntentPopupClearanceSaleContent
          handlePrimaryButtonClick={skipBtnClick}
          handleSecondaryButtonClick={optinBtnClick}
          heading={heading}
          subHeading={subHeading}
          offerTextInfoOsmo={offerTextOsmo}
          offerTextSubHeading={offerTextSubHeading}
          buttonText={primaryBtnText}
          secondaryButtonText={'Yes, opt in'}
          offerConditionstext={conditionsText}
        />
      </div>
    </div>
  );
};

export default ExitIntentPopup;
