import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RedeemCode from 'src/pages/redeem/RedeemCode';
import EnterEmail from 'src/pages/redeem/EnterEmail';
import VerifyEmail from 'src/pages/redeem/VerifyEmail';
import AccountInformation from 'src/pages/redeem/AccountInformation';
import Consent from 'src/pages/redeem/Consent';
import TeacherReferralSurvey from 'src/pages/redeem/TeacherReferralSurvey';
import SelectDevice from 'src/pages/redeem/SelectDevice';
import ContinueSetup from 'src/pages/redeem/ContinueSetup';

import * as routes from './routes';

const RedeemRoutes = () => {
  return (
    <Switch>
      <Route exact path={routes.REDEEM_CODE_ROUTE} component={RedeemCode} />

      <Route exact path={routes.ENTER_EMAIL_ROUTE} component={EnterEmail} />

      <Route exact path={routes.VERIFY_EMAIL_ROUTE} component={VerifyEmail} />

      <Route
        exact
        path={routes.ACCOUNT_INFORMATION_ROUTE}
        component={AccountInformation}
      />

      <Route
        exact
        path={routes.REFERRAL_TEACHER_SURVEY_ROUTE}
        component={TeacherReferralSurvey}
      />

      <Route exact path={routes.SELECT_DEVICE_ROUTE} component={SelectDevice} />

      <Route exact path={routes.CONSENT_ROUTE} component={Consent} />

      <Route
        exact
        path={routes.CONTINUE_SETUP_ROUTE}
        component={ContinueSetup}
      />
    </Switch>
  );
};

export default RedeemRoutes;
