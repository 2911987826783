// fetch polyfill for unsupported browsers.
import 'whatwg-fetch';

export async function triggerSurveyWebhookZapier(params) {
  const response = await fetch(
    'https://hooks.zapier.com/hooks/catch/1612501/oqo58if/',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
  );

  return response.json();
}
