import React from 'react';

import SiteHeader from 'src/components/headers/SiteHeader';
import SiteFooter from 'src/components/footers/SiteFooter';

import MathWizardIconSrc from 'src/assets/images/games/math-wizard/math-wizard-icon@2x.png';
import MathWizardLogoSrc from 'src/assets/images/games/math-wizard/not-released/math-wizard-logo-fx@2x.png';
import PlayingMathWizardImageSrc from 'src/assets/images/games/math-wizard/not-released/playing-math-wizard@2x.png';
import MathWizardIconAndIlluSrc from 'src/assets/images/games/math-wizard/not-released/math-wizard-icon-and-illustration@2x.png';

import './style.scss';

const GetMathWizardNotReleased = () => {
  return (
    <div className="app-container">
      <SiteHeader />

      <div className="get-math-wizard-not-released-page">
        <div className="get-math-wizard-not-released-page__content">
          <img
            className="get-math-wizard-not-released-page__icon"
            src={MathWizardIconSrc}
            alt="Math Wizard Icon"
          />
          <img
            className="get-math-wizard-not-released-page__logo"
            src={MathWizardLogoSrc}
            alt="Math Wizard Logo"
          />
          <div className="get-math-wizard-not-released-page__texts">
            <h2 className="get-math-wizard-not-released-page__title">
              {'Learn through hands-on play!'}
            </h2>
            <p className="get-math-wizard-not-released-page__available-on">
              {'Available for Amazon Fire Tablets on '}
              <strong>{'11/01/2020'}</strong>
            </p>
          </div>
        </div>
        <div className="get-math-wizard-not-released-page__image-container">
          <img
            className="get-math-wizard-not-released-page__image get-math-wizard-not-released-page__image--mobile"
            src={MathWizardIconAndIlluSrc}
            alt="Math Wizard Icon and Illustration"
          />
          <img
            className="get-math-wizard-not-released-page__image"
            src={PlayingMathWizardImageSrc}
            alt="Playing Math Wizard"
          />
        </div>
      </div>

      <SiteFooter />
    </div>
  );
};

export default GetMathWizardNotReleased;
