import React from 'react';
import { useParams } from 'react-router-dom';

import Container from 'src/components/displays/Container';
import Breadcrumb from 'src/components/general/Breadcrumb';
import Title from 'src/components/texts/Title';
import ProductInstructionsCarrousel from './components/ProductInstructionsCarrousel';

import { getNavigationElements } from 'src/data/redeem-faq/navigation';
import { productInstructionsData } from 'src/data/redeem-faq/productInstructions';

import './style.scss';

const ProductInstructions = () => {
  const { product } = useParams();

  const navigationElements = getNavigationElements(product);

  const productInstructions = productInstructionsData[product];

  return (
    <div className="product-instructions-page">
      <Container>
        <Breadcrumb elements={navigationElements} />
        {productInstructions?.title && (
          <Title
            className="product-instructions-page__title"
            text={productInstructions.title}
            isDark={true}
          />
        )}

        {productInstructions?.description && (
          <div
            className="product-instructions-page__description"
            dangerouslySetInnerHTML={productInstructions.description}
          />
        )}

        {productInstructions?.content && (
          <ProductInstructionsCarrousel
            carrouselData={productInstructions.content}
          />
        )}
      </Container>
    </div>
  );
};

export default ProductInstructions;
