"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var currencySymbols = {
    AED: ['', 'د.إ'],
    AUD: ['A$'],
    BHD: ['', '.د.ب'],
    CAD: ['C$'],
    DKK: { DK: ['', '\x0akr'], '': null },
    EUR: { IE: ['€'], '': ['', '\x0a€'] },
    GBP: ['£'],
    HKD: ['HK$'],
    ILS: ['₪'],
    KW: ['', 'KD'],
    NOK: { NO: ['', '\x0akr'], '': null },
    KRW: ['₩'],
    NZD: ['NZ$'],
    OMR: ['', 'ر.ع.'],
    SAR: ['', 'ر.س'],
    SEK: { SE: ['', '\x0akr'], '': null },
    SGD: ['S$'],
    USD: { US: ['$'], '': ['US$'] },
};
var getCurrencySymbols = function (currency, country) {
    if (!currency) {
        return [];
    }
    var symbols = null;
    if (currencySymbols[currency]) {
        var thisCurrencySymbols = currencySymbols[currency];
        if (Array.isArray(thisCurrencySymbols)) {
            symbols = thisCurrencySymbols;
        }
        else {
            if (thisCurrencySymbols[country]) {
                symbols = thisCurrencySymbols[country];
            }
            else {
                symbols = thisCurrencySymbols[''];
            }
        }
    }
    if (!symbols) {
        symbols = ['', "\n".concat(currency)];
    }
    return symbols;
};
exports.default = getCurrencySymbols;
