export function isIpad() {
  return (
    window.navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
}

export function isSilk() {
  // Source : https://docs.aws.amazon.com/silk/latest/developerguide/detecting-silk-ua.html
  return /(?:; ([^;)]+) Build\/.*)?\bSilk\/([0-9._-]+)\b(.*\bMobile Safari\b)?/.exec(
    navigator.userAgent,
  );
}

export function isOldFireDevice() {
  return (
    window.navigator.userAgent.match(
      /KFSUWI|KFAUWI|KFDOWI|KFKAWI|KFMUWI|KFMAWI/i,
    ) !== null
  );
}
