import React from 'react';
import PropTypes from 'prop-types';

import OsmoButton from 'src/components/buttons/OsmoButton';

import './style.scss';

const CodeAlreadyUsedModal = (props) => {
  const { onContinue, onCancel } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleCancelClick() {
    if (onCancel) {
      onCancel();
    }
  }

  function _handleContinueClick() {
    if (onContinue) {
      onContinue();
    }
  }

  return (
    <div className="code-already-used-modal">
      <div className="code-already-used-modal__title">
        {'This redemption code has been used before on a different account.'}
      </div>
      <p className="code-already-used-modal__text">
        {
          'If you choose to continue, the digital games associated with this code will no longer be accessible on that previous account.'
        }
      </p>
      <div className="code-already-used-modal__buttons">
        <OsmoButton text={'Cancel'} onClick={_handleCancelClick} isInverted />
        <OsmoButton text={'Continue'} onClick={_handleContinueClick} />
      </div>
    </div>
  );
};

CodeAlreadyUsedModal.propTypes = {
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CodeAlreadyUsedModal;
