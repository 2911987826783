import React from 'react';

import './style.scss';

const OnboardingStep = ({ icon, text, subText }) => {
  return (
    <div className="onboarding-step">
      <div className="onboarding-step__icon">
        <img
          src={icon}
          alt=""
          className="onboarding-step__icon__img"
          width={40}
          height={40}
        />
      </div>
      <div className="onboarding-step__text">
        {text}
        <div className="onboarding-step__sub-text">{subText}</div>
      </div>
    </div>
  );
};

export default OnboardingStep;
