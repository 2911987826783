import { products } from '../products';

export default {
  // GSK
  startgenius: {
    name: products.GENIUS_SK,
  },
  startgeniusfire: {
    name: products.GENIUS_SK,
  },
  startgeniusplus: {
    name: products.GENIUS_SK_PLUS,
  },
  startgeniusplusfire: {
    name: products.GENIUS_SK_PLUS,
  },
  // Coding
  setupcoding: {
    name: products.CODING_SK,
  },
  // LGSK
  startlittle: {
    name: products.LITTLE_GENIUS_SK,
  },
  startlittlefire: {
    name: products.LITTLE_GENIUS_SK,
  },
  startlittleplus: {
    name: products.LITTLE_GENIUS_SK_PLUS,
  },
  startlittleplusfire: {
    name: products.LITTLE_GENIUS_SK_PLUS,
  },
  // Creative SK
  startcreative: {
    name: products.CREATIVE_SK,
  },
  startcreativefire: {
    name: products.CREATIVE_SK,
  },
  // Numbers
  startnumberskit: {
    name: products.NUMBERS_SK,
  },
  startnumberskitfire: {
    name: products.NUMBERS_SK,
  },
  startnumbers: {
    name: products.NUMBERS,
  },
  // Words
  startwordskit: {
    name: products.WORDS_SK,
  },
  startwordskitfire: {
    name: products.WORDS_SK,
  },
  startwords: {
    name: products.WORDS,
  },
  // Sticks and rings
  startstickskit: {
    name: products.STICKS_RINGS_SK,
  },
  startstickskitfire: {
    name: products.STICKS_RINGS_SK,
  },
  // SS Frozen
  startfrozenkit: {
    name: products.SUPERSTUDIO_FROZEN_SK,
  },
  startfrozenkitfire: {
    name: products.SUPERSTUDIO_FROZEN_SK,
  },
  startfrozen: {
    name: products.SUPERSTUDIO_FROZEN,
  },
  startfrozenfire: {
    name: products.SUPERSTUDIO_FROZEN,
  },
  // SS Princess
  startprincesskit: {
    name: products.SUPERSTUDIO_PRINCESS_SK,
  },
  startprincesskitfire: {
    name: products.SUPERSTUDIO_PRINCESS_SK,
  },
  startprincess: {
    name: products.SUPERSTUDIO_PRINCESS,
  },
  startprincessfire: {
    name: products.SUPERSTUDIO_PRINCESS,
  },
  // SS Mickey
  startmickeykit: {
    name: products.SUPERSTUDIO_MICKEY_SK,
  },
  startmickeykitfire: {
    name: products.SUPERSTUDIO_MICKEY_SK,
  },
  startmickey: {
    name: products.SUPERSTUDIO_MICKEY,
  },
  startmickeyfire: {
    name: products.SUPERSTUDIO_MICKEY,
  },
  // Pizza
  startpizzakit: {
    name: products.PIZZA_SK,
  },
  startpizzakitfire: {
    name: products.PIZZA_SK,
  },
  startpizza: {
    name: products.PIZZA,
  },
  startpizzafire: {
    name: products.PIZZA,
  },
  // Detective
  startdetectivekit: {
    name: products.DETECTIVE_SK,
  },
  startdetectivekitfire: {
    name: products.DETECTIVE_SK,
  },
  startdetective: {
    name: products.DETECTIVE,
  },
  startdetectivefire: {
    name: products.DETECTIVE,
  },
};
