import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

/**
 *  Osmo Button
 */

const OsmoButton = React.memo((props) => {
  const {
    text,
    theme = 'orange',
    isDisabled,
    isInverted,
    className,
    onClick,
    isLarge,
  } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onClick() {
    if (isDisabled) {
      return;
    }

    if (onClick) {
      onClick();
    }
  }

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  const osmoButtonClassNames = classNames(
    'osmo-button',
    `osmo-button--${theme}`,
    className,
    {
      'osmo-button--disabled': isDisabled,
      'osmo-button--inverted': isInverted,
      'osmo-button--large': isLarge,
    },
  );

  return (
    <button className={osmoButtonClassNames} onClick={_onClick}>
      <div className="osmo-button__inner">
        <span className="osmo-button__text">{text}</span>
      </div>
    </button>
  );
});

OsmoButton.propTypes = {
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInverted: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  isLarge: PropTypes.bool,
};

export default OsmoButton;
