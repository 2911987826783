import React from 'react';

import './style.scss';

/**
 *  Breadcrumb component
 */

const Breadcrumb = (props) => {
  const { elements } = props;

  return (
    <div className="breadcrumb">
      {!!elements?.length &&
        elements.map((element, index) => {
          if (elements.length - 1 === index) {
            return (
              <span className="breadcrumb__container breadcrumb__current">
                {element.label}
              </span>
            );
          } else {
            return (
              <span className="breadcrumb__container">
                <a href={element.path} className="breadcumb__link">
                  {element.label}
                </a>
                <span className="breadcrumb__separator">{'>'}</span>
              </span>
            );
          }
        })}
    </div>
  );
};

export default Breadcrumb;
