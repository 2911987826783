import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const SetupSteps = (props) => {
  const { steps } = props;

  if (!steps?.length) {
    return null;
  }

  return (
    <div className="setup-steps">
      {steps.map((step, index) => (
        <div className="setup-steps__step" key={index}>
          {step.text && (
            <div className="setup-steps__text">
              <div className="setup-steps__index">{`${index + 1}.`}</div>
              <p dangerouslySetInnerHTML={step.text} />
            </div>
          )}
          {step.image && (
            <div className="setup-steps__image-holder">
              <img
                className="setup-steps__image"
                src={step.image.src}
                alt={step.image.alt}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

SetupSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
};

export default SetupSteps;
