import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { translate } from 'src/i18n';
import * as selectors from 'src/selectors';
import * as actions from 'src/actions';

import {
  ONBOARDING_CONSENT_ROUTE,
  ONBOARDING_SURVEY_ROUTE,
} from 'src/router/onboarding/routes';
import getEmailStatus from 'src/apis/utils/getEmailStatus';

import { trajectories } from 'src/store/onboarding/reducers';
import AccountInformationForm from 'src/modules/AccountInformationForm';
import withLoading from 'src/components/hoc/withLoading';
import MainHeader from 'src/components/headers/MainHeader';
import ErrorMessage from 'src/components/messages/ErrorMessage';

import { views } from '../../Account';

import './style.scss';

const SignUp = (props) => {
  const {
    updateAccountView,
    setLoadingOn,
    setLoadingOff,
    // From Redux
    tmpAccountInformation,
    dispatch,
  } = props;
  const [globalError, setGlobalError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  const _onLoginClick = () => {
    updateAccountView(views.LOGIN);
  };

  const _onSubmit = ({ firstName, lastName, place, email, country }) => {
    // Set loading on
    setLoadingOn();

    dispatch({
      type: actions.onboarding.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          firstName,
          lastName,
          email,
          country,
        },
      },
    });

    // Check wether email exists or not
    getEmailStatus({
      email,
      existCallback: () => {
        setLoadingOff();
        updateAccountView(views.LOGIN);
      },
      notFoundCallback: () => {
        setLoadingOff();
        if (place === 'classroom') {
          dispatch({
            type: actions.onboarding.SET_TRAJECTORY_TYPE,
            payload: {
              trajectory: trajectories.CREATION_TEACHER,
            },
          });
          history.push(ONBOARDING_SURVEY_ROUTE);
        } else {
          history.push(ONBOARDING_CONSENT_ROUTE);
        }
      },
      errorCallback: (res) => {
        setGlobalError(res?.message);
        setLoadingOff();
      },
    });
  };

  function _getSubHeading() {
    const privacyPolicyUrl = 'https://www.playosmo.com/--/privacy-policy/';
    const termsAndConditionsUrl = 'https://www.playosmo.com/en/terms/';

    return (
      <div className="consent__subheading">
        {/*{translate('consent.subheader')}*/}
        <div className="flex-subheading">
          <a
            href={termsAndConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="link-items"
          >
            {translate('general.termsAndConditions')}
          </a>
          <div className="divider"></div>
          <a
            href={privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="link-items"
          >
            {translate('general.privacyPolicy')}
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="onboarding-sign-up">
      <MainHeader title={translate('onboarding.signUp.title')} />
      <p className="onboarding-account__subtitle">
        {translate('onboarding.signUp.subtitle')}
      </p>
      <AccountInformationForm
        onSubmit={_onSubmit}
        initialEmail={tmpAccountInformation?.email}
        withCountry={true}
      />
      {globalError?.length && (
        <div className="onboarding-account__message">
          <ErrorMessage message={globalError} />
        </div>
      )}
      <p className="onboarding-account__link">
        {translate('onboarding.signUp.alreadyHaveAnAccount')}
        <button className="onboarding-link" onClick={_onLoginClick}>
          {translate('onboarding.signUp.link')}
        </button>
      </p>
      {_getSubHeading()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tmpAccountInformation: selectors.onboarding.getTmpAccountInformationSelector(
    state,
  ),
});

export default connect(mapStateToProps)(withLoading(SignUp));
