import GeniusSKPlusImage from 'src/assets/images/kits/genius-starter-kit-plus-fire.png';
import LittleGeniusSKPlusImage from 'src/assets/images/kits/little-genius-starter-kit-plus-fire.png';
import FamilyGameNightBundleImage from 'src/assets/images/bundles/family_game_night_bundle.png';
import EarlyMathAdventureBundle from 'src/assets/images/bundles/early_math_adventure_bundle.png';
import MathChampionBundle from 'src/assets/images/bundles/math_champion_bundle.png';
import EssentialMathBundle from 'src/assets/images/bundles/essential_math_bundle.png';
import LiteracyBundle from 'src/assets/images/bundles/osmo_literacy_bundle.jpg';
import UltimateExpansionBundle from 'src/assets/images/bundles/ultimate_expansion_bundle.jpg';

// Product constants
export const products = {
  GENIUS_SK: 'genius-sk',
  LITTLE_GENIUS_SK: 'little-genius-sk',
  FAMILY_GAME_NIGHT_BUNDLE: 'family-game-night-bundle',
  EARLY_MATH_ADVENTURE_BUNDLE: 'early-math-adventure-bundle',
  ESSENTIAL_MATH_ADVENTURE_BUNDLE: 'essential-math-bundle',
  MATH_CHAMPION_BUNDLE: 'math-champion-bundle',
  LITERACY_BUNDLE: 'literacy-bundle',
  ULTIMATE_EXPANSION_BUNDLE: 'ultimate-expansion-bundle',
};

// Products data
export const productsData = {
  [products.GENIUS_SK]: {
    id: products.GENIUS_SK,
    title: 'Genius Starter Kit + Family Game Night',
    image: {
      src: GeniusSKPlusImage,
      alt: 'Genius Starter Kit + Family Game Night',
    },
  },
  [products.LITTLE_GENIUS_SK]: {
    id: products.LITTLE_GENIUS_SK,
    title: 'Little Genius Starter Kit + Early Math Adventure',
    image: {
      src: LittleGeniusSKPlusImage,
      alt: 'Little Genius Starter Kit + Early Math Adventure',
    },
  },
  [products.FAMILY_GAME_NIGHT_BUNDLE]: {
    id: products.FAMILY_GAME_NIGHT_BUNDLE,
    title: 'Family Game Night Bundle',
    image: {
      src: FamilyGameNightBundleImage,
      alt: 'Family Game Night Bundle',
    },
  },
  [products.EARLY_MATH_ADVENTURE_BUNDLE]: {
    id: products.EARLY_MATH_ADVENTURE_BUNDLE,
    title: 'Early Math Adventure Bundle',
    image: {
      src: EarlyMathAdventureBundle,
      alt: 'Early Math Adventure Bundle',
    },
  },
  [products.ESSENTIAL_MATH_ADVENTURE_BUNDLE]: {
    id: products.ESSENTIAL_MATH_ADVENTURE_BUNDLE,
    title: 'Essential Math Bundle',
    image: {
      src: EssentialMathBundle,
      alt: 'Essential Math Bundle',
    },
  },
  [products.MATH_CHAMPION_BUNDLE]: {
    id: products.MATH_CHAMPION_BUNDLE,
    title: 'Math Champion Bundle',
    image: {
      src: MathChampionBundle,
      alt: 'Math Champion Bundle',
    },
  },
  [products.LITERACY_BUNDLE]: {
    id: products.LITERACY_BUNDLE,
    title: 'Literacy Bundle',
    image: {
      src: LiteracyBundle,
      alt: 'Literacy Bundle',
    },
  },
  [products.LITERACY_BUNDLE]: {
    id: products.LITERACY_BUNDLE,
    title: 'Literacy Bundle',
    image: {
      src: LiteracyBundle,
      alt: 'Literacy Bundle',
    },
  },
  [products.ULTIMATE_EXPANSION_BUNDLE]: {
    id: products.ULTIMATE_EXPANSION_BUNDLE,
    title: 'Ultimate Expansion Bundle',
    image: {
      src: UltimateExpansionBundle,
      alt: 'Ultimate Expansion Bundle',
    },
  },
};

// Ordered products displayed on Product Selection page
export const orderedProductElements = [
  productsData[products.GENIUS_SK],
  productsData[products.LITTLE_GENIUS_SK],
  productsData[products.FAMILY_GAME_NIGHT_BUNDLE],
  productsData[products.EARLY_MATH_ADVENTURE_BUNDLE],
  productsData[products.ESSENTIAL_MATH_ADVENTURE_BUNDLE],
  productsData[products.MATH_CHAMPION_BUNDLE],
];
