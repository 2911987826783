import React from 'react';

import { isIpad, isSilk } from 'src/utils/device';

import VisitPageWithSupportedDevice from 'src/pages/games/VisitPageWithSupportedDevice';
import VisitPageWithSupportedDeviceOnboarding from 'src/pages/onboarding/VisitPageWithSupportedDevice';

const ignoreFireOnboardingPaths = `/onboarding/fire`;

const VisitPageWithSupportedDeviceHOC = (WrappedComponent, source) => {
  return () => {
    // If is neither ipad/fire then show a specific page
    if (
      !isIpad() &&
      !isSilk() &&
      !window.location.pathname.includes(ignoreFireOnboardingPaths)
    ) {
      if (source === 'onboarding') {
        return <VisitPageWithSupportedDeviceOnboarding />;
      } else {
        return <VisitPageWithSupportedDevice />;
      }
    }

    return <WrappedComponent />;
  };
};

export default VisitPageWithSupportedDeviceHOC;
