import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { translate } from 'src/i18n';

import * as actions from 'src/actions';

import Consent from 'src/modules/Consent';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import {
  ONBOARDING_WELCOME_ROUTE,
  ONBOARDING_ACCOUNT_ROUTE,
} from 'src/router/onboarding/routes';
import withLoading from 'src/components/hoc/withLoading';

import './style.scss';

const ConsentPage = (props) => {
  const { dispatch, setLoadingOn, setLoadingOff } = props;
  const history = useHistory();
  const [exists, setExists] = useState(false);
  const [emailsAreChecked, setEmailsAreChecked] = useState(true);
  const [errors, setErrors] = useState({});

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  const _onSubmit = () => {
    // Set loading on
    setLoadingOn();

    // Dispatch
    dispatch({
      type: actions.onboarding.CREATE_ACCOUNT_SAGA,
      payload: {
        params: {
          isSubscribingToEmails: emailsAreChecked,
        },
        callback: () => {
          setLoadingOff();
          history.push(ONBOARDING_WELCOME_ROUTE);
        },
        existingUserCallback: () => {
          setLoadingOff();
          setExists(true);
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            status: { message },
          });
        },
        errorRedeemCallback: (message) => {
          setLoadingOff();

          history.push(ONBOARDING_WELCOME_ROUTE);
        },
      },
    });
  };

  return (
    <div className="onboarding-consent">
      <Container>
        <CenterContent>
          <Consent
            onNext={_onSubmit}
            submitButtonLabel={translate('onboarding.consent.cta')}
            setEmailsAreChecked={setEmailsAreChecked}
            emailsAreChecked={emailsAreChecked}
            errors={errors}
            disableSubmitButton={exists}
          />
          {exists && (
            <p className="consent__terms">
              You already have an account, please{' '}
              <a href={ONBOARDING_ACCOUNT_ROUTE + '?view=login'}>login</a>
            </p>
          )}
        </CenterContent>
      </Container>
    </div>
  );
};

export default withLoading(ConsentPage);
