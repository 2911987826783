import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';

import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import withLoading from 'src/components/hoc/withLoading';
import Consent from 'src/modules/Consent';

import './style.scss';

const ConsentPage = (props) => {
  const { dispatch, setLoadingOn, setLoadingOff } = props;
  const [errors, setErrors] = useState({});
  const [emailsAreChecked, setEmailsAreChecked] = useState(true);

  const history = useHistory();

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onSubmit() {
    // Set loading on
    setLoadingOn();

    // Dispatch
    dispatch({
      type: actions.redeem.CREATE_ACCOUNT_SAGA,
      payload: {
        params: {
          isSubscribingToEmails: emailsAreChecked,
        },
        callback: (nextRoute) => {
          setLoadingOff();

          if (nextRoute) {
            history.push(nextRoute);
          }
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            status: { message },
          });
        },
      },
    });
  }

  return (
    <div className="consent-page">
      <Container>
        <CenterContent>
          <Consent
            onNext={_onSubmit}
            setEmailsAreChecked={setEmailsAreChecked}
            emailsAreChecked={emailsAreChecked}
            errors={errors}
          />
        </CenterContent>
      </Container>
    </div>
  );
};

export default withLoading(ConsentPage);
