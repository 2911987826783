import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  isEnterKeydownEvent,
  isEscKeydownEvent,
  isUpKeydownEvent,
  isDownKeydownEvent,
} from 'src/utils/events';

import ErrorMessage from 'src/components/messages/ErrorMessage';

import DoubleArrowSvg from 'src/assets/images/icons/double-arrow.svg';

import './style.scss';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (callback) {
          callback();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

/**
 *  Custom Select Input
 */

const SelectInput = (props) => {
  const {
    label,
    placeholder,
    className,
    value,
    options,
    onChange,
    error,
  } = props;

  const wrapperRef = useRef(null);
  const optionsRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => _toggleOptions(false));

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  const [displayOptions, setDisplayOptions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _toggleOptions(forceValue) {
    if (typeof forceValue === 'boolean') {
      setDisplayOptions(forceValue);
    } else {
      setDisplayOptions(!displayOptions);
    }
  }

  function _onChange(value) {
    onChange(value);
    _toggleOptions(false);
    _onBlur();
  }

  function _onFocus() {
    setIsFocused(true);
  }

  function _onBlur() {
    setIsFocused(false);
  }

  function _onKeyDown(e, value, index) {
    e.stopPropagation();
    const optionsEls =
      optionsRef &&
      optionsRef.current &&
      optionsRef &&
      optionsRef.current.children;

    if (!optionsEls || !optionsEls.length) return;

    if (isEnterKeydownEvent(e) && value) {
      _onChange(value);
    }

    if (isUpKeydownEvent(e) && value) {
      const nextIndex = index - 1 >= 0 ? index - 1 : 0;
      optionsEls[nextIndex].focus();
    }

    if (isDownKeydownEvent(e)) {
      if (value) {
        const optionsCount = optionsEls.length - 1;
        const nextIndex = index + 1 <= optionsCount ? index + 1 : optionsCount;
        optionsEls[nextIndex].focus();
      } else {
        optionsEls[0].focus();
        optionsRef.current.blur();
      }
    }

    if (isEscKeydownEvent(e)) {
      _onBlur();
      _toggleOptions(false);
    }
  }

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  const selectInputClassNames = classNames('select-input', className, {
    // Active state if focused or has value
    'select-input--focus': isFocused || displayOptions,
    'select-input--active': !!value && !!value.length,
    'select-input--error': error,
  });

  const selectedOption =
    value && options.find((option) => option.value === value);

  return (
    <div
      className={selectInputClassNames}
      ref={wrapperRef}
      tabIndex="0"
      onFocus={_onFocus}
      onBlur={_onBlur}
      onKeyDown={(e) => _onKeyDown(e)}
    >
      <div className="select-input__input" onClick={_toggleOptions}>
        {label && (
          <div className="select-input__label">
            <span>{label}</span>
          </div>
        )}
        <div className="select-input__placeholder">
          {selectedOption?.label || placeholder}
        </div>
        <div
          className={classNames('select-input__options', {
            'select-input__options--open': isFocused || displayOptions,
          })}
          ref={optionsRef}
        >
          {options.map((option, index) => (
            <div
              className="select-input__option"
              onClick={() => _onChange(option.value)}
              onKeyDown={(e) => _onKeyDown(e, option.value, index)}
              key={option.value}
              tabIndex="0"
            >
              {option.label}
            </div>
          ))}
        </div>
        <img src={DoubleArrowSvg} alt="" className="select-input__icon" />
      </div>
      {error && (
        <div className="select-input__error">
          <ErrorMessage message={error?.message} />
        </div>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
};

export default SelectInput;
