import React from 'react';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import PlusIcon from 'src/assets/images/icons/plus.svg';
import MinusIcon from 'src/assets/images/icons/minus.svg';

import './style.scss';

/**
 *  Simple slide down component
 */

const SlideDownComponent = (props) => {
  const {
    children,
    label,
    active,
    onClick,
    className,
    noPadding,
    hideControl,
  } = props;

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _onClick() {
    if (onClick) {
      onClick();
    }
  }

  // ===========================================================================
  // ========================= COMPUTED VARIABLES ==============================
  // ===========================================================================

  function _getIcon() {
    return {
      src: active ? MinusIcon : PlusIcon,
      alt: active ? 'Minus' : 'Plus',
    };
  }

  const icon = _getIcon();

  const slideDownClassNames = classNames('slide-down', className, {
    'slide-down--active': active,
    'slide-down--no-padding': noPadding,
    'slide-down--hide-control': hideControl,
  });

  if (!children || !label) {
    return null;
  }

  return (
    <div className={slideDownClassNames}>
      <div className="slide-down__toggler" onClick={_onClick}>
        <div className="slide-down__label">{label}</div>
        {!hideControl && (
          <img className="slide-down__icon" src={icon.src} alt={icon.alt} />
        )}
      </div>
      <SlideDown className="slide-down__content">
        {active ? children : null}
      </SlideDown>
    </div>
  );
};

export default SlideDownComponent;
