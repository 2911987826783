import React, { useState } from 'react';
import { translate } from 'src/i18n';

import withLoading from 'src/components/hoc/withLoading';
import OsmoCheckbox from 'src/components/inputs/OsmoCheckbox';
import OsmoButton from 'src/components/buttons/OsmoButton';
import OsmoInput from 'src/components/inputs/OsmoInput';
import ErrorMessage from 'src/components/messages/ErrorMessage';

import { eduClassroomUsages } from 'src/data/redeem-flow/eduClassroomUsages';

import './style.scss';

// Constants
const OTHER_ID = 'other';

const TeacherReferralForm = (props) => {
  const { isLoading, onSubmit } = props;

  // ===========================================================================
  // ============================== STATES ===================================
  // ===========================================================================

  const [activeReferrals, setActiveReferrals] = useState([]);
  const [otherInput, setOtherInput] = useState('');
  const [errors, setErrors] = useState({});
  const [anyCheckBoxSelected, setAnyCheckBoxSelected] = useState(false);

  // ===========================================================================
  // ============================== ERRORS =====================================
  // ===========================================================================

  function _resetErrors() {
    setErrors({});
  }

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleReferralChange(id) {
    const updatedReferrals = [...activeReferrals];

    const isReferralActive = updatedReferrals.includes(id);

    if (isReferralActive) {
      const index = updatedReferrals.indexOf(id);
      updatedReferrals.splice(index, 1);
    } else {
      updatedReferrals.push(id);
    }

    setActiveReferrals(updatedReferrals);

    if (updatedReferrals.length) {
      setAnyCheckBoxSelected(true);
    } else {
      setAnyCheckBoxSelected(false);
    }
  }

  function _handleOtherInputChange(e) {
    setErrors({ ...errors, otherInput: null });
    setOtherInput(e.target.value);
  }

  function _onSubmit() {
    // Prevent form submit if isLoading
    if (isLoading) {
      return;
    }

    // Reset errors state
    _resetErrors();

    // Check if it's 'other' referral and if a value is defined
    if (_hasOtherValue() && !otherInput) {
      setErrors({
        ...errors,
        otherInput: {
          message: translate('general.teacherReferral.errorMessage'),
        },
      });
    }
    // Format acquisition sources depending if its defined
    // choice or 'other'
    const schoolUsages = [...activeReferrals];

    // Format other value if it's in activeReferrals
    if (_hasOtherValue() && otherInput) {
      const index = schoolUsages.indexOf(OTHER_ID);
      schoolUsages.splice(index, 1);
      schoolUsages.push(`Other - ${otherInput}`);
    }

    onSubmit({ schoolUsages });
  }

  // ===========================================================================
  // ======================== COMPUTED VARIABLES ===============================
  // ===========================================================================

  function _hasOtherValue() {
    return activeReferrals.includes(OTHER_ID);
  }

  // Get if we should display other input
  function _getShouldDisplayOtherInput() {
    return _hasOtherValue();
  }

  return (
    <div className="teacher-referral-form">
      <div className="teacher-referral-form__label">
        {translate('teacherReferral.label')}
      </div>
      <div className="teacher-referral-form__container">
        {eduClassroomUsages?.length > 0 &&
          eduClassroomUsages.map((usage) => (
            <OsmoCheckbox
              key={usage.id}
              className="teacher-referral-form__input"
              id={usage.id}
              label={usage.name}
              onChange={() => _handleReferralChange(usage.id)}
              checked={activeReferrals.includes(usage.id)}
            />
          ))}
      </div>

      {_getShouldDisplayOtherInput() && (
        <OsmoInput
          className="teacher-referral-form__input-other"
          placeHolder="Other"
          onChange={_handleOtherInputChange}
          value={otherInput}
          error={errors.otherInput}
        />
      )}

      {errors?.status && (
        <div className="teacher-referral-form__message">
          <ErrorMessage message={errors?.status?.message} />
        </div>
      )}

      <div className="teacher-referral-form__button">
        <OsmoButton
          text={translate('general.next')}
          onClick={_onSubmit}
          isDisabled={!anyCheckBoxSelected}
        />
      </div>
    </div>
  );
};

export default withLoading(TeacherReferralForm);
