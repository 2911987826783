import {
  IS_LOCAL,
  IS_RELC,
  IS_DEVELOP,
  IS_BETA_PARENT,
} from 'src/config/environment';

export const ENVIRONMENT_API_URL = 'https://www.playosmo.com/';
export const ENVIRONMENT_BETA_PARENT_API_URL = 'https://beta.dev.playosmo.com/';
export const ENVIRONMENT_DEVELOP_API_URL = 'https://develop.dev.playosmo.com/';
export const ENVIRONMENT_RELC_API_URL = 'https://relc.dev.playosmo.com/';

export function getPlayOsmoApiUrl() {
  if (IS_DEVELOP) {
    return ENVIRONMENT_DEVELOP_API_URL;
  }

  if (IS_LOCAL || IS_RELC) {
    return ENVIRONMENT_RELC_API_URL;
  }

  if (IS_BETA_PARENT) {
    return ENVIRONMENT_BETA_PARENT_API_URL;
  }

  return ENVIRONMENT_API_URL;
}
