import { products } from 'src/data/products';

import img_1 from 'src/assets/images/animations/onboarding/home/base/img_1.png';
import img_2 from 'src/assets/images/animations/onboarding/home/base/img_2.png';
import img_4 from 'src/assets/images/animations/onboarding/home/base/img_4.jpg';
import img_5 from 'src/assets/images/animations/onboarding/home/base/img_5.png';

// GeniusSk
import geniusTangibles from 'src/assets/images/animations/onboarding/home/genius-sk/img_0.png';
import geniusScreen from 'src/assets/images/animations/onboarding/home/genius-sk/img_3.jpg';

// LittleGeniusSk
import littleGeniusTangibles from 'src/assets/images/animations/onboarding/home/little-genius-sk/img_0.png';
import littleGeniusScreen from 'src/assets/images/animations/onboarding/home/little-genius-sk/img_3.jpg';

// LittleGeniusPlusSk
import littleGeniusPlusTangibles from 'src/assets/images/animations/onboarding/home/little-genius-sk-plus/img_0.png';
import littleGeniusPlusScreen from 'src/assets/images/animations/onboarding/home/little-genius-sk-plus/img_3.jpg';

// GeniusPlusSk
import geniusPlusTangibles from 'src/assets/images/animations/onboarding/home/genius-sk-plus/img_0.png';
import geniusPlusScreen from 'src/assets/images/animations/onboarding/home/genius-sk-plus/img_3.jpg';

// CodingSk
import codingTangibles from 'src/assets/images/animations/onboarding/home/coding-sk/img_0.png';
import codingScreen from 'src/assets/images/animations/onboarding/home/coding-sk/img_3.jpg';

// CreativeSk
import creativeSKTangibles from 'src/assets/images/animations/onboarding/home/creative-sk/img_0.png';
import creativeSKScreen from 'src/assets/images/animations/onboarding/home/creative-sk/img_3.jpg';

// DetectiveSK
import detectiveSKTangibles from 'src/assets/images/animations/onboarding/home/detective-sk/img_0.png';
import detectiveSKScreen from 'src/assets/images/animations/onboarding/home/detective-sk/img_3.jpg';

// Detective
import detectiveTangibles from 'src/assets/images/animations/onboarding/home/detective-game/img_0.png';
import detectiveScreen from 'src/assets/images/animations/onboarding/home/detective-game/img_3.jpg';

// PizzaSK
import pizzaSKTangibles from 'src/assets/images/animations/onboarding/home/pizza-sk/img_0.png';
import pizzaSKScreen from 'src/assets/images/animations/onboarding/home/pizza-sk/img_3.jpg';

// Pizza
import pizzaTangibles from 'src/assets/images/animations/onboarding/home/pizza-game/img_0.png';
import pizzaScreen from 'src/assets/images/animations/onboarding/home/pizza-game/img_3.jpg';

// NumbersSK
import numbersSKTangibles from 'src/assets/images/animations/onboarding/home/numbers-sk/img_0.png';
import numbersSKScreen from 'src/assets/images/animations/onboarding/home/numbers-sk/img_3.jpg';

// Numbers
import numbersTangibles from 'src/assets/images/animations/onboarding/home/numbers-game/img_0.png';
import numbersScreen from 'src/assets/images/animations/onboarding/home/numbers-game/img_3.jpg';

// SticksRingsSK
import sticksRingsSKTangibles from 'src/assets/images/animations/onboarding/home/sticks-and-rings-sk/img_0.png';
import sticksRingsSKScreen from 'src/assets/images/animations/onboarding/home/sticks-and-rings-sk/img_3.jpg';

// SuperstudioPrincess
import superstudioPrincessTangibles from 'src/assets/images/animations/onboarding/home/superstudio-princess-game/img_0.png';
import superstudioPrincessScreen from 'src/assets/images/animations/onboarding/home/superstudio-princess-game/img_3.jpg';

// SuperstudioPrincessSK
import superstudioPrincessSKTangibles from 'src/assets/images/animations/onboarding/home/superstudio-princess-sk/img_0.png';
import superstudioPrincessSKScreen from 'src/assets/images/animations/onboarding/home/superstudio-princess-sk/img_3.jpg';

// SuperstudioFrozen
import superstudioFrozenTangibles from 'src/assets/images/animations/onboarding/home/superstudio-frozen2-game/img_0.png';
import superstudioFrozenScreen from 'src/assets/images/animations/onboarding/home/superstudio-frozen2-game/img_3.jpg';

// SuperstudioFrozenSK
import superstudioFrozenSKTangibles from 'src/assets/images/animations/onboarding/home/superstudio-frozen2-sk/img_0.png';
import superstudioFrozenSKScreen from 'src/assets/images/animations/onboarding/home/superstudio-frozen2-sk/img_3.jpg';

// SuperstudioMickey
import superstudioMickeyTangibles from 'src/assets/images/animations/onboarding/home/superstudio-mickey-game/img_0.png';
import superstudioMickeyScreen from 'src/assets/images/animations/onboarding/home/superstudio-mickey-game/img_3.jpg';

// SuperstudioMickeySK
import superstudioMickeySKTangibles from 'src/assets/images/animations/onboarding/home/superstudio-mickey-sk/img_0.png';
import superstudioMickeySKScreen from 'src/assets/images/animations/onboarding/home/superstudio-mickey-sk/img_3.jpg';

// Words
import wordsTangibles from 'src/assets/images/animations/onboarding/home/words-game/img_0.png';
import wordsScreen from 'src/assets/images/animations/onboarding/home/words-game/img_3.jpg';

// WordsSK
import wordsSKTangibles from 'src/assets/images/animations/onboarding/home/words-sk/img_0.png';
import wordsSKScreen from 'src/assets/images/animations/onboarding/home/words-sk/img_3.jpg';

const assetsByProduct = {
  [products.CODING_SK]: {
    screen: codingScreen,
    tangibles: codingTangibles,
  },
  [products.GENIUS_SK]: {
    screen: geniusScreen,
    tangibles: geniusTangibles,
  },
  [products.GENIUS_SK_PLUS]: {
    screen: geniusPlusScreen,
    tangibles: geniusPlusTangibles,
  },
  [products.LITTLE_GENIUS_SK]: {
    screen: littleGeniusScreen,
    tangibles: littleGeniusTangibles,
  },
  [products.LITTLE_GENIUS_SK_PLUS]: {
    screen: littleGeniusPlusScreen,
    tangibles: littleGeniusPlusTangibles,
  },
  [products.CREATIVE_SK]: {
    screen: creativeSKScreen,
    tangibles: creativeSKTangibles,
  },
  [products.STICKS_RINGS_SK]: {
    screen: sticksRingsSKScreen,
    tangibles: sticksRingsSKTangibles,
  },
  [products.PIZZA_SK]: {
    screen: pizzaSKScreen,
    tangibles: pizzaSKTangibles,
  },
  [products.PIZZA]: {
    screen: pizzaScreen,
    tangibles: pizzaTangibles,
  },
  [products.NUMBERS_SK]: {
    screen: numbersSKScreen,
    tangibles: numbersSKTangibles,
  },
  [products.NUMBERS]: {
    screen: numbersScreen,
    tangibles: numbersTangibles,
  },
  [products.WORDS_SK]: {
    screen: wordsSKScreen,
    tangibles: wordsSKTangibles,
  },
  [products.WORDS]: {
    screen: wordsScreen,
    tangibles: wordsTangibles,
  },
  [products.DETECTIVE_SK]: {
    screen: detectiveSKScreen,
    tangibles: detectiveSKTangibles,
  },
  [products.DETECTIVE]: {
    screen: detectiveScreen,
    tangibles: detectiveTangibles,
  },
  [products.SUPERSTUDIO_PRINCESS]: {
    screen: superstudioPrincessScreen,
    tangibles: superstudioPrincessTangibles,
  },
  [products.SUPERSTUDIO_PRINCESS_SK]: {
    screen: superstudioPrincessSKScreen,
    tangibles: superstudioPrincessSKTangibles,
  },
  [products.SUPERSTUDIO_FROZEN_SK]: {
    screen: superstudioFrozenSKScreen,
    tangibles: superstudioFrozenSKTangibles,
  },
  [products.SUPERSTUDIO_FROZEN]: {
    screen: superstudioFrozenScreen,
    tangibles: superstudioFrozenTangibles,
  },
  [products.SUPERSTUDIO_MICKEY_SK]: {
    screen: superstudioMickeySKScreen,
    tangibles: superstudioMickeySKTangibles,
  },
  [products.SUPERSTUDIO_MICKEY]: {
    screen: superstudioMickeyScreen,
    tangibles: superstudioMickeyTangibles,
  },
};

export default (product) => ({
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 1536,
  h: 2048,
  nm: 'onboarding_2022-animation-landing_page',
  ddd: 0,
  assets: [
    {
      id: 'tangibles.psd',
      w: 2800,
      h: 900,
      u: '',
      p: assetsByProduct[product]?.tangibles,
      e: 0,
    },
    {
      id: 'reflector/ipad_front_facing_flattened.psd',
      w: 370,
      h: 212,
      u: '',
      p: img_1,
      e: 0,
    },
    {
      id: 'base front/ipad_front_facing_flattened.psd',
      w: 1340,
      h: 277,
      u: '',
      p: img_2,
      e: 0,
    },
    {
      id: 'screenshot/ipad_front_facing_flattened.psd',
      w: 980,
      h: 1307,
      u: '',
      p: assetsByProduct[product]?.screen,
      e: 0,
    },
    {
      id: 'ipad/ipad_front_facing_flattened.psd',
      w: 1128,
      h: 1594,
      u: '',
      p: img_4,
      e: 0,
    },
    {
      id: 'base back/ipad_front_facing_flattened.psd',
      w: 3549,
      h: 2940,
      u: '',
      p: img_5,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: 'Null',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 0,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.25,
                y: 1,
              },
              o: {
                x: 0.1,
                y: 0,
              },
              t: 58,
              s: [769, 1145, 0],
              to: [87.167, 0, 0],
              ti: [-87.167, 0, 0],
            },
            {
              t: 71,
              s: [1292, 1145, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [165, 165, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'tangibles',
      parent: 2,
      refId: 'tangibles.psd',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.25,
                y: 1,
              },
              o: {
                x: 0.1,
                y: 0,
              },
              t: 26.833,
              s: [7.273, 765.455, 0],
              to: [0, -88.889, 0],
              ti: [0, 88.889, 0],
            },
            {
              t: 52.5,
              s: [7.273, 232.121, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [1400, 450, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [33.939, 33.939, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'reflector',
      parent: 2,
      refId: 'reflector/ipad_front_facing_flattened.psd',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.25,
                y: 1,
              },
              o: {
                x: 0.1,
                y: 0,
              },
              t: 21,
              s: [-0.933, -789.745, 0],
              to: [0, 48.485, 0],
              ti: [0, -48.485, 0],
            },
            {
              t: 47.8330078125,
              s: [-0.933, -498.836, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [185, 106, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [32.727, 32.727, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'base front',
      parent: 2,
      refId: 'base front/ipad_front_facing_flattened.psd',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 8,
              s: [0],
            },
            {
              t: 14,
              s: [100],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [-1.588, 35.436, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [670, 138.5, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [32.727, 32.727, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'screenshot',
      parent: 2,
      refId: 'screenshot/ipad_front_facing_flattened.psd',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 37,
              s: [0],
            },
            {
              t: 47,
              s: [100],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [-1.915, -246.673, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [490, 653.5, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [32.727, 32.727, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'ipad',
      parent: 2,
      refId: 'ipad/ipad_front_facing_flattened.psd',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.25,
                y: 1,
              },
              o: {
                x: 0.1,
                y: 0,
              },
              t: 10.5,
              s: [-1.588, -974.436, 0],
              to: [0, 121.212, 0],
              ti: [0, -121.212, 0],
            },
            {
              t: 36.1669921875,
              s: [-1.588, -247.164, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [564, 797, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [32.727, 32.727, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: 'base back',
      parent: 2,
      refId: 'base back/ipad_front_facing_flattened.psd',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 1,
              s: [0],
            },
            {
              t: 10,
              s: [100],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [0.212, 165.855, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [1774.5, 1470, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [32.727, 32.727, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
});
