import * as actions from 'src/actions';

const initialState = {
  show: false,
  props: {},
  type: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.modal.SHOW_MODAL: {
      const { type, props } = action.payload;

      return {
        ...initialState,
        ...state,
        show: true,
        props,
        type,
      };
    }

    case actions.modal.HIDE_MODAL: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
