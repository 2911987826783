// Stolen from https://codepen.io/divinsmathew/pen/MWmrKQG
import React from 'react';

import Lottie from 'src/components/animations/Lottie';

import animationData from './animation/lottie.js';

import './style.scss';

const RotateDevice = () => (
  <div className="rotate-device">
    <div className="rotate-device__inner">
      <Lottie animationData={animationData} />
    </div>
    <div className="rotate-device__text">Please rotate your device</div>
  </div>
);

export default RotateDevice;
