import React from 'react';
import PropTypes from 'prop-types';

import Container from 'src/components/displays/Container';

import OsmoWhiteLogo from 'src/assets/images/onboarding/osmo-original--logo-white.svg';
import OsmoLogo from 'src/assets/images/onboarding/osmo-original-logo.svg';

import './style.scss';

/**
 *  This component is the header
 *  of the whole application
 */

const SiteHeader = React.memo((props) => {
  const { rightSide, color = 'red', size = 'medium' } = props;

  return (
    <div className={`site-header site-header--${color} site-header--${size}`}>
      <Container>
        <div className="site-header__container">
          <img
            className="site-header__osmo-logo"
            src={color === 'red' ? OsmoWhiteLogo : OsmoLogo}
            alt="Osmo"
          />
          {rightSide && rightSide()}
        </div>
      </Container>
    </div>
  );
});

SiteHeader.propTypes = {
  rightSide: PropTypes.func,
};

export default SiteHeader;
