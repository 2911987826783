import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SlideDown from 'src/components/general/SlideDown';
import SetupSteps from '../SetupSteps';

import './style.scss';

const ProductInstructionsCarrousel = (props) => {
  const { carrouselData } = props;

  const hasASingleItem = carrouselData.length === 1;

  // ===========================================================================
  // ============================ STATES =======================================
  // ===========================================================================

  // Show first item by default if carrousel has a single item
  const [slidedDownIndex, setSlidedDownIndex] = useState(
    hasASingleItem ? 0 : -1,
  );

  // ===========================================================================
  // ============================== HANDLERS ===================================
  // ===========================================================================

  function _handleSlideDownClick(index) {
    // Disable item toggling if there's a single item
    if (hasASingleItem) {
      return;
    }

    const updatedIndex = index === slidedDownIndex ? -1 : index;
    setSlidedDownIndex(updatedIndex);
  }

  if (!carrouselData?.length) {
    return null;
  }

  return (
    <div className="product-instructions-carrousel">
      {carrouselData.map((element, index) => (
        <SlideDown
          key={index}
          label={element.label}
          onClick={() => _handleSlideDownClick(index)}
          active={slidedDownIndex === index}
          noPadding={true}
          // Hide slide down control if there's only an item
          hideControl={hasASingleItem}
        >
          {element.steps && <SetupSteps steps={element.steps} />}
        </SlideDown>
      ))}
    </div>
  );
};

ProductInstructionsCarrousel.propTypes = {
  carrouselData: PropTypes.arrayOf(PropTypes.object),
};

export default ProductInstructionsCarrousel;
