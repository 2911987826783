import React, { useEffect, useRef, useCallback } from 'react';
import lottie from 'lottie-web';

import { isOldFireDevice } from 'src/utils/device';
import { checkObjEquality } from 'src/utils/object';

const Lottie = React.memo(
  ({ animationData, width, height, play, onPlay, settings = {} }) => {
    const element = useRef(null);
    const lottieInstance = useRef(null);

    const handlePlay = useCallback(() => {
      lottieInstance.current.play();
      if (onPlay) {
        onPlay();
      }
    }, [onPlay]);

    useEffect(() => {
      if (lottieInstance.current) {
        lottieInstance.current.addEventListener('loaded_images', () => {
          handlePlay();
        });
      }
    }, [handlePlay]);

    useEffect(() => {
      if (play) {
        handlePlay();
      }
    }, [play, handlePlay]);

    useEffect(() => {
      if (element.current) {
        lottieInstance.current = lottie.loadAnimation({
          animationData,
          container: element.current,
          ...settings,
        });

        if (isOldFireDevice()) {
          lottieInstance.current.goToAndStop(
            lottieInstance.current.totalFrames - 1,
            true,
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationData]);

    return <div style={{ width, height }} ref={element}></div>;
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.animationData) !==
      JSON.stringify(nextProps.animationData)
    ) {
      return checkObjEquality(
        prevProps.animationData,
        nextProps.animationData,
        ['__complete', 'assets', 'layers'],
      );
    }

    return undefined;
  },
);

export default Lottie;
