import { select, takeLatest, call } from 'redux-saga/effects';

import { store } from 'src/App';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import { report } from 'src/services/errors';

import {
  getEmailLoginCode,
  confirmEmailLoginCode,
  createAccount,
} from 'src/apis/login';

import { redeemCodeForUser, getRedeemCodeStatus } from 'src/apis/redeem';

// ===========================================================================
// ============================== ONBOARDING =================================
// ===========================================================================

function* sendEmail(action) {
  const { params, callback, errorCallback } = action.payload;
  const { email } = params;

  try {
    const response = yield call(getEmailLoginCode, {
      email,
      flow: 'onboarding2022',
    });

    // Check if response returns code was sent
    if (!response || !response.code_sent) {
      if (errorCallback) {
        if (!response.message) {
          report(
            `Error while send onboarding login request (${email}): ${JSON.stringify(
              response,
            )}`,
            response,
          );
        }
        errorCallback(response.message || 'Login request failed');
      }

      return;
    }

    if (callback) {
      const noEmailFound = false;
      callback({ noEmailFound });
    }
  } catch (e) {
    report(
      `Error in onboarding login process (${email}): ${JSON.stringify(e)}`,
      e,
    );
    if (errorCallback) {
      errorCallback('Login request failed');
    }
  }
}

function* sendVerificationCode(action) {
  const {
    params,
    callback,
    errorCallback,
    errorRedeemCallback,
  } = action.payload;
  const { email, verificationCode } = params;

  const tmpAccountInfo = yield select(
    selectors.onboarding.getTmpAccountInformationSelector,
  );

  try {
    const response = yield call(confirmEmailLoginCode, {
      email,
      code: verificationCode,
      flow: 'onboarding2022',
    });

    if (!response || response.users === undefined) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work, did you make a typo?';

        errorCallback(message);
      }
      return;
    }

    // If user already exists then store it into redux
    if (response.users && response.users[0]) {
      const user = response.users[0];

      // Store user
      store.dispatch({
        type: actions.onboarding.SET_USER,
        payload: {
          user,
        },
      });

      // After account creation, we redeem code for that user
      if (tmpAccountInfo.redeemCode) {
        const redeemCodeStatusResponse = yield call(getRedeemCodeStatus, {
          code: tmpAccountInfo.redeemCode,
        });

        if (
          redeemCodeStatusResponse?.code > 208 ||
          redeemCodeStatusResponse?.Code > 208
        ) {
          if (errorRedeemCallback) {
            const message =
              redeemCodeStatusResponse.message ||
              redeemCodeStatusResponse.Message ||
              'Oops! Redeeming the digital apps didn’t work.';

            store.dispatch({
              type: actions.onboarding.SET_REDEEM_ERROR,
              payload: {
                redeemError: {
                  message,
                },
              },
            });

            errorRedeemCallback(message);
          }
          return;
        }

        const isRedeemed = redeemCodeStatusResponse.redeemed;
        if (isRedeemed) {
          store.dispatch({
            type: actions.onboarding.SET_REDEEM_ERROR,
            payload: {
              redeemError: {
                valid: false,
                redeemed: isRedeemed,
              },
            },
          });
        } else {
          const redeemCodeResponse = yield call(redeemCodeForUser, {
            code: tmpAccountInfo.redeemCode,
          });

          if (
            !redeemCodeResponse ||
            redeemCodeResponse?.code > 208 ||
            redeemCodeResponse?.Code > 208 ||
            !redeemCodeResponse.valid ||
            !redeemCodeResponse.redeemed
          ) {
            if (!!errorRedeemCallback) {
              const message =
                redeemCodeResponse.message || redeemCodeResponse.Message;
              store.dispatch({
                type: actions.onboarding.SET_REDEEM_ERROR,
                payload: {
                  redeemError: {
                    valid: !redeemCodeResponse.valid,
                    redeemed: redeemCodeResponse.redeemed,
                    message,
                  },
                },
              });

              errorRedeemCallback(message);
              return;
            }
          }
        }
      }

      // Then redirect to continue setup page
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    report(e);

    if (errorCallback) {
      const message = 'Oops! That didn’t work, did you make a typo?';

      errorCallback(message);
    }
  }
}

function* createAccountRequest(action) {
  const {
    callback,
    existingUserCallback,
    errorCallback,
    errorRedeemCallback,
    params: { isSubscribingToEmails },
  } = action.payload;

  const product = yield select(
    selectors.onboarding.getOnboardingProductSelector,
  );
  const tmpAccountInfo = yield select(
    selectors.onboarding.getTmpAccountInformationSelector,
  );

  try {
    const payload = {
      email: tmpAccountInfo.email,
      firstName: tmpAccountInfo.firstName,
      lastName: tmpAccountInfo.lastName,
      country: tmpAccountInfo.country,
      isSubscribingToEmails,
      onboardingProduct: product.name,
      onboardingGames: product.gamesIncluded,
      flow: 'onboarding2022',
      schoolSettings: {
        schoolUsage: tmpAccountInfo.schoolUsages,
      },
    };
    const response = yield call(createAccount, payload);

    if (response?.code > 208) {
      if (response.message_key === 'email_already_exists') {
        existingUserCallback();
      } else if (errorCallback) {
        const message = response.message || 'Oops! That didn’t work.';

        errorCallback(message);
      }
      return;
    }

    if (!response || !response.users || !response.users[0]) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work.';

        errorCallback(message);
      }
      return;
    }

    const user = response.users[0];

    // Store user
    store.dispatch({
      type: actions.onboarding.SET_USER,
      payload: {
        user,
      },
    });

    // After account creation, we redeem code for that user
    if (tmpAccountInfo.redeemCode) {
      const redeemCodeStatusResponse = yield call(getRedeemCodeStatus, {
        code: tmpAccountInfo.redeemCode,
      });

      if (
        redeemCodeStatusResponse?.code > 208 ||
        redeemCodeStatusResponse?.Code > 208
      ) {
        if (errorRedeemCallback) {
          const message =
            redeemCodeStatusResponse.message ||
            redeemCodeStatusResponse.Message ||
            'Oops! Redeeming the digital apps didn’t work.';

          store.dispatch({
            type: actions.onboarding.SET_REDEEM_ERROR,
            payload: {
              redeemError: {
                message,
              },
            },
          });

          errorRedeemCallback(message);
        }
        return;
      }

      const isRedeemed = redeemCodeStatusResponse.redeemed;
      if (isRedeemed) {
        store.dispatch({
          type: actions.onboarding.SET_REDEEM_ERROR,
          payload: {
            redeemError: {
              valid: false,
              redeemed: isRedeemed,
            },
          },
        });
      } else {
        const redeemCodeResponse = yield call(redeemCodeForUser, {
          code: tmpAccountInfo.redeemCode,
        });

        if (
          !redeemCodeResponse ||
          redeemCodeResponse?.code > 208 ||
          redeemCodeResponse?.Code > 208 ||
          !redeemCodeResponse.valid ||
          !redeemCodeResponse.redeemed
        ) {
          if (!!errorRedeemCallback) {
            const message =
              redeemCodeResponse.message || redeemCodeResponse.Message;
            store.dispatch({
              type: actions.onboarding.SET_REDEEM_ERROR,
              payload: {
                redeemError: {
                  valid: !redeemCodeResponse.valid,
                  redeemed: redeemCodeResponse.redeemed,
                  message,
                },
              },
            });

            errorRedeemCallback();
            return;
          }
        }
      }
    }

    if (callback) {
      callback();
    }
  } catch (e) {
    report(e);

    if (errorCallback) {
      const message = 'Oops! That didn’t work.';

      errorCallback(message);
    }
  }
}

function* redeemCode(action) {
  const { callback, errorCallback } = action.payload;

  const tmpAccountInfo = yield select(
    selectors.onboarding.getTmpAccountInformationSelector,
  );

  try {
    const redeemCodeResponse = yield call(redeemCodeForUser, {
      code: tmpAccountInfo.redeemCode,
      override: true,
    });

    if (
      !redeemCodeResponse ||
      !redeemCodeResponse.valid ||
      !redeemCodeResponse.redeemed
    ) {
      if (errorCallback) {
        const message = 'Oops! That didn’t work, did you make a typo?';

        errorCallback(message);
      }
    }

    if (callback) {
      callback();
    }
  } catch (e) {
    report(e);

    if (errorCallback) {
      const message = 'Oops! That didn’t work.';

      errorCallback(message);
    }
  }
}

export const onboardingSagas = [
  takeLatest(actions.onboarding.SEND_EMAIL_TO_SERVER_SAGA, sendEmail),
  takeLatest(
    actions.onboarding.SEND_VERIFICATION_CODE_TO_SERVER_SAGA,
    sendVerificationCode,
  ),
  takeLatest(actions.onboarding.CREATE_ACCOUNT_SAGA, createAccountRequest),
  takeLatest(actions.onboarding.REDEEM_CODE, redeemCode),
];
