import img_0 from 'src/assets/images/animations/onboarding/welcome/img_0.png';
import img_1 from 'src/assets/images/animations/onboarding/welcome/img_1.png';
import img_2 from 'src/assets/images/animations/onboarding/welcome/img_2.png';
import img_3 from 'src/assets/images/animations/onboarding/welcome/img_3.png';
import img_4 from 'src/assets/images/animations/onboarding/welcome/img_4.png';
import img_5 from 'src/assets/images/animations/onboarding/welcome/img_5.png';
import img_6 from 'src/assets/images/animations/onboarding/welcome/img_6.png';
import img_7 from 'src/assets/images/animations/onboarding/welcome/img_7.png';
import img_8 from 'src/assets/images/animations/onboarding/welcome/img_8.png';
import img_9 from 'src/assets/images/animations/onboarding/welcome/img_9.png';
import img_10 from 'src/assets/images/animations/onboarding/welcome/img_10.png';
import img_11 from 'src/assets/images/animations/onboarding/welcome/img_11.png';

export default {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 60,
  w: 672,
  h: 528,
  nm: 'onboarding_2022-illustration_scene-account',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 202,
      h: 386,
      u: '',
      p: img_0,
      e: 0,
    },
    {
      id: 'image_1',
      w: 174,
      h: 186,
      u: '',
      p: img_1,
      e: 0,
    },
    {
      id: 'image_2',
      w: 33,
      h: 33,
      u: '',
      p: img_2,
      e: 0,
    },
    {
      id: 'image_3',
      w: 35,
      h: 24,
      u: '',
      p: img_3,
      e: 0,
    },
    {
      id: 'image_4',
      w: 142,
      h: 14,
      u: '',
      p: img_4,
      e: 0,
    },
    {
      id: 'image_5',
      w: 91,
      h: 14,
      u: '',
      p: img_5,
      e: 0,
    },
    {
      id: 'image_6',
      w: 117,
      h: 14,
      u: '',
      p: img_6,
      e: 0,
    },
    {
      id: 'image_7',
      w: 461,
      h: 277,
      u: '',
      p: img_7,
      e: 0,
    },
    {
      id: 'image_8',
      w: 29,
      h: 31,
      u: '',
      p: img_8,
      e: 0,
    },
    {
      id: 'image_9',
      w: 45,
      h: 47,
      u: '',
      p: img_9,
      e: 0,
    },
    {
      id: 'image_10',
      w: 451,
      h: 389,
      u: '',
      p: img_10,
      e: 0,
    },
    {
      id: 'image_11',
      w: 584,
      h: 462,
      u: '',
      p: img_11,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 1,
      nm: 'Red Solid 1',
      parent: 9,
      td: 1,
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [116.25, 94.25, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [336, 264, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [25.893, 50.758, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      sw: 672,
      sh: 528,
      sc: '#d32118',
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'osmonaut',
      parent: 9,
      tt: 1,
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0,
                y: 1,
              },
              o: {
                x: 0.172,
                y: 0,
              },
              t: 17,
              s: [116.576, 424.105, 0],
              to: [0, -28.333, 0],
              ti: [0, 28.333, 0],
            },
            {
              t: 32,
              s: [116.576, 254.105, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [100.711, 192.501, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'portrait frame',
      parent: 9,
      refId: 'image_1',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [116.75, 135.75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [86.75, 92.75, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.004, 0.004, 0.667],
                y: [0.957, 0.957, 1],
              },
              o: {
                x: [0.062, 0.062, 0.333],
                y: [0, 0, 0],
              },
              t: 9,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.5, 0.5, 0.667],
                y: [1.329, 1.329, 1],
              },
              o: {
                x: [0.439, 0.439, 0.333],
                y: [0, 0, 0],
              },
              t: 18,
              s: [103, 103, 100],
            },
            {
              t: 22,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'icon-profile',
      parent: 9,
      refId: 'image_2',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [252.338, 96.635, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [16.426, 16.011, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.004, 0.004, 0.667],
                y: [0.957, 0.957, 1],
              },
              o: {
                x: [0.062, 0.062, 0.333],
                y: [0, 0, 0],
              },
              t: 10,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.5, 0.5, 0.667],
                y: [1.329, 1.329, 1],
              },
              o: {
                x: [0.439, 0.439, 0.333],
                y: [0, 0, 0],
              },
              t: 19,
              s: [103, 103, 100],
            },
            {
              t: 23,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'icon-mail',
      parent: 9,
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [252.338, 191.393, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [17.161, 11.984, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.004, 0.004, 0.667],
                y: [0.957, 0.957, 1],
              },
              o: {
                x: [0.062, 0.062, 0.333],
                y: [0, 0, 0],
              },
              t: 20,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.5, 0.5, 0.667],
                y: [1.329, 1.329, 1],
              },
              o: {
                x: [0.439, 0.439, 0.333],
                y: [0, 0, 0],
              },
              t: 29,
              s: [103, 103, 100],
            },
            {
              t: 33,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'text-profile_1',
      parent: 9,
      refId: 'image_4',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [356.75, 82.75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [70.75, 6.75, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'n',
          pt: {
            a: 0,
            k: {
              i: [
                [-52.416, 0],
                [0, 0],
              ],
              o: [
                [52.417, 0],
                [0, 0],
              ],
              v: [
                [-2.55, 6.641],
                [143, 6.25],
              ],
              c: false,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      ef: [
        {
          ty: 22,
          nm: 'Stroke',
          np: 13,
          mn: 'ADBE Stroke',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Path',
              mn: 'ADBE Stroke-0001',
              ix: 1,
              v: {
                a: 0,
                k: 1,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Stroke-0010',
              ix: 2,
              v: {
                a: 0,
                k: 0,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: 'Stroke Sequentially',
              mn: 'ADBE Stroke-0011',
              ix: 3,
              v: {
                a: 0,
                k: 1,
                ix: 3,
              },
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Stroke-0002',
              ix: 4,
              v: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
            },
            {
              ty: 0,
              nm: 'Brush Size',
              mn: 'ADBE Stroke-0003',
              ix: 5,
              v: {
                a: 0,
                k: 11,
                ix: 5,
              },
            },
            {
              ty: 0,
              nm: 'Brush Hardness',
              mn: 'ADBE Stroke-0004',
              ix: 6,
              v: {
                a: 0,
                k: 0.95,
                ix: 6,
              },
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Stroke-0005',
              ix: 7,
              v: {
                a: 0,
                k: 1,
                ix: 7,
              },
            },
            {
              ty: 0,
              nm: 'Start',
              mn: 'ADBE Stroke-0008',
              ix: 8,
              v: {
                a: 0,
                k: 0,
                ix: 8,
              },
            },
            {
              ty: 0,
              nm: 'End',
              mn: 'ADBE Stroke-0009',
              ix: 9,
              v: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.031],
                      y: [1],
                    },
                    o: {
                      x: [0.396],
                      y: [0],
                    },
                    t: 13,
                    s: [0],
                  },
                  {
                    t: 34,
                    s: [100],
                  },
                ],
                ix: 9,
              },
            },
            {
              ty: 7,
              nm: 'Spacing',
              mn: 'ADBE Stroke-0006',
              ix: 10,
              v: {
                a: 0,
                k: 15,
                ix: 10,
              },
            },
            {
              ty: 7,
              nm: 'Paint Style',
              mn: 'ADBE Stroke-0007',
              ix: 11,
              v: {
                a: 0,
                k: 3,
                ix: 11,
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'text-profile_2',
      parent: 9,
      refId: 'image_5',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [331.25, 111.75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [45.25, 6.75, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'n',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
              ],
              v: [
                [-1.5, 6],
                [91.438, 6],
              ],
              c: false,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      ef: [
        {
          ty: 22,
          nm: 'Stroke',
          np: 13,
          mn: 'ADBE Stroke',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Path',
              mn: 'ADBE Stroke-0001',
              ix: 1,
              v: {
                a: 0,
                k: 1,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Stroke-0010',
              ix: 2,
              v: {
                a: 0,
                k: 0,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: 'Stroke Sequentially',
              mn: 'ADBE Stroke-0011',
              ix: 3,
              v: {
                a: 0,
                k: 1,
                ix: 3,
              },
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Stroke-0002',
              ix: 4,
              v: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
            },
            {
              ty: 0,
              nm: 'Brush Size',
              mn: 'ADBE Stroke-0003',
              ix: 5,
              v: {
                a: 0,
                k: 11,
                ix: 5,
              },
            },
            {
              ty: 0,
              nm: 'Brush Hardness',
              mn: 'ADBE Stroke-0004',
              ix: 6,
              v: {
                a: 0,
                k: 0.75,
                ix: 6,
              },
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Stroke-0005',
              ix: 7,
              v: {
                a: 0,
                k: 1,
                ix: 7,
              },
            },
            {
              ty: 0,
              nm: 'Start',
              mn: 'ADBE Stroke-0008',
              ix: 8,
              v: {
                a: 0,
                k: 0,
                ix: 8,
              },
            },
            {
              ty: 0,
              nm: 'End',
              mn: 'ADBE Stroke-0009',
              ix: 9,
              v: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.031],
                      y: [1],
                    },
                    o: {
                      x: [0.396],
                      y: [0],
                    },
                    t: 16,
                    s: [0],
                  },
                  {
                    t: 37,
                    s: [100],
                  },
                ],
                ix: 9,
              },
            },
            {
              ty: 7,
              nm: 'Spacing',
              mn: 'ADBE Stroke-0006',
              ix: 10,
              v: {
                a: 0,
                k: 15,
                ix: 10,
              },
            },
            {
              ty: 7,
              nm: 'Paint Style',
              mn: 'ADBE Stroke-0007',
              ix: 11,
              v: {
                a: 0,
                k: 3,
                ix: 11,
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: 'text-email',
      parent: 9,
      refId: 'image_6',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [344.25, 189.75, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [58.25, 6.75, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'n',
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
              ],
              v: [
                [-0.625, 6.75],
                [117, 6.75],
              ],
              c: false,
            },
            ix: 1,
          },
          o: {
            a: 0,
            k: 100,
            ix: 3,
          },
          x: {
            a: 0,
            k: 0,
            ix: 4,
          },
          nm: 'Mask 1',
        },
      ],
      ef: [
        {
          ty: 22,
          nm: 'Stroke',
          np: 13,
          mn: 'ADBE Stroke',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Path',
              mn: 'ADBE Stroke-0001',
              ix: 1,
              v: {
                a: 0,
                k: 1,
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Stroke-0010',
              ix: 2,
              v: {
                a: 0,
                k: 0,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: 'Stroke Sequentially',
              mn: 'ADBE Stroke-0011',
              ix: 3,
              v: {
                a: 0,
                k: 1,
                ix: 3,
              },
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Stroke-0002',
              ix: 4,
              v: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4,
              },
            },
            {
              ty: 0,
              nm: 'Brush Size',
              mn: 'ADBE Stroke-0003',
              ix: 5,
              v: {
                a: 0,
                k: 11,
                ix: 5,
              },
            },
            {
              ty: 0,
              nm: 'Brush Hardness',
              mn: 'ADBE Stroke-0004',
              ix: 6,
              v: {
                a: 0,
                k: 0.95,
                ix: 6,
              },
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Stroke-0005',
              ix: 7,
              v: {
                a: 0,
                k: 1,
                ix: 7,
              },
            },
            {
              ty: 0,
              nm: 'Start',
              mn: 'ADBE Stroke-0008',
              ix: 8,
              v: {
                a: 0,
                k: 0,
                ix: 8,
              },
            },
            {
              ty: 0,
              nm: 'End',
              mn: 'ADBE Stroke-0009',
              ix: 9,
              v: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.031],
                      y: [1],
                    },
                    o: {
                      x: [0.396],
                      y: [0],
                    },
                    t: 19,
                    s: [0],
                  },
                  {
                    t: 40,
                    s: [100],
                  },
                ],
                ix: 9,
              },
            },
            {
              ty: 7,
              nm: 'Spacing',
              mn: 'ADBE Stroke-0006',
              ix: 10,
              v: {
                a: 0,
                k: 15,
                ix: 10,
              },
            },
            {
              ty: 7,
              nm: 'Paint Style',
              mn: 'ADBE Stroke-0007',
              ix: 11,
              v: {
                a: 0,
                k: 3,
                ix: 11,
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: 'card',
      refId: 'image_7',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0],
                y: [1],
              },
              o: {
                x: [0.098],
                y: [0],
              },
              t: 7,
              s: [-15],
            },
            {
              t: 20,
              s: [5],
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [341, 270, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [230.25, 138.25, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.004, 0.004, 0.667],
                y: [0.957, 0.957, 1],
              },
              o: {
                x: [0.062, 0.062, 0.333],
                y: [0, 0, 0],
              },
              t: 7,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.5, 0.5, 0.667],
                y: [1.329, 1.329, 1],
              },
              o: {
                x: [0.439, 0.439, 0.333],
                y: [0, 0, 0],
              },
              t: 16,
              s: [103, 103, 100],
            },
            {
              t: 20,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: 'star small - top right',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 12,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 13,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 19,
              s: [100],
            },
            {
              t: 29,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 14,
              s: [0],
            },
            {
              t: 29,
              s: [50],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.185,
                y: 1,
              },
              o: {
                x: 0.143,
                y: 0,
              },
              t: 14,
              s: [529, 158, 0],
              to: [14.667, -3, 0],
              ti: [-14.667, 3, 0],
            },
            {
              t: 24,
              s: [617, 140, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [14.477, 15.124, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [80, 80, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: 'star large - top right',
      refId: 'image_9',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 12,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 13,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 19,
              s: [100],
            },
            {
              t: 29,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 14,
              s: [60],
            },
            {
              t: 29,
              s: [110],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.185,
                y: 1,
              },
              o: {
                x: 0.143,
                y: 0,
              },
              t: 14,
              s: [527, 158, 0],
              to: [0.667, -18, 0],
              ti: [-0.667, 18, 0],
            },
            {
              t: 24,
              s: [531, 50, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [22.248, 23.25, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: 'star small 2 - bottom left',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 12,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 13,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 19,
              s: [100],
            },
            {
              t: 29,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 14,
              s: [50],
            },
            {
              t: 29,
              s: [100],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.185,
                y: 1,
              },
              o: {
                x: 0.143,
                y: 0,
              },
              t: 14,
              s: [147, 380, 0],
              to: [7.333, 11, 0],
              ti: [-7.333, -11, 0],
            },
            {
              t: 24,
              s: [191, 446, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [14.477, 15.124, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [80, 80, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: 'star small - bottom left',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 12,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 13,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 19,
              s: [100],
            },
            {
              t: 29,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 14,
              s: [30],
            },
            {
              t: 29,
              s: [80],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.185,
                y: 1,
              },
              o: {
                x: 0.143,
                y: 0,
              },
              t: 14,
              s: [147, 380, 0],
              to: [-16.333, 16.667, 0],
              ti: [16.333, -16.667, 0],
            },
            {
              t: 24,
              s: [49, 480, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [14.477, 15.124, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [80, 80, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: 'star large - bottom left',
      refId: 'image_9',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 12,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 13,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 19,
              s: [100],
            },
            {
              t: 29,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.333],
                y: [0],
              },
              t: 14,
              s: [10],
            },
            {
              t: 29,
              s: [60],
            },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.185,
                y: 1,
              },
              o: {
                x: 0.143,
                y: 0,
              },
              t: 14,
              s: [153, 372, 0],
              to: [-17.333, -4.667, 0],
              ti: [17.333, 4.667, 0],
            },
            {
              t: 24,
              s: [49, 344, 0],
            },
          ],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [22.248, 23.25, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: 'blobules',
      parent: 16,
      refId: 'image_10',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.217],
                y: [1],
              },
              o: {
                x: [0.395],
                y: [0],
              },
              t: 1,
              s: [-4],
            },
            {
              t: 20,
              s: [-0.078],
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [347.497, 197.239, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [225.408, 194.126, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: 'blob',
      refId: 'image_11',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0],
                y: [1],
              },
              o: {
                x: [0.098],
                y: [0],
              },
              t: 1,
              s: [-20],
            },
            {
              t: 14,
              s: [0],
            },
          ],
          ix: 10,
        },
        p: {
          a: 0,
          k: [335.404, 268.866, 0],
          ix: 2,
          l: 2,
        },
        a: {
          a: 0,
          k: [291.588, 230.729, 0],
          ix: 1,
          l: 2,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.004, 0.004, 0.667],
                y: [0.957, 0.957, 1],
              },
              o: {
                x: [0.062, 0.062, 0.333],
                y: [0, 0, 0],
              },
              t: 1,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.5, 0.5, 0.667],
                y: [1.329, 1.329, 1],
              },
              o: {
                x: [0.439, 0.439, 0.333],
                y: [0, 0, 0],
              },
              t: 10,
              s: [103, 103, 100],
            },
            {
              t: 14,
              s: [100, 100, 100],
            },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
