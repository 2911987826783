import React from 'react';
import PropTypes from 'prop-types';

import withModal from 'src/components/hoc/withModal';

import { modalComponents } from 'src/data/modals';

import './style.scss';

const ModalRoot = (props) => {
  const { modalShow, modalProps, modalType, hideModal } = props;

  // ===========================================================================
  // ==================== COMPUTED VARIABLES ===================================
  // ===========================================================================

  const ModalComponent = modalComponents[modalType];

  if (!modalShow || !ModalComponent) {
    return null;
  }

  return (
    <div className="modal-root">
      <div className="modal-root__background" onClick={hideModal} />
      <div className="modal-root__container">
        <ModalComponent {...modalProps} />
      </div>
    </div>
  );
};

ModalRoot.propTypes = {
  modalShow: PropTypes.bool,
  modalProps: PropTypes.object,
  modalType: PropTypes.string,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
};

export default withModal(ModalRoot);
