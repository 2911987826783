import React, { useEffect } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import SiteHeader from 'src/components/headers/SiteHeader';
import Container from 'src/components/displays/Container';

import { triggerSurveyWebhookZapier } from 'src/apis/survey';

import FlyingRocketIlluSrc from 'src/assets/images/illustrations/illustration-flying-osmo-rocket.png';

import './style.scss';

const WEEKLY_TIME_GOAL_KEY = 'weekly-time-goal';

// This is a component for the header
const SurveyCallbackHeaderLink = () => {
  return (
    <div class="survey-callback-header-link">
      {'Need Help? '}
      <a href="mailto:support@playosmo.com">{'Email Us'}</a>
    </div>
  );
};

const SurveyCallback = () => {
  // ===========================================================================
  // ============================== PARAMS =====================================
  // ===========================================================================

  const [query] = useQueryParams({
    key: StringParam,
    uid: StringParam,
    option: StringParam,
    source: StringParam,
  });

  const { key, uid, option, source } = query;

  // ===========================================================================
  // ============================= LIFECYCLE ===================================
  // ===========================================================================

  useEffect(() => {
    if (key && uid && option) {
      triggerSurveyWebhookZapier(query);
    }
  }, [query, key, uid, option, source]);

  // ===========================================================================
  // ============================= VARIABLES ===================================
  // ===========================================================================

  const _getText = () => {
    return key === WEEKLY_TIME_GOAL_KEY
      ? 'Congratulations on successfully setting up your weekly goal of healthy screen time!'
      : 'Thank you for answering this survey';
  };

  return (
    <>
      <SiteHeader rightSide={SurveyCallbackHeaderLink} />
      <Container>
        <div className="survey-callback">
          <img
            src={FlyingRocketIlluSrc}
            className="survey-callback__image"
            alt="Flying Rocket Illustration"
          />

          <div className="survey-callback__title">{'Look at you go!'}</div>
          <p className="survey-callback__text">{_getText()}</p>
        </div>
      </Container>
    </>
  );
};

export default SurveyCallback;
