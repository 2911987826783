import React from 'react';

import Container from 'src/components/displays/Container';

import TangiblePlaySrc from 'src/assets/images/icons/tangible-play-logo-bw.png';

import './style.scss';

/**
 *  This component is the footer
 *  of the whole application
 */

const SiteFooter = React.memo(() => {
  return (
    <div className="site-footer">
      <Container>
        <nav className="site-footer__nav">
          <a
            className="site-footer__link"
            href="https://www.playosmo.com/-/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Privacy Policy'}
          </a>
          <a
            className="site-footer__link"
            href="https://www.playosmo.com/-/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Terms of Use'}
          </a>
          <a
            className="site-footer__link"
            href="https://support.playosmo.com/hc/en-us/articles/235183167-What-is-your-return-policy-"
            target="_blank"
            rel="noopener noreferrer"
          >
            {'Return Policy'}
          </a>
        </nav>
        <div className="site-footer__notice">
          <p>
            {
              'Osmo™, Awbie™, and Tangible Play™ are trademarks of Tangible Play, Inc. Mickey Mouse and Friends and Disney Princess ©Disney. The Incredibles 2 ©Disney/Pixar.'
            }
          </p>
          <div className="site-footer__tangibleplay-logo">
            <img src={TangiblePlaySrc} alt="Tangible Play" />
          </div>
          <p>{'©2021 Tangible Play, Inc. All rights reserved.'}</p>
        </div>
      </Container>
    </div>
  );
});

export default SiteFooter;
