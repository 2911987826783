export const eduClassroomUsages = [
  {
    id: 'station_rotations',
    name: 'Station rotations',
  },
  {
    id: 'small_group_instruction',
    name: 'Small-group instruction',
  },
  {
    id: 'maker_space',
    name: 'Maker space',
  },
  {
    id: '1_1_classroom',
    name: '1:1 in the classroom',
  },
  {
    id: 'collaboration_stations_centers',
    name: 'Collaboration stations/centers',
  },
  {
    id: 'stem_steam',
    name: 'STEM or STEAM',
  },
  {
    id: 'other',
    name: 'Other',
  },
];
