import React from 'react';

import './style.scss';

const ConsentItem = (props) => {
  const { title, content, image } = props;

  return (
    <div className="consent-item">
      <div className="consent-item__left">
        <img src={image} alt="" className="consent-item__image" />
      </div>
      <div className="consent-item__right">
        <div className="consent-item__title">{title}</div>
        <div className="consent-item__content">{content}</div>
      </div>
    </div>
  );
};

export default ConsentItem;
