import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import EmailForm from 'src/modules/EmailForm';

import withLoading from 'src/components/hoc/withLoading';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

import {
  REDEEM_CODE_ROUTE,
  VERIFY_EMAIL_ROUTE,
} from 'src/router/redeem/routes';

import './style.scss';

const EnterEmail = (props) => {
  const { dispatch, setLoadingOn, setLoadingOff, code } = props;
  const [errors, setErrors] = useState({});

  const history = useHistory();

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    if (!code) {
      history.push(REDEEM_CODE_ROUTE);
    }
  });

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  function _onSubmit({ email }) {
    // Dispatch loading on
    setLoadingOn();

    // Dispatch request
    dispatch({
      type: actions.redeem.SEND_EMAIL_TO_SERVER_SAGA,
      payload: {
        params: {
          email,
        },
        callback: () => {
          setLoadingOff();

          history.push(VERIFY_EMAIL_ROUTE);
        },
        errorCallback: (message) => {
          setLoadingOff();

          setErrors({
            status: { message },
          });
        },
      },
    });
  }

  return (
    <div className="enter-email-page">
      <Container>
        <CenterContent>
          <MainHeader title="Enter your email to login or create your account" />

          <EmailForm onSubmit={_onSubmit} errors={errors} />
        </CenterContent>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    code: selectors.redeem.getRedeemCodeSelector(state),
  };
}

export default connect(mapStateToProps)(withLoading(EnterEmail));
