const prefix = 'ONBOARDING_STATE_ACTIONS_';

// ===========================================================================
// ================================= GLOBAL ==================================
// ===========================================================================

export const SET_TRAJECTORY_TYPE = `${prefix}SET_TRAJECTORY_TYPE`;
export const SET_TMP_ACCOUNT_INFORMATION = `${prefix}SET_TMP_ACCOUNT_INFORMATION`;
export const SET_USER = `${prefix}SET_USER`;
export const SET_INITIAL_STATE = `${prefix}SET_INITIAL_STATE`;
export const SET_PRODUCT = `${prefix}SET_PRODUCT`;
export const SET_REDEEM_ERROR = `${prefix}SET_REDEEM_ERROR`;
export const SET_DOWNLOADED_GAMES = `${prefix}SET_DOWNLOADED_GAMES`;

// Sagas
export const SEND_EMAIL_TO_SERVER_SAGA = `${prefix}SEND_EMAIL_TO_SERVER`;
export const SEND_VERIFICATION_CODE_TO_SERVER_SAGA = `${prefix}SEND_VERIFICATION_CODE_TO_SERVER_SAGA`;
export const CREATE_ACCOUNT_SAGA = `${prefix}CREATE_ACCOUNT_SAGA`;
export const UPDATE_ACQUISITION_SOURCES_SAGA = `${prefix}UPDATE_ACQUISITION_SOURCES_SAGA`;
export const UPDATE_SCHOOL_USAGES_SAGA = `${prefix}UPDATE_SCHOOL_USAGES_SAGA`;
export const REDEEM_CODE = `${prefix}REDEEM_CODE`;
