import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import * as selectors from 'src/selectors';
import { translate } from 'src/i18n';
import * as actions from 'src/actions';

import withModal from 'src/components/hoc/withModal';
import { ONBOARDING_CONNECTION_CODE_ROUTE } from 'src/router/onboarding/routes';

import OsmoButton from 'src/components/buttons/OsmoButton';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';
import ErrorMessage from 'src/components/messages/ErrorMessage';
import GameDownloadItem from './components/GameDownloadItem';

import { modalTypes } from 'src/data/modals';
import { gamesData } from 'src/data/games';

import './style.scss';

const GameDownload = ({
  hideModal,
  showModal,
  // From Redux
  dispatch,
  product,
  redeemError,
  downloadedGames,
}) => {
  const history = useHistory();
  const includedGames = product?.gamesIncluded;

  const resetRedeemError = () => {
    dispatch({
      type: actions.onboarding.SET_REDEEM_ERROR,
      payload: {
        redeemError: null,
      },
    });
  };

  const onContinue = () => {
    resetRedeemError();

    dispatch({
      type: actions.onboarding.REDEEM_CODE,
      payload: {},
    });

    hideModal();
  };

  const onCancel = () => {
    resetRedeemError();
    hideModal();
  };

  useEffect(() => {
    if (redeemError?.redeemed) {
      showModal(modalTypes.CODE_ALREADY_USED, {
        onCancel,
        onContinue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemError]);

  const gameClickHandle = (game) => {
    dispatch({
      type: actions.onboarding.SET_DOWNLOADED_GAMES,
      payload: {
        games: [game.name],
      },
    });

    history.push(ONBOARDING_CONNECTION_CODE_ROUTE, {
      game,
    });
  };

  const getRedeemErrorMessage = () =>
    (redeemError?.valid || !!redeemError?.message?.length) && (
      <div className="onboarding-game-download__error">
        <ErrorMessage
          message={
            redeemError?.message ||
            'Oops! Redeeming your code didn’t work, please contact the support for more information.'
          }
        />
      </div>
    );

  const getContent = () => {
    // If single game to download
    if (includedGames?.length === 1) {
      const gameName = includedGames[0];
      const game = gamesData[gameName];

      return (
        <>
          <MainHeader title={translate('onboarding.download.title')} />
          {getRedeemErrorMessage()}
          <div className="onboarding-game-download__single-game">
            <GameDownloadItem
              key={gameName}
              className="onboarding-game-download__item"
              game={game}
              hideDownloadIcon={true}
              onClick={() => gameClickHandle(game)}
              active={downloadedGames.includes(game.name)}
              size="big"
            />
          </div>
          <OsmoButton
            text={translate('general.download')}
            onClick={() => gameClickHandle(game)}
          />
        </>
      );
    }

    // If kit (multiple games to download)
    return (
      <>
        <MainHeader title={translate('onboarding.download.titleMultiple')} />
        {getRedeemErrorMessage()}
        <div className="onboarding-game-download__list">
          {includedGames?.map((gameName, i) => {
            const game = gamesData[gameName];
            return (
              <GameDownloadItem
                key={gameName}
                className="onboarding-game-download__item"
                game={game}
                isRecommended={i === 0}
                onClick={() => gameClickHandle(game)}
                active={downloadedGames.includes(game.name)}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="onboarding-game-download">
      <Container>
        <CenterContent>{getContent()}</CenterContent>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: selectors.onboarding.getOnboardingProductSelector(state),
  redeemError: selectors.onboarding.getRedeemErrorSelector(state),
  downloadedGames: selectors.onboarding.getDownloadedGamesSelector(state),
});

export default connect(mapStateToProps)(withModal(GameDownload));
