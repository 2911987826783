import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from 'src/actions';
import * as selectors from 'src/selectors';

import withLoading from 'src/components/hoc/withLoading';
import Container from 'src/components/displays/Container';
import CenterContent from 'src/components/displays/CenterContent';
import MainHeader from 'src/components/headers/MainHeader';

import AccountInformationForm from 'src/modules/AccountInformationForm';

import {
  CONSENT_ROUTE,
  REFERRAL_TEACHER_SURVEY_ROUTE,
  REDEEM_CODE_ROUTE,
} from 'src/router/redeem/routes';

import './style.scss';

const AccountInformation = (props) => {
  const { dispatch, emailVerificationId, setLoadingOn, setLoadingOff } = props;

  const history = useHistory();

  // ===========================================================================
  // ============================= HOOKS =======================================
  // ===========================================================================

  useEffect(() => {
    if (!emailVerificationId) {
      history.push(REDEEM_CODE_ROUTE);
    }
  });

  // ===========================================================================
  // ============================ HANDLERS =====================================
  // ===========================================================================

  const _onSubmit = ({ firstName, lastName, place }) => {
    // Set loading on
    setLoadingOn();

    // Dispatch
    dispatch({
      type: actions.redeem.SET_TMP_ACCOUNT_INFORMATION,
      payload: {
        tmpAccountInformation: {
          lastName,
          firstName,
          place,
        },
      },
    });

    if (place === 'classroom') {
      history.push(REFERRAL_TEACHER_SURVEY_ROUTE);
    } else {
      history.push(CONSENT_ROUTE);
    }

    setLoadingOff();
  };

  return (
    <div className="account-information-page">
      <Container>
        <CenterContent>
          <MainHeader title="Great! Let’s complete your parent account." />

          <AccountInformationForm
            onSubmit={_onSubmit}
            withEmailInput={false}
            withCountry={false}
          />
        </CenterContent>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    emailVerificationId: selectors.redeem.getRedeemEmailVerificationIdSelector(
      state,
    ),
  };
}

export default connect(mapStateToProps)(withLoading(AccountInformation));
